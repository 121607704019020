function SvgCursor({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m18.7 18.7-4.6-4.6M5.3 7.1l3.5 10.7c.4 1.1 1.9 1.3 2.5.3.7-1.1 1.4-2.2 2-3.3.4-.6.8-1.1 1.4-1.4 1.1-.7 2.2-1.4 3.3-2 1-.6.8-2.2-.3-2.5L7.1 5.3C6 4.9 4.9 6 5.3 7.1"
      />
    </svg>
  );
}
export default SvgCursor;
