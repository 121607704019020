function SvgPresent({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3.78 12h6.17v9.23H5.96c-1.2 0-2.18-.98-2.18-2.18zM20.22 12v7.05c0 1.2-.98 2.18-2.18 2.18h-3.99V12zM4.63 6.84h5.31V12H2.75V8.72c0-1.04.84-1.88 1.88-1.88M21.25 8.72V12h-7.19V6.84h5.31c1.04 0 1.88.84 1.88 1.88"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.05 6.84H9.94v14.39h4.11z"
      />
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.05 21.23H9.94v.02h4.11z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12.02 4.8c0 .57-.23 1.08-.6 1.45s-.88.6-1.45.6h-.03c-.55-.01-1.06-.24-1.42-.6-.37-.37-.6-.88-.6-1.45 0-1.13.91-2.05 2.05-2.05.57 0 1.08.23 1.45.6s.6.88.6 1.45M14.1 2.75c-1.13 0-2.05.92-2.05 2.05s.92 2.05 2.05 2.05 2.05-.92 2.05-2.05-.92-2.05-2.05-2.05"
      />
    </svg>
  );
}
export default SvgPresent;
