function SvgCloud({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.25 14.68c0-2.14-1.52-3.44-1.61-3.52-.79-.66-1.79-1.02-2.82-.93-.38.03-.72-.23-.75-.61C15.66 3.11 5.9 3.2 5.75 9.9c-.01.28-.17.53-.43.64-1.84.84-2.9 3-2.47 5.13.25 1.23 1.06 2.41 2.19 3.03.95.52 1.84.51 2.26.48 3.39-.01 6.78-.03 10.18-.04 2.18-.01 3.77-2.42 3.77-4.46"
      />
    </svg>
  );
}
export default SvgCloud;
