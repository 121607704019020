function SvgDonut({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 15.08a3.08 3.08 0 1 0 0-6.16 3.08 3.08 0 0 0 0 6.16"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 21.25a9.25 9.25 0 1 0 0-18.5 9.25 9.25 0 0 0 0 18.5"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5.83 18.17c.27-.51.88-.76 1.45-.78.58-.01 1.14.17 1.69.33s1.14.29 1.7.17 1.04-.47 1.57-.7c.59-.25 1.26-.34 1.9-.24.56.08 1.12.3 1.68.18.5-.11.91-.47 1.23-.87.33-.39.6-.83.98-1.18.58-.53 1.4-.77 2.18-.7"
      />
    </svg>
  );
}
export default SvgDonut;
