function SvgServingPlate({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21 16H3c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3M12 7c-4.42 0-8 3.58-8 8v1h16v-1c0-4.42-3.58-8-8-8"
      />
      <path
        fill="currentColor"
        d="M12 7.75a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5"
      />
    </svg>
  );
}
export default SvgServingPlate;
