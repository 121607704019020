function SvgBrain({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.83 13.88a1.97 1.97 0 0 0-.94-1.44c.49-.5.78-1.19.78-1.9 0-.69-.26-1.34-.72-1.84-.38-.41-.87-.7-1.42-.81.04-.21.07-.42.07-.64 0-1.5-1.21-2.71-2.71-2.71h-.13c-.04.01-.07.01-.11.01a1.97 1.97 0 0 0-1.96-1.8c-.71 0-1.34.38-1.69.96-.35-.58-.98-.96-1.69-.96-1.03 0-1.87.79-1.96 1.8 0-.04-.66.02-.72.03-.23.04-.46.12-.67.23-.92.47-1.57 1.39-1.57 2.44 0 .22.03.43.07.64-.54.11-1.04.4-1.42.81-.46.5-.72 1.15-.72 1.84 0 .71.29 1.4.78 1.9-.5.31-.86.84-.94 1.44-.1.91.42 1.76 1.24 2.08-.2.39-.3.82-.3 1.25 0 1.55 1.26 2.82 2.82 2.82.37 0 .74-.07 1.08-.22.32.85 1.14 1.44 2.07 1.44.82 0 1.54-.45 1.92-1.12.38.67 1.1 1.12 1.92 1.12.93 0 1.75-.59 2.07-1.44.34.14.71.22 1.08.22 1.5 0 2.82-1.33 2.82-2.82 0-.43-.1-.86-.3-1.25.83-.32 1.35-1.16 1.25-2.08M12 3.78v16.44"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8.4 6.85c-.03.23-.13 1.21.52 2.06.64.84 1.75 1.24 2.87 1.04M8.92 14.04c-.14-.02-.74-.08-1.26.32-.52.39-.76 1.07-.63 1.75M15.08 9.96c.14-.02.73-.08 1.24.31.51.38.75 1.06.63 1.73M14.74 16.52c.55-.07 1.14.17 1.47.62M15.68 16.47c.15-.28.4-.51.7-.63"
      />
    </svg>
  );
}
export default SvgBrain;
