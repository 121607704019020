function SvgPowerOff({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.46 7.28a6.6 6.6 0 0 1 3.14 5.62c0 3.65-2.95 6.6-6.6 6.6s-6.6-2.95-6.6-6.6c0-2.38 1.26-4.46 3.14-5.62M12 4.5v4.4"
      />
    </svg>
  );
}
export default SvgPowerOff;
