function SvgCamera({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.25 9v8c0 1.1-.92 2-2.06 2H4.8c-1.13 0-2.06-.9-2.06-2V9c0-1.1.92-2 2.06-2h2.34c.65 0 1.22-.4 1.43-1S9.35 5 10 5h4c.65 0 1.22.4 1.43 1s.78 1 1.43 1h2.34c1.13 0 2.05.9 2.05 2"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 16c1.988 0 3.6-1.567 3.6-3.5S13.988 9 12 9s-3.6 1.567-3.6 3.5S10.012 16 12 16M5.83 7V6"
      />
    </svg>
  );
}
export default SvgCamera;
