function SvgBone({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.45 5.78c-.41-.41-.91-.67-1.46-.77-.1-.54-.36-1.05-.77-1.46-.51-.51-1.2-.8-1.94-.8-.73 0-1.43.29-1.94.8-.85.86-1.04 2.17-.5 3.21l-7.07 7.07a2.79 2.79 0 0 0-3.21.5c-.51.51-.8 1.21-.8 1.94 0 .74.29 1.43.8 1.94.41.41.91.67 1.46.77.1.54.36 1.05.77 1.46.51.51 1.2.8 1.94.8.73 0 1.43-.29 1.94-.8.85-.86 1.04-2.17.5-3.21l7.07-7.07c1.04.53 2.34.35 3.21-.5.51-.51.8-1.21.8-1.94 0-.74-.29-1.42-.8-1.94"
      />
    </svg>
  );
}
export default SvgBone;
