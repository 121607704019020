function SvgRssFeed({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.6 5.3c6.7 0 12.1 5.4 12.1 12.1M7.1 10.8c3.3 0 6 2.7 6 6"
      />
      <path
        fill="currentColor"
        d="M7.1 18.7a1.8 1.8 0 1 0 0-3.6 1.8 1.8 0 0 0 0 3.6"
      />
    </svg>
  );
}
export default SvgRssFeed;
