function SvgBookmark({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.58 20.99c-1.32-1.18-2.63-2.42-3.95-3.56a.99.99 0 0 0-1.29 0c-1.31 1.13-2.62 2.37-3.93 3.55-.64.58-1.67.12-1.67-.75V4.74c0-1.1.9-2 2-2h8.5c1.1 0 2 .9 2 2v15.49a.994.994 0 0 1-1.66.76"
      />
    </svg>
  );
}
export default SvgBookmark;
