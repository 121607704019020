function SvgGrapes({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 7.9c-.45-1.5-.05-3.09 1.03-4.11 1.24-1.17 2.82-1.05 3.08-1.03M12 21.25a2.23 2.23 0 1 0 0-4.46 2.23 2.23 0 0 0 0 4.46M14 16.46A2.23 2.23 0 1 0 14 12a2.23 2.23 0 0 0 0 4.46"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.55 16.46a2.23 2.23 0 1 0 0-4.46 2.23 2.23 0 0 0 0 4.46M17.19 12.35a2.23 2.23 0 1 0 0-4.46 2.23 2.23 0 0 0 0 4.46M6.81 12.35a2.23 2.23 0 1 0 0-4.46 2.23 2.23 0 0 0 0 4.46M12 12.35a2.23 2.23 0 1 0 0-4.46 2.23 2.23 0 0 0 0 4.46M10.866 6.384c.562-.562.23-1.806-.742-2.779-.973-.972-2.217-1.304-2.78-.742s-.23 1.806.743 2.779 2.217 1.305 2.78.742"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M10.866 6.384c.562-.562.23-1.806-.742-2.779-.973-.972-2.217-1.304-2.78-.742s-.23 1.806.743 2.779 2.217 1.305 2.78.742"
      />
    </svg>
  );
}
export default SvgGrapes;
