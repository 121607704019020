function SvgFire({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.85 21c-.51.03-1.01-.15-1.45-.35-.98-.43-1.74-1.22-2.01-2.23-.03-.11-.05-.21-.07-.3-.05-.26-.08-.46-.08-.62 0-.95.37-1.88 1.05-2.62l2.06-2.31.51-.57.51.57 2.06 2.31c.68.74 1.05 1.67 1.05 2.62 0 .16-.03.36-.08.62-.03.09-.05.19-.07.3-.27 1.01-1.03 1.8-2.01 2.23-.47.19-.96.31-1.47.35"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12.71 4.26c-.32-.6-.55-1.05-.55-1.05a.96.96 0 0 0-.83-.46 1 1 0 0 0-.7.3l-4.45 5c-1.46 1.59-2.26 3.6-2.26 5.66 0 .34.06.77.18 1.33.06.2.1.4.16.64.85 3.17 3.94 5.34 7.49 5.56 3.75.02 7.16-2.24 8.02-5.55.07-.23.12-.46.18-.65.1-.52.16-.94.16-1.27v-.13c0-2.17-1.1-4.62-2.12-6.2l-.95-1.45c-.15-.25-.44-.39-.76-.4-.31 0-.6.14-.76.38L14.44 7.5c-.02 0-1.06-1.97-1.73-3.24"
      />
    </svg>
  );
}
export default SvgFire;
