function SvgForkSpoon({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.86 2.75v18.5M9.94 2.75v4.11c0 1.7-1.38 3.08-3.08 3.08S3.78 8.56 3.78 6.86V2.75M16.62 21.25V9.94M16.62 9.95a3.6 3.6 0 1 0 0-7.2 3.6 3.6 0 0 0 0 7.2"
      />
    </svg>
  );
}
export default SvgForkSpoon;
