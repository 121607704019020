function SvgFlashlight({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m12.13 14.68-2.8-2.8-6.27 6.27c-.41.41-.41 1.07 0 1.48l1.33 1.33c.41.41 1.07.41 1.48 0z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m18.43 9.77-4.2-4.2a.984.984 0 0 0-1.4 0l-2.1 2.1a3.965 3.965 0 0 0 0 5.61 3.965 3.965 0 0 0 5.61 0l2.1-2.1c.38-.4.38-1.02-.01-1.41M11.43 6.97l5.6 5.6M18.43 5.57l.71-.71M20.26 7.39h.99M16.61 3.74v-.99"
      />
    </svg>
  );
}
export default SvgFlashlight;
