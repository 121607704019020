function SvgPowerSocket({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.17 21.49H5.84c-1.7 0-3.08-1.38-3.08-3.08V6.08C2.76 4.38 4.14 3 5.84 3h12.33c1.7 0 3.08 1.38 3.08 3.08v12.33c0 1.7-1.38 3.08-3.08 3.08"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 18.34A6.17 6.17 0 1 0 12 6a6.17 6.17 0 0 0 0 12.34"
      />
      <path
        fill="currentColor"
        d="M14.57 13.52a1.26 1.26 0 1 0 0-2.52 1.26 1.26 0 0 0 0 2.52M9.43 13.52a1.26 1.26 0 1 0 0-2.52 1.26 1.26 0 0 0 0 2.52"
      />
    </svg>
  );
}
export default SvgPowerSocket;
