function SvgTv({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.19 20.22H4.8c-1.14 0-2.06-.92-2.06-2.06V7.88c0-1.14.92-2.06 2.06-2.06h14.39c1.14 0 2.06.92 2.06 2.06v10.28c0 1.14-.92 2.06-2.06 2.06M18.17 20.22v1.03M5.83 20.22v1.03"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.11 17.14H6.83c-.55 0-1-.45-1-1V9.92c0-.55.45-1 1-1h8.28c.55 0 1 .45 1 1v6.22c0 .55-.45 1-1 1M12 5.83l3.08-3.08M12 5.83 8.92 2.75"
      />
      <path
        fill="currentColor"
        d="M18.38 9.61a.72.72 0 0 0 .57 0c.09-.04.18-.09.24-.16.14-.14.22-.34.22-.53 0-.2-.08-.4-.22-.53a.7.7 0 0 0-.24-.17.8.8 0 0 0-.57 0c-.09.04-.18.1-.25.17a.75.75 0 0 0 0 1.06c.07.07.16.12.25.16M18.95 11.31a.7.7 0 0 0-.57 0c-.09.04-.18.09-.25.16a.75.75 0 0 0 0 1.06.82.82 0 0 0 .54.22.776.776 0 0 0 .52-.22.75.75 0 0 0-.24-1.22"
      />
    </svg>
  );
}
export default SvgTv;
