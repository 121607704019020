function SvgMicrochip({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.62 16.11H9.38c-.82 0-1.49-.67-1.49-1.49V9.38c0-.82.67-1.49 1.49-1.49h5.24c.82 0 1.49.67 1.49 1.49v5.24c0 .82-.67 1.49-1.49 1.49"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.58 19.19H7.42c-1.44 0-2.61-1.17-2.61-2.61V7.42c0-1.44 1.17-2.61 2.61-2.61h9.16c1.44 0 2.61 1.17 2.61 2.61v9.16c0 1.44-1.17 2.61-2.61 2.61M21.25 12h-2.06M21.25 17.14h-2.06M21.25 12h-2.06M21.25 6.86h-2.06M2.75 12h2.06M2.75 17.14h2.06M2.75 12h2.06M2.75 6.86h2.06M12 2.75v2.06M17.14 2.75v2.06M12 2.75v2.06M6.86 2.75v2.06M12 21.25v-2.06M17.14 21.25v-2.06M12 21.25v-2.06M6.86 21.25v-2.06"
      />
    </svg>
  );
}
export default SvgMicrochip;
