function SvgAt({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.1 20.7c-1 .3-2 .5-3.1.5-5.1 0-9.2-4.1-9.2-9.3 0-5.1 4.1-9.2 9.3-9.2 5.1 0 9.2 4.1 9.2 9.3v1c0 1.7-1.4 3.1-3.1 3.1s-3.1-1.4-3.1-3.1V8.9"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 15.1c-1.8 0-3.1-1.4-3.1-3V12c0-1.7 1.4-3 3-3h.1c1.7 0 3 1.4 3 3 .1 1.7-1.3 3.1-3 3.1"
      />
    </svg>
  );
}
export default SvgAt;
