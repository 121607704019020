function SvgHospitalBed({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.25 11.79H2.75v2h18.5zM17.52 13.79H6.48v2.22h11.04zM6.48 21.25a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M6.48 15.01V18M17.52 21.25a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M17.52 15.01V18M6.54 11.79V2.75M4.66 3.76h5.56M11.22 5.78h-2v3h2zM10.22 3.76v1.58"
      />
    </svg>
  );
}
export default SvgHospitalBed;
