function SvgUpload({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15 17.6H8.9M15.1 14.6H8.9M17.1 8h.7M17.1 8h1.3c1.6 0 2.9 1.4 2.9 3.1v7.1c0 1.7-1.3 3.1-2.9 3.1H5.6c-1.6 0-2.9-1.4-2.9-3.1v-7.1C2.8 9.4 4 8 5.6 8h1.5M12 11.3V2.8M12 2.8 9.1 5.7M12 2.8l2.9 2.9"
      />
    </svg>
  );
}
export default SvgUpload;
