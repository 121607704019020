function SvgPriceTag({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m8.19 20.6-4.8-4.8c-.88-.88-.86-2.34.05-3.25l9.11-9.11c.5-.5 1.2-.75 1.89-.69l4.38.42c1.07.1 1.9.93 2 2l.42 4.38c.07.69-.19 1.39-.69 1.89l-9.11 9.11c-.91.91-2.36.94-3.25.05"
      />
      <path
        fill="currentColor"
        d="M17.52 6.48c-.51-.51-1.35-.51-1.86 0s-.51 1.35 0 1.86c.26.26.59.38.93.38s.67-.13.93-.38c.51-.51.51-1.34 0-1.86"
      />
    </svg>
  );
}
export default SvgPriceTag;
