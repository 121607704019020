function SvgPhone({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.54 12.46c3.88 3.88 4.77-.61 7.24 1.86 2.38 2.38 3.75 2.86.73 5.88-.38.3-2.78 3.96-11.23-4.48C-.17 7.27 3.49 4.87 3.79 4.49c3.03-3.03 3.5-1.65 5.88.73 2.48 2.47-2.01 3.36 1.87 7.24"
      />
    </svg>
  );
}
export default SvgPhone;
