function SvgHourglass({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M4.81 2.75h14.38M4.81 21.25h14.38M16.54 7.46l-3.08 3.08c-.8.8-2.1.8-2.91 0L7.47 7.46c-.39-.39-.6-.91-.6-1.45v-1.2c0-1.14.92-2.06 2.06-2.06h6.17c1.14 0 2.06.92 2.06 2.06v1.2c-.02.54-.24 1.07-.62 1.45"
      />
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.14 19.19c0 1.14-.92 2.06-2.06 2.06H8.92c-1.14 0-2.06-.92-2.06-2.06z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.14 17.99v1.2H6.86v-1.2c0-.54.22-1.07.6-1.45l3.09-3.09c.8-.8 2.1-.8 2.9 0l3.09 3.09c.38.38.6.91.6 1.45M9.94 9.94h4.12"
      />
      <path
        fill="currentColor"
        d="M12 16.86a.75.75 0 0 1-.53-1.28c.2-.21.54-.27.82-.16.09.04.17.09.24.16a.78.78 0 0 1 .22.53.75.75 0 0 1-.75.75"
      />
    </svg>
  );
}
export default SvgHourglass;
