function SvgShield({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.6 9.6c0 5-2.93 9.6-7.6 11.65-4.54-2-7.6-6.52-7.6-11.65V5.91L12 2.76l7.6 3.15z"
      />
    </svg>
  );
}
export default SvgShield;
