function SvgMegaphone({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m16.19 14.72-2.45-2.45-2.14-2.14-2.48-2.48"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m9.12 18.96 6.37-4.95-5.66-5.65-4.95 6.36z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m7.49 20.6 1.64-1.64-4.24-4.24-1.64 1.64c-.66.66-.66 1.72 0 2.38l1.86 1.86c.65.66 1.72.66 2.38 0M9.29 19.13l2.12 2.12M13.61 10.23c.39.39.4 1.02 0 1.41-.08.08-.18.16-.3.2L12 10.53c.04-.11.11-.21.2-.3.4-.4 1.02-.39 1.41 0M21.25 11.25h-2M18.91 5.09 17.2 6.8M12.75 2.75v2"
      />
    </svg>
  );
}
export default SvgMegaphone;
