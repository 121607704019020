function SvgSpeechBubble({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        fill="currentColor"
        d="M7.89 13.03a1.03 1.03 0 1 0 0-2.06 1.03 1.03 0 0 0 0 2.06M12 13.03a1.03 1.03 0 1 0 0-2.06 1.03 1.03 0 0 0 0 2.06M16.11 13.03a1.03 1.03 0 1 0 0-2.06 1.03 1.03 0 0 0 0 2.06"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.44 15.8h-.01c.52-1.16.82-2.45.82-3.8 0-5.11-4.14-9.25-9.25-9.25S2.75 6.89 2.75 12a9.248 9.248 0 0 0 13.45 8.24l5 .74z"
      />
    </svg>
  );
}
export default SvgSpeechBubble;
