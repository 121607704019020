function SvgGif({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.49 5.95c.45.58.7 1.36.7 2.18v10.04c0 1.7-1.07 3.08-2.4 3.08H7.2c-1.32 0-2.4-1.39-2.4-3.08V5.84c0-1.71 1.08-3.08 2.4-3.08h7.81c.64 0 1.25.32 1.7.9z"
      />
      <path
        fill="currentColor"
        d="M10.89 15.42c.06.06.11.14.14.21.03.08.05.16.05.24s-.02.17-.05.25-.08.16-.15.22c-.06.06-.14.11-.22.15-.08.03-.16.05-.25.05a.636.636 0 0 1-.47-.18c-.13-.12-.27-.22-.42-.28a1.3 1.3 0 0 0-.48-.09c-.16 0-.32.03-.47.1s-.28.15-.39.27c-.11.11-.2.24-.27.4-.07.15-.1.31-.1.49 0 .16.03.32.09.47s.15.28.26.39.24.21.39.27c.15.07.31.1.49.1.14 0 .26-.01.38-.03s.24-.07.37-.15v-.12h-.1c-.09 0-.18-.02-.26-.05a.6.6 0 0 1-.21-.15.56.56 0 0 1-.14-.22.8.8 0 0 1-.05-.26c0-.09.02-.17.05-.26.03-.08.08-.15.14-.21s.13-.11.21-.14.17-.05.26-.05h.77c.09 0 .18.02.26.06.08.03.15.08.21.14s.11.13.14.21c.04.08.05.16.05.25v1.11q0 .15-.06.27c-.04.08-.09.15-.16.22-.31.26-.63.44-.94.56-.31.11-.64.16-.97.16-.22 0-.44-.03-.65-.09s-.41-.15-.6-.26-.36-.25-.51-.4c-.16-.15-.29-.33-.4-.52s-.2-.39-.26-.61a2.6 2.6 0 0 1 .11-1.68 2.6 2.6 0 0 1 1.34-1.39c.31-.14.64-.21 1-.21.37 0 .71.06 1.01.19.32.13.6.32.86.57M13.14 19.11c0 .09-.02.18-.05.26s-.08.15-.14.21-.13.11-.21.14-.17.05-.26.05-.18-.02-.26-.05a.6.6 0 0 1-.21-.14.6.6 0 0 1-.14-.21.6.6 0 0 1-.05-.26v-3.76c0-.09.02-.18.05-.26s.08-.15.14-.21.13-.11.21-.14.17-.05.26-.05.18.02.26.05.15.08.21.14.11.13.14.21c.04.08.05.16.05.26zM16.84 14.69c.09 0 .18.02.26.05.08.04.15.08.21.14s.11.13.14.21.05.17.05.26-.02.18-.05.26-.08.15-.14.21a.8.8 0 0 1-.21.15c-.08.04-.17.05-.26.05H15.2v.65h1.28c.09 0 .18.02.26.06a.8.8 0 0 1 .21.15c.06.06.11.13.14.22.03.08.05.17.05.26s-.02.18-.05.26-.08.15-.14.2c-.06.06-.13.1-.21.13s-.17.05-.26.05H15.2v1.09c0 .09-.02.18-.05.26s-.08.15-.14.21-.13.11-.21.14-.17.05-.26.05-.18-.02-.26-.05a.6.6 0 0 1-.21-.14.6.6 0 0 1-.14-.21.8.8 0 0 1-.05-.26v-3.78c0-.07.02-.15.04-.22.02-.08.06-.14.11-.21s.12-.11.2-.15.19-.06.31-.06h2.3z"
      />
    </svg>
  );
}
export default SvgGif;
