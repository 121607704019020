function SvgLogout({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.25 17.14v1.26c0 1.58-1.4 2.85-3.13 2.85H5.87c-1.73 0-3.13-1.28-3.13-2.85V5.6c0-1.58 1.4-2.85 3.13-2.85h7.25c1.73 0 3.13 1.28 3.13 2.85v1.26M11.28 12h9.95M21.25 12l-3.28-3.26M21.25 12l-3.28 3.26"
      />
    </svg>
  );
}
export default SvgLogout;
