function SvgKnife({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m10.56 16.19-4.95 4.77c-.41.39-1.06.39-1.46 0l-1.07-1.03c-.43-.41-.43-1.1 0-1.51l4.9-4.72zM19.75 10.15l-7 6.74c-.41.39-1.06.39-1.46 0l-3.32-3.2L19.33 2.75l.43.41a4.83 4.83 0 0 1-.01 6.99"
      />
    </svg>
  );
}
export default SvgKnife;
