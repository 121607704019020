function SvgInvoice({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.89 5.97v15.28H5.97c-1.78 0-3.22-1.44-3.22-3.22V5.97c0-1.78 1.44-3.22 3.22-3.22h8.7c1.77 0 3.22 1.44 3.22 3.22M21.25 19.13V14c0-1.7-1.51-3.08-3.36-3.08M14.21 21.25h4.97"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.89 16.89v2.82c0 .85.75 1.54 1.68 1.54s1.68-.69 1.68-1.54v-2.82M6.85 6.87h7.19M6.85 12h7.19M6.88 17.12h2.05"
      />
    </svg>
  );
}
export default SvgInvoice;
