function SvgMilkCarton({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.71 15.4c.01.84-.75 1.74-1.76 1.74-1.02 0-1.77-.9-1.76-1.74.01-.56.34-.96.48-1.11.43-.42.85-.84 1.28-1.26.43.42.85.84 1.28 1.26.13.15.47.55.48 1.11"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.08 8.92H4.8V19.2c0 1.14.92 2.06 2.06 2.06h8.22zM17.14 5.83H6.86L4.81 8.92h10.27zM17.14 5.83l2.05 3.09"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.11 2.75H7.89c-.57 0-1.03.46-1.03 1.03v2.06h10.28V3.78c0-.57-.46-1.03-1.03-1.03M13.03 21.25h4.11c1.14 0 2.06-.92 2.06-2.06V8.91"
      />
    </svg>
  );
}
export default SvgMilkCarton;
