function SvgBitcoin({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18M10.62 15.33V16M10.62 8.92v6.41M10.62 8v.92M12.92 15.03V16M12.92 8v.8"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9 9.17h3.62c.83 0 1.5.61 1.5 1.36s-.67 1.36-1.5 1.36H9M9 11.89h4.5c.83 0 1.5.61 1.5 1.36s-.67 1.36-1.5 1.36H9"
      />
    </svg>
  );
}
export default SvgBitcoin;
