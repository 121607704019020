function SvgUsbDrive({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.62 9.94v9.25c0 1.14-.92 2.06-2.06 2.06H9.42c-1.14 0-2.06-.92-2.06-2.06V9.94c0-1.14.92-2.06 2.06-2.06h5.14c1.14.01 2.06.93 2.06 2.06"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8.92 2.75v5.14h6.17V2.75z"
      />
      <path
        fill="currentColor"
        d="M12 18.92a1.52 1.52 0 1 1-.001-3.039A1.52 1.52 0 0 1 12 18.92m.02-1.52-.02.02c.01 0 .02-.01.02-.02"
      />
    </svg>
  );
}
export default SvgUsbDrive;
