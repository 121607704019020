function SvgPttKargo({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 54 17"
      className={className}
      {...props}
    >
      <path fill="#292929" d="M0 0h54v17H0z" />
      <g clipPath="url(#ptt-kargo_svg__a)">
        <path
          fill="#F6F6F6"
          d="M-1014-3924c0-4.42 3.58-8 8-8H253a8 8 0 0 1 8 8V392.439a8 8 0 0 1-8 8h-1259c-4.42 0-8-3.581-8-8z"
        />
        <path fill="currentColor" d="M-1014-20H261v420.439h-1275z" />
        <path
          fill="#31327D"
          d="M53.9 15.64c-.026.043-.06.085-.078.13-.132.35-.337.513-.706.518-2.182.03-4.315-1.122-4.825-3.85a7.4 7.4 0 0 1-.112-1.349c-.009-3.207-.005-6.413-.005-9.62 0-.77.283-1.054 1.051-1.054h.835c.684 0 .986.289.993.975.009.786.002 1.57.002 2.357v.328h1.494c.68 0 .97.285.97.952q.002.444 0 .888c-.006.524-.323.84-.852.845-.526.004-1.053 0-1.596 0-.006.096-.014.168-.014.239 0 1.423-.026 2.847.012 4.27.012.478.12.975.292 1.423.25.643.763 1.068 1.477 1.09.533.015.902.167 1.06.696v1.165zM9.3 5.313c.172 1.972.746 3.755 1.857 5.344 1.456 2.082 3.494 3.273 5.946 3.812 1.21.265 2.431.38 3.663.151 2.058-.381 3.49-1.574 4.39-3.425.225-.462.406-.95.554-1.442.148-.49.229-1.002.346-1.537.055.075.109.14.154.214.36.581.713 1.166 1.08 1.742.102.16.116.296.023.46-1.45 2.56-3.523 4.295-6.473 4.857-1.71.326-3.392.128-5.048-.352-2.876-.832-5.302-2.34-7.07-4.792-.425-.589-.76-1.241-1.147-1.881L3.977 10.47c2.902 2.865 6.347 4.685 10.236 5.753-.478.23-5.18-1.01-7.164-1.884-2.3-1.011-4.377-2.35-6.148-4.201l8.396-4.825zM44.801 6.755v.333q-.002 1.969 0 3.937c0 .585.057 1.162.325 1.697.344.688.891 1.06 1.674 1.077.556.01.815.274.818.822.002.305.009.61 0 .915-.016.465-.287.741-.752.746-2.291.025-4.43-1.235-4.85-4.077a7 7 0 0 1-.062-.993c-.006-3.272-.004-6.542-.002-9.813 0-.71.28-.988.991-.99.323 0 .648-.005.97 0 .585.011.888.314.89.895.003.822.009 1.646-.004 2.468-.003.225.052.312.29.301.436-.018.87-.009 1.306-.003.576.005.891.319.9.893q.01.47 0 .943c-.012.52-.338.842-.86.847-.536.005-1.069 0-1.632 0z"
        />
        <path
          fill="#31327D"
          d="M12.034 1.721c.439.535 1.047.836 1.64 1.146.646.337 1.314.63 1.958.967.455.239.71.54.822.891-.977.21-1.94.132-2.9-.084l-.058-.017.032.042c.761.582 1.517 1.165 2.284 1.739.216.162.456.292.743.474-.959.257-1.838.155-2.717-.027q-.031-.006-.064-.012l.029.048c.743.765 1.667 1.214 2.683 1.48.462.121.941.173 1.405.256-2.074.222-5.188-1.19-6.117-2.777q1.444.237 2.89.478l.025-.091c-.273-.166-.548-.327-.817-.498-.815-.517-1.587-1.09-2.2-1.845-.055-.068-.09-.155-.134-.232l-.005.004c.405.082.806.183 1.214.244.637.096 1.278.164 1.919.244l.035-.08c-.117-.086-.23-.18-.355-.257-.658-.408-1.337-.784-1.975-1.223A8.2 8.2 0 0 1 10.287.546c-.082-.116-.13-.255-.195-.383l-.003.003c.996.66 2.168.683 3.287.885 2.727.49 5.367 1.239 7.838 2.51.826.424 1.59.943 2.227 1.633 1.102 1.193 1.36 2.694.649 4.15-.282.577-.683 1.094-1.03 1.638-.05.076-.106.147-.198.178.023-.464.076-.927.064-1.39-.064-2.29-.984-4.07-3.099-5.143a39.5 39.5 0 0 0-7.547-2.89c-.077-.021-.162-.012-.242-.018q-.008-.019-.015-.039l-.025.01.038.031z"
        />
        <path
          fill="#31327D"
          d="M10.117.169q-.027-.01-.054-.02.026.011.049.025zM11.556 3.673s-.029-.007-.038-.013c-.007-.003 0-.02-.003-.029l.032.048zM40.97 4.79C40.794 2.572 38.882.575 36.66.457 34.868.363 33.07.41 31.272.411c-.499 0-.813.344-.815.831v14.192c0 .51.346.845.86.85q.527.006 1.055.006c.706 0 1.022-.312 1.022-1.01V10.3h.298c.805 0 1.613.029 2.417-.005 1.567-.07 2.924-.601 3.898-1.9.804-1.074 1.066-2.287.96-3.606zm-4.411 2.689c-.854.074-1.72.04-2.579.048-.584.005-.584 0-.584-.574V3.469c1.035 0 2.027-.03 3.014.009 1.038.039 1.83.921 1.874 2.009.04.941-.75 1.906-1.727 1.992zM12.079 1.732l-.049-.04q.017-.006.033-.014.009.026.018.051zM13.523 4.66l-.032-.043q.03.008.059.018zM13.82 6.888q-.014-.024-.028-.048l.064.012z"
        />
      </g>
      <defs>
        <clipPath id="ptt-kargo_svg__a">
          <path
            fill="#fff"
            d="M-1014-3924c0-4.42 3.58-8 8-8H253a8 8 0 0 1 8 8V392.439a8 8 0 0 1-8 8h-1259c-4.42 0-8-3.581-8-8z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgPttKargo;
