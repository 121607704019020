function SvgAtm({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.86 13.03H4.8c-1.14 0-2.06-.92-2.06-2.05V4.82c0-1.13.92-2.05 2.06-2.05h14.39c1.14 0 2.06.92 2.06 2.05v6.16c0 1.13-.92 2.05-2.06 2.05h-2.06"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.14 7.89v11.62c0 .96-.78 1.75-1.75 1.75H8.61c-.96 0-1.75-.78-1.75-1.75V7.89zM9.87 17.24V8.79M14.08 17.12v-1.03M5.83 7.89h12.34"
      />
    </svg>
  );
}
export default SvgAtm;
