function SvgCreditcard({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.69 20H5.32c-1.42 0-2.56-1.15-2.56-2.56V6.57c0-1.42 1.15-2.56 2.56-2.56h13.37c1.42 0 2.56 1.15 2.56 2.56v10.87c0 1.41-1.15 2.56-2.56 2.56M3.16 8.97h17.68M5.71 12.61h3.58"
      />
    </svg>
  );
}
export default SvgCreditcard;
