function SvgAirpods({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.14 2.75H6.86a4.11 4.11 0 0 0-4.11 4.11v10.28a4.11 4.11 0 0 0 4.11 4.11h10.28a4.11 4.11 0 0 0 4.11-4.11V6.86a4.11 4.11 0 0 0-4.11-4.11M20.88 8.92H3.12"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5.83 8.92h12.34A3.08 3.08 0 0 1 15.08 12H8.92a3.08 3.08 0 0 1-3.09-3.08"
      />
      <path
        fill="currentColor"
        d="M12 17.65a1.025 1.025 0 1 1 0-2.05 1.025 1.025 0 0 1 0 2.05"
      />
    </svg>
  );
}
export default SvgAirpods;
