function SvgPolkadot({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18M11.39 10.67l-.73 4.62"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.03 11.32c-.17-1.32.55-2.56 1.77-3.08.09-.04 1.6-.65 2.94.13 1.12.66 1.47 1.9 1.17 2.78-.12.36-.31.65-.56.89-.49.47-1.07.62-1.47.72-.56.14-1.08.15-1.5.12"
      />
      <path
        fill="currentColor"
        d="M13.15 16.02a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5"
      />
    </svg>
  );
}
export default SvgPolkadot;
