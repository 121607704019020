function SvgPassword({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        fill="currentColor"
        d="M6.86 13.26a1.26 1.26 0 1 0 0-2.52 1.26 1.26 0 0 0 0 2.52M12 13.26a1.26 1.26 0 1 0 0-2.52 1.26 1.26 0 0 0 0 2.52M17.14 13.26a1.26 1.26 0 1 0 0-2.52 1.26 1.26 0 0 0 0 2.52"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.19 16.11H4.81c-1.14 0-2.06-.92-2.06-2.06V9.94c0-1.14.92-2.06 2.06-2.06H19.2c1.14 0 2.06.92 2.06 2.06v4.11a2.07 2.07 0 0 1-2.07 2.06"
      />
    </svg>
  );
}
export default SvgPassword;
