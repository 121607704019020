function SvgMap({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.5 8.87H4.8c-1.14 0-2.06.92-2.06 2.06v7.19c0 1.14.92 2.06 2.06 2.06h14.39c1.14 0 2.06-.92 2.06-2.06v-7.19M16.11 8.87h-5.84"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.11 16.06h3.08c1.14 0 2.06.92 2.06 2.06s-.92 2.06-2.06 2.06h-3.08M16.11 5.78h3.08c1.14 0 2.06.92 2.06 2.06M21.25 18.12V7.84M16.11 5.78v10.28M2.75 14.01h4.11c.57 0 1.03.39 1.03.88s.46.88 1.03.88h1.03c.57 0 1.03.39 1.03.88v3.52M8.4 3.83A2.57 2.57 0 0 0 5.83 6.4c0 1.76 2.3 4.34 2.4 4.45.09.1.25.1.34 0 .1-.11 2.4-2.69 2.4-4.45A2.57 2.57 0 0 0 8.4 3.83"
      />
      <path
        fill="currentColor"
        d="M8.4 7.38a.89.89 0 1 0 0-1.78.89.89 0 0 0 0 1.78"
      />
    </svg>
  );
}
export default SvgMap;
