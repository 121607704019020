function SvgBandage({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m19.81 12.87-8.68-8.68a4.9 4.9 0 0 0-6.94 0 4.9 4.9 0 0 0 0 6.94l8.68 8.68a4.9 4.9 0 0 0 6.94 0 4.9 4.9 0 0 0 0-6.94"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.942 11.998 12 5.054l-6.944 6.944 6.944 6.944z"
      />
      <path
        fill="currentColor"
        d="M11.91 13.28a1.19 1.19 0 1 0 0-2.38 1.19 1.19 0 0 0 0 2.38M8.53 13.28a1.19 1.19 0 1 0 0-2.38 1.19 1.19 0 0 0 0 2.38M15.47 13.28a1.19 1.19 0 1 0 0-2.38 1.19 1.19 0 0 0 0 2.38M12 9.81a1.19 1.19 0 1 0 0-2.38 1.19 1.19 0 0 0 0 2.38M12 16.75a1.19 1.19 0 1 0 0-2.38 1.19 1.19 0 0 0 0 2.38"
      />
    </svg>
  );
}
export default SvgBandage;
