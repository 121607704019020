function SvgAlignCenter({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 2.75v18.5M15.1 10.53H8.91c-.55 0-1-.45-1-1V5.79c0-.55.45-1 1-1h6.19c.55 0 1 .45 1 1v3.74c0 .56-.45 1-1 1M18.17 19.14H5.83c-.55 0-1-.45-1-1V14.4c0-.55.45-1 1-1h12.34c.55 0 1 .45 1 1v3.74c0 .55-.45 1-1 1"
      />
    </svg>
  );
}
export default SvgAlignCenter;
