function SvgDiamondHands({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14 11.53V6.22c0-.93.7-1.67 1.55-1.67.86 0 1.55.75 1.55 1.67v6.35"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M10.9 9.71V4.42c0-.93.7-1.67 1.55-1.67.86 0 1.55.75 1.55 1.67v6.75M7.79 10.6V7.94c0-.92.7-1.67 1.55-1.67.86 0 1.55.76 1.55 1.67v2.13M17.11 12.57V8.3c0-.93.7-1.67 1.55-1.67.86 0 1.55.75 1.55 1.67v6.24c0 3.71-2.77 6.7-6.21 6.7-.91 0-1.77-.21-2.54-.59M3.77 15.2l1.75-1.93h4.78l1.75 1.92-4.06 4.78z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.01 11.53V6.22c0-.93.7-1.67 1.55-1.67.86 0 1.55.75 1.55 1.67v6.35M10.9 9.71V4.42c0-.93.7-1.67 1.55-1.67.86 0 1.55.75 1.55 1.67v6.75M7.79 10.86V7.94c0-.92.7-1.67 1.55-1.67.86 0 1.55.76 1.55 1.67v2.13"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.12 12.57V8.3c0-.93.7-1.67 1.55-1.67.86 0 1.55.75 1.55 1.67v6.24c0 3.71-2.78 6.7-6.22 6.7-1.2 0-2.32-.37-3.27-1"
      />
    </svg>
  );
}
export default SvgDiamondHands;
