function SvgMouse({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 20c-3.31 0-6-2.69-6-6v-4c0-3.31 2.69-6 6-6s6 2.69 6 6v4c0 3.31-2.69 6-6 6M12 7.5v2"
      />
    </svg>
  );
}
export default SvgMouse;
