function SvgLineChartDown({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        fill="currentColor"
        d="M11.75 16.05a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5M4.25 8.54a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5M19.75 19.96a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m19.86 17.86-8.11-4.06-7.5-7.51"
      />
    </svg>
  );
}
export default SvgLineChartDown;
