function SvgAlignRight({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.19 2.75v18.5M17.12 10.53h-6.17c-.55 0-1-.45-1-1V5.79c0-.55.45-1 1-1h6.17zM17.12 19.14H4.8c-.55 0-1-.45-1-1V14.4c0-.55.45-1 1-1h12.32z"
      />
    </svg>
  );
}
export default SvgAlignRight;
