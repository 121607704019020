function SvgWhistle({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.22 12h-4.47c.28.76.4 1.57.35 2.43-.16 2.77-2.24 5.17-4.97 5.68a6.18 6.18 0 0 1-7.36-6.21c.09-3.39 3.04-6.01 6.43-6.01h10.01c.57 0 1.03.46 1.03 1.03v2.06c.01.56-.45 1.02-1.02 1.02"
      />
      <path
        fill="currentColor"
        d="M9.94 12.28c-.98 0-1.78.8-1.78 1.78s.8 1.78 1.78 1.78 1.78-.8 1.78-1.78-.8-1.78-1.78-1.78"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3.78 14.06H2.75M13.03 4.81V3.78M10.31 4.66l-.73-.73M15.75 4.66l.72-.73"
      />
    </svg>
  );
}
export default SvgWhistle;
