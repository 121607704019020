function SvgThermometer({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.04 12.02V5.83c0-1.7-1.38-3.08-3.08-3.08S8.88 4.13 8.88 5.83v6.19c-1.24.94-2.06 2.41-2.06 4.09 0 2.84 2.3 5.14 5.14 5.14s5.14-2.3 5.14-5.14c0-1.67-.82-3.15-2.06-4.09"
      />
      <path
        fill="currentColor"
        d="M11.96 18.92a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.96 16.11V8.92M18.12 4.81h1.03M18.12 7.89h2.06M18.12 10.97h1.03"
      />
    </svg>
  );
}
export default SvgThermometer;
