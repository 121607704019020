function SvgDumbbell({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.94 12h5.14M4.45 15.29h-.33c-.76 0-1.38-.62-1.38-1.38v-3.66a1.54 1.54 0 0 1 3.08 0v3.66c.01.76-.6 1.38-1.37 1.38M7.54 16.11h-.33c-.76 0-1.38-.62-1.38-1.38v-5.3a1.54 1.54 0 0 1 3.08 0v5.3c.01.76-.61 1.38-1.37 1.38M16.79 16.11h-.33c-.76 0-1.38-.62-1.38-1.38v-5.3a1.54 1.54 0 0 1 3.08 0v5.3c.01.76-.61 1.38-1.37 1.38M19.71 15.29c-.85 0-1.54-.69-1.54-1.54v-3.49a1.54 1.54 0 0 1 3.08 0v3.49c0 .85-.69 1.54-1.54 1.54"
      />
    </svg>
  );
}
export default SvgDumbbell;
