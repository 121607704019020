function SvgThumbsDown({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.55 15.04h-.71c.58 1.78 1.22 4.65-.77 5.79-.54.31-2.16.8-2.39-.12-.32-1.26-.63-2.52-1.38-3.63-.62-.91-1.56-1.92-2.73-2.04V5.18l.24-.08 2.1-.69c.69-.23 1.41-.35 2.14-.35h5.13c1.02 0 1.85.82 1.85 1.83 0 .24-.05.46-.14.67.79.22 1.36.93 1.36 1.77 0 .47-.17.9-.47 1.23.29.32.47.75.47 1.21 0 .47-.17.9-.47 1.23.29.32.47.75.47 1.21 0 1.01-.83 1.83-1.84 1.83zM2.75 16.62h3.49c1.28 0 2.32-1.04 2.32-2.32V5.06c0-1.28-1.04-2.32-2.32-2.32H2.75z"
      />
      <path
        fill="currentColor"
        d="M5.66 6.82a.78.78 0 1 0 0-1.56.78.78 0 0 0 0 1.56"
      />
    </svg>
  );
}
export default SvgThumbsDown;
