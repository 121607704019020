function SvgAlarmBell({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.22 16.62c0 .85-.69 1.54-1.54 1.54H5.32a1.54 1.54 0 0 1 0-3.08h.51V9.94c0-3.4 2.76-6.17 6.17-6.17 1.7 0 3.24.69 4.36 1.81a6.1 6.1 0 0 1 1.81 4.36v5.14h.51c.85 0 1.54.69 1.54 1.54M12 3.78V2.75M15.08 18.17c0 1.71-1.38 3.08-3.08 3.08s-3.08-1.38-3.08-3.08z"
      />
    </svg>
  );
}
export default SvgAlarmBell;
