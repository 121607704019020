function SvgCancerRibbon({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m18.43 6.91-3.97 5.23-.15.19-1.23 1.63L12 15.4l-.4.52-1.23 1.63-2.81 3.7c-.06-.95-.13-1.88-.19-2.83-.71-.18-1.42-.37-2.13-.55 1.1-1.48 2.22-2.95 3.33-4.43.32-.43.65-.86.98-1.29.08-.11.15-.22.24-.32l1.49-1.98.72-.97.5-.67 1.3-1.84 2.53-3.62z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m12 8.88-.73.97-1.49 1.98c-.09.11-.16.22-.24.32L5.57 6.91l2.1-4.16 2.53 3.62 1.29 1.84zM18.76 17.87c-.72.18-1.42.37-2.13.55-.06.95-.13 1.88-.19 2.83l-2.82-3.7-1.23-1.63-.39-.52 1.08-1.44 1.23-1.63.15-.19c.32.43.65.87.98 1.3 1.1 1.48 2.22 2.95 3.32 4.43M7.79 2.75h8.42"
      />
    </svg>
  );
}
export default SvgCancerRibbon;
