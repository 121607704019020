function SvgArrowUp40({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m15.15 9.54-6.3 5.29M10.8 9.17l4.34.38M14.76 13.9l.38-4.35M21.25 12c0 1.21-.24 2.42-.7 3.54a9.2 9.2 0 0 1-2.01 3c-.86.86-1.88 1.54-3 2.01-1.12.46-2.33.7-3.54.7s-2.42-.24-3.54-.7a9.2 9.2 0 0 1-3-2.01 9.4 9.4 0 0 1-2.01-3c-.46-1.12-.7-2.33-.7-3.54 0-2.45.97-4.81 2.71-6.54A9.27 9.27 0 0 1 12 2.75c2.45 0 4.81.97 6.54 2.71A9.22 9.22 0 0 1 21.25 12"
      />
    </svg>
  );
}
export default SvgArrowUp40;
