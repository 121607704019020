function SvgWheelchair({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.19 16.11v-4.38c0-1.55-1.26-2.81-2.81-2.81H6.86V12M8.92 18.17h9.25M6.86 9.94V4.81M3.78 4.81h3.08M7.89 5.83h7.19M13.03 8.92V5.83"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.35 19.2a3.6 3.6 0 1 0 0-7.2 3.6 3.6 0 0 0 0 7.2M19.71 19.19a1.54 1.54 0 1 0 0-3.08 1.54 1.54 0 0 0 0 3.08"
      />
    </svg>
  );
}
export default SvgWheelchair;
