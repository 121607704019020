function SvgDiscountCoupon({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.93 11.24 18.9 9.21V6.25c0-.64-.51-1.15-1.15-1.15h-2.96l-2.03-2.03c-.42-.42-1.1-.42-1.52 0L9.21 5.1H6.25c-.64 0-1.15.51-1.15 1.15v2.96l-2.03 2.03c-.42.42-.42 1.1 0 1.52l2.03 2.03v2.96c0 .64.51 1.15 1.15 1.15h2.96l2.03 2.03c.42.42 1.1.42 1.52 0l2.03-2.03h2.96c.64 0 1.15-.52 1.15-1.15v-2.96l2.03-2.03c.43-.42.43-1.1 0-1.52M14.44 9.56l-4.88 4.88"
      />
      <path
        fill="currentColor"
        d="M8.97 7.65a1.261 1.261 0 0 0 0 2.52 1.261 1.261 0 0 0 0-2.52M15.12 13.8a1.261 1.261 0 0 0 0 2.52c.7 0 1.26-.57 1.26-1.26.01-.69-.56-1.26-1.26-1.26"
      />
    </svg>
  );
}
export default SvgDiscountCoupon;
