function SvgStar({
  size = 16,
  className = "",
  ...props
}: {
  size?: number;
  className?: string;
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.1033 0.816988C12.4701 0.0737417 13.5299 0.0737404 13.8967 0.816986L16.8818 6.86544C17.0275 7.16058 17.3091 7.36515 17.6348 7.41248L24.3096 8.38239C25.1298 8.50158 25.4574 9.50955 24.8638 10.0881L20.0339 14.7962C19.7982 15.0259 19.6906 15.3569 19.7463 15.6813L20.8865 22.3292C21.0266 23.1461 20.1691 23.769 19.4355 23.3834L13.4653 20.2446C13.174 20.0915 12.826 20.0915 12.5347 20.2446L6.56448 23.3834C5.83085 23.769 4.97342 23.1461 5.11353 22.3292L6.25373 15.6813C6.30937 15.3569 6.20182 15.0259 5.96614 14.7962L1.13616 10.0881C0.542642 9.50955 0.870151 8.50158 1.69037 8.38239L8.36524 7.41248C8.69095 7.36515 8.97251 7.16058 9.11817 6.86544L12.1033 0.816988Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgStar;
