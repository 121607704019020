function SvgCoffeToGo({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m18.49 9.18-1.91 10.35c-.19.99-1.16 1.72-2.29 1.72H9.71c-1.13 0-2.1-.74-2.29-1.72L5.51 9.18M20 5.83H4v3.08h16zM18 2.75H6L5 5.83h14z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.09 15.15c.804-.805.969-1.945.367-2.546-.601-.602-1.74-.437-2.545.367-.805.805-.97 1.944-.368 2.546.601.601 1.741.437 2.546-.368"
      />
    </svg>
  );
}
export default SvgCoffeToGo;
