function SvgKeyboard({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.21 17.1H4.79c-1.13 0-2.04-.91-2.04-2.04V8.94c0-1.13.91-2.04 2.04-2.04h14.42c1.13 0 2.04.91 2.04 2.04v6.12c0 1.13-.91 2.04-2.04 2.04M10.47 14.04h3.06"
      />
      <path
        fill="currentColor"
        d="M18.12 10.98c-.28 0-.53-.1-.72-.3-.19-.19-.3-.45-.3-.72 0-.13.03-.26.08-.39.05-.12.12-.24.21-.34.38-.38 1.06-.38 1.45 0 .09.1.16.2.21.34a1.01 1.01 0 0 1-.22 1.11c-.18.2-.44.3-.71.3M14.04 10.98c-.13 0-.26-.03-.39-.08s-.24-.12-.34-.21c-.19-.19-.3-.46-.3-.72s.11-.53.3-.72c.1-.09.2-.16.34-.21.38-.16.83-.07 1.11.21.09.1.16.2.21.34a1.01 1.01 0 0 1-.22 1.11 1.08 1.08 0 0 1-.71.28M9.96 10.98c-.13 0-.26-.03-.39-.08-.12-.05-.24-.12-.34-.21-.09-.1-.16-.21-.21-.34a.85.85 0 0 1-.08-.39c0-.13.03-.26.08-.39s.12-.24.21-.34c.38-.38 1.06-.38 1.45 0 .09.1.16.2.21.34.05.12.08.25.08.39 0 .13-.03.26-.08.39-.05.12-.12.23-.21.34-.1.09-.21.16-.34.21-.11.05-.25.08-.38.08M18.12 15.17c-.28 0-.53-.1-.72-.3-.19-.19-.3-.45-.3-.72 0-.13.03-.26.08-.39s.12-.24.21-.34c.38-.38 1.06-.38 1.45 0 .09.1.16.2.21.34.05.12.08.25.08.39 0 .13-.03.26-.08.39-.05.12-.12.23-.21.34-.19.19-.45.29-.72.29M5.88 15.06c-.28 0-.53-.1-.72-.3-.19-.19-.3-.45-.3-.72 0-.13.03-.26.08-.39s.12-.24.21-.34c.38-.38 1.07-.38 1.45 0 .09.1.16.2.21.34a1.01 1.01 0 0 1-.22 1.11c-.1.09-.21.16-.34.21a.75.75 0 0 1-.37.09M5.88 10.98c-.28 0-.53-.1-.72-.3-.19-.19-.3-.45-.3-.72 0-.13.03-.26.08-.39s.12-.24.21-.34c.38-.38 1.07-.38 1.45 0 .09.1.16.2.21.34a1.01 1.01 0 0 1-.22 1.11c-.1.09-.21.16-.34.21a.75.75 0 0 1-.37.09"
      />
    </svg>
  );
}
export default SvgKeyboard;
