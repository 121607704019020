function SvgMedicalCross({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.19 8.92h-3.08c-.57 0-1.03-.46-1.03-1.03V4.81c0-1.14-.92-2.06-2.06-2.06h-2.06c-1.14 0-2.06.92-2.06 2.06v3.08c0 .57-.46 1.03-1.03 1.03H4.79c-1.14 0-2.06.92-2.06 2.06v2.06c0 1.14.92 2.06 2.06 2.06h3.08c.57 0 1.03.46 1.03 1.03v3.08c0 1.14.92 2.06 2.06 2.06h2.06c1.14 0 2.06-.92 2.06-2.06v-3.08c0-.57.46-1.03 1.03-1.03h3.08c1.14 0 2.06-.92 2.06-2.06v-2.06c0-1.14-.92-2.06-2.06-2.06"
      />
    </svg>
  );
}
export default SvgMedicalCross;
