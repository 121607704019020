function SvgCalculator({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.11 21.25H7.89c-1.7 0-3.08-1.38-3.08-3.08V5.84c0-1.7 1.38-3.08 3.08-3.08h8.22c1.7 0 3.08 1.38 3.08 3.08v12.33c0 1.7-1.38 3.08-3.08 3.08"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.57 8.88H9.43a1.54 1.54 0 0 1 0-3.08h5.14a1.54 1.54 0 0 1 0 3.08"
      />
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.43 13.02a.51.51 0 1 0 0-1.02.51.51 0 0 0 0 1.02M14.57 13.02a.51.51 0 1 0 0-1.02.51.51 0 0 0 0 1.02M9.43 18.16a.51.51 0 1 0 0-1.02.51.51 0 0 0 0 1.02M14.57 18.16a.51.51 0 1 0 0-1.02.51.51 0 0 0 0 1.02"
      />
    </svg>
  );
}
export default SvgCalculator;
