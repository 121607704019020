function SvgGolfBall({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 18.17a7.71 7.71 0 1 0 0-15.42 7.71 7.71 0 0 0 0 15.42M12 21.25v-1.03M12 20.22l-1.03-1.03M12 20.22l1.03-1.03M10.97 19.19h2.06"
      />
      <path
        fill="currentColor"
        d="M9.45 7.89c-.57 0-1.03-.46-1.03-1.03s.45-1.03 1.02-1.03h.01c.57 0 1.03.46 1.03 1.03s-.46 1.03-1.03 1.03M7.91 9.94c-.57 0-1.03-.46-1.03-1.03s.45-1.03 1.02-1.03h.01c.57 0 1.03.46 1.03 1.03s-.46 1.03-1.03 1.03M10.99 9.94c-.57 0-1.03-.46-1.03-1.03s.45-1.03 1.02-1.03h.01c.57 0 1.03.46 1.03 1.03s-.46 1.03-1.03 1.03"
      />
    </svg>
  );
}
export default SvgGolfBall;
