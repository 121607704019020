function SvgLayers({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m19.22 9.22-1.33.79-5.28 3.13c-.4.22-.9.22-1.29 0l-5.28-3.13-1.33-.79c-.89-.52-.89-1.86 0-2.38l6.62-3.93c.4-.22.9-.22 1.29 0l6.62 3.93c.87.52.87 1.87-.02 2.38"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.83 11.59c.17.58-.03 1.26-.61 1.6l-6.62 3.93c-.4.22-.9.22-1.29 0l-6.62-3.93c-.54-.32-.75-.94-.63-1.5"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.85 15.62c.14.57-.07 1.21-.62 1.54l-6.62 3.93c-.4.22-.9.22-1.29 0L4.7 17.16c-.51-.3-.73-.87-.65-1.4"
      />
    </svg>
  );
}
export default SvgLayers;
