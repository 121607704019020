function SvgTravelBag({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3.78 15.08h1.03c.57 0 1.03.46 1.03 1.03v4.11c0 .57-.46 1.03-1.03 1.03H3.78c-.57 0-1.03-.46-1.03-1.03v-4.11c0-.57.46-1.03 1.03-1.03M19.19 15.08h1.03c.57 0 1.03.46 1.03 1.03v4.11c0 .57-.46 1.03-1.03 1.03h-1.03c-.57 0-1.03-.46-1.03-1.03v-4.11c.01-.57.47-1.03 1.03-1.03"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.17 9.94v10.28c0 .57-.46 1.03-1.03 1.03H6.86c-.57 0-1.03-.46-1.03-1.03V9.94c0-.57.46-1.03 1.03-1.03h3.6v5.14L12 15.08l1.54-1.03V8.91h3.6c.56.01 1.03.47 1.03 1.03"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.22 4.81v2.06c0 1.13-.93 2.06-2.06 2.06h-4.62V5.85h-3.08v3.08h-4.7C4.62 8.93 3.7 8 3.7 6.87V4.81c0-1.13.91-2.06 2.06-2.06h12.41c1.13 0 2.05.92 2.05 2.06"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.54 5.83v8.22L12 15.08l-1.54-1.03V5.83zM4.81 21.25h15.41M8.92 12h6.16M13.03 18.17h-2.06"
      />
    </svg>
  );
}
export default SvgTravelBag;
