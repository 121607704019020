function SvgPill({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
      className={className}
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.608 1.667A4.73 4.73 0 0 1 16.95 3.05a4.68 4.68 0 0 1 1.383 3.333c0 1.267-.491 2.45-1.383 3.342l-3.617 3.608-3.616 3.617a4.72 4.72 0 0 1-3.325 1.383A4.73 4.73 0 0 1 3.05 16.95a4.68 4.68 0 0 1-1.383-3.333c0-1.259.491-2.442 1.383-3.334l3.617-3.616 3.616-3.617a4.72 4.72 0 0 1 3.325-1.383M6.392 17.083c.925 0 1.791-.366 2.441-1.016l3.175-3.175-4.9-4.9-3.175 3.175a3.44 3.44 0 0 0-1.016 2.45c0 .925.358 1.8 1.016 2.45a3.46 3.46 0 0 0 2.459 1.016m6.641-11.675a.85.85 0 0 0 .6-.25.88.88 0 0 0 .259-.6c0-.233-.1-.45-.259-.608-.316-.317-.891-.317-1.208 0a.82.82 0 0 0-.25.608c0 .217.083.442.25.6.15.159.375.25.608.25m-2.266.517-.6.608a.614.614 0 0 0 0 .884.6.6 0 0 0 .441.183.62.62 0 0 0 .442-.183l.6-.609a.614.614 0 0 0 0-.883.63.63 0 0 0-.883 0"
        clipRule="evenodd"
      />
    </svg>
  );
}
export default SvgPill;
