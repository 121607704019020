function SvgLoading({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 5v1.75M7.05 7.05l1.24 1.24M5 12h1.75M7.05 16.95l1.24-1.24M12 19v-1.75M16.95 16.95l-1.24-1.24M19 12h-1.75M16.95 7.05l-1.24 1.24"
      />
    </svg>
  );
}
export default SvgLoading;
