function SvgHeart({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.25 8.72c0 1.16-.44 2.33-1.33 3.22l-1.48 1.48-6.37 6.37c-.03.03-.04.04-.07.06a.4.4 0 0 1-.07-.06l-7.85-7.85a4.53 4.53 0 0 1-1.33-3.22c0-1.17.44-2.34 1.33-3.23a4.57 4.57 0 0 1 6.44 0l1.47 1.48 1.48-1.48a4.557 4.557 0 0 1 6.43 0 4.47 4.47 0 0 1 1.35 3.23"
      />
    </svg>
  );
}
export default SvgHeart;
