function SvgNumberList({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 10.76a1.32 1.32 0 0 1 1.63-.61 1.24 1.24 0 0 1 .61 1.29c-.07.44-.39.62-1.16 1.36-.5.47-.89.89-1.13 1.15h2.33M4.06 17h2.16l-1.31 1.29a1.35 1.35 0 0 1 1.31 1.43 1.25 1.25 0 0 1-1.11 1 1.32 1.32 0 0 1-1-.4"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.49 5.16H9.33M19.49 18.84H9.33M19.49 12H9.33"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m4.68 3.79.93-.56v3.85"
      />
    </svg>
  );
}
export default SvgNumberList;
