function SvgBatteryLow({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.14 3.78H8.86c-1.1 0-2 .9-2 2v13.47c0 1.1.9 2 2 2h6.28c1.1 0 2-.9 2-2V5.78a2 2 0 0 0-2-2"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.54 2.75h-3.08v1.03h3.08zM9.94 18.17h4.12M9.94 15.08h4.12"
      />
    </svg>
  );
}
export default SvgBatteryLow;
