function SvgBurger({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17 20.22H7c-1.78 0-3.22-1.44-3.22-3.22v-1.92h16.44V17c0 1.78-1.44 3.22-3.22 3.22M19.71 12H4.29a1.54 1.54 0 0 0 0 3.08h15.42a1.54 1.54 0 0 0 0-3.08M12.53 3.78h-1.06c-4.25 0-7.69 3.44-7.69 7.69V12h16.44v-.53c0-4.25-3.44-7.69-7.69-7.69"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3.67 15.33c.39.42.67.99 1.4.99s1.01-.57 1.4-.99M13.06 15.33c.39.42.67.99 1.4.99.74 0 1.02-.57 1.41-.99M19.17 16.31c-.73 0-1.01-.57-1.4-.99M8.37 15.33c.39.42.67.99 1.4.99s1.01-.57 1.4-.99"
      />
    </svg>
  );
}
export default SvgBurger;
