function SvgCocktail({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 15.13v5.1M8.94 21.25h6.12M12 15.13 5.01 5.81h13.98z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7.05 8.36c1.75 0 1.75 1.02 3.49 1.02 1.75 0 1.75-1.02 3.5-1.02 1.16 0 1.55.45 2.19.75M14.04 7.85l3.06-5.1"
      />
    </svg>
  );
}
export default SvgCocktail;
