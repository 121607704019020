function SvgCodeScanner({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M2.75 7.9V5.83c0-1.71 1.38-3.08 3.08-3.08h12.33c1.71 0 3.08 1.38 3.08 3.08V7.9M21.25 16.08v2.09c0 1.71-1.38 3.08-3.08 3.08H5.84c-1.71 0-3.08-1.38-3.08-3.08v-2.06"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.08 16.11H8.91c-.57 0-1.03-.46-1.03-1.03V8.91c0-.57.46-1.03 1.03-1.03h6.17c.57 0 1.03.46 1.03 1.03v6.17c0 .57-.46 1.03-1.03 1.03"
      />
    </svg>
  );
}
export default SvgCodeScanner;
