function SvgTriangleSign({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 9.3v4.16"
      />
      <path
        fill="currentColor"
        d="M12 16.16a.71.71 0 1 0 0-1.42.71.71 0 0 0 0 1.42"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.24 5.3 3.36 15.52c-1.22 2.13.31 4.78 2.76 4.78h11.76c2.45 0 3.99-2.65 2.76-4.78L14.76 5.3c-1.22-2.13-4.3-2.13-5.52 0"
      />
    </svg>
  );
}
export default SvgTriangleSign;
