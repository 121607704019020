function SvgBankVault({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.17 21.25H5.75c-1.66 0-3-1.34-3-3V5.75c0-1.66 1.34-3 3-3h12.42c1.7 0 3.08 1.38 3.08 3.08v12.33c0 1.71-1.38 3.09-3.08 3.09"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 15.08a3.08 3.08 0 1 0 0-6.16 3.08 3.08 0 0 0 0 6.16M8.76 12h-.87M16.11 12h-.94M12 15.38v.73M12 7.89v.73"
      />
      <path
        fill="currentColor"
        d="M4.23 8.92h-.96c-.29 0-.52-.23-.52-.52V6.52c0-.29.23-.52.52-.52h.96c.29 0 .52.23.52.52V8.4c0 .28-.23.52-.52.52M4.23 18h-.96c-.29 0-.52-.23-.52-.52V15.6c0-.29.23-.52.52-.52h.96c.29 0 .52.23.52.52v1.88c0 .29-.23.52-.52.52"
      />
    </svg>
  );
}
export default SvgBankVault;
