function SvgBreadSlice({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.68 4.6c-1.8-1.53-5.17-1.85-7.68-1.85s-5.88.32-7.68 1.85c-.81.69-1.24 1.6-1.26 2.63-.01 1.17.54 2.39 1.5 3.32V20.7c0 .3.24.54.53.54H18.9c.3 0 .53-.25.53-.54V10.55c.96-.92 1.51-2.15 1.5-3.32-.01-1.03-.44-1.94-1.25-2.63"
      />
      <path
        fill="currentColor"
        d="M7.89 19.2a1.03 1.03 0 1 0 0-2.06 1.03 1.03 0 0 0 0 2.06M17.14 8.92a1.03 1.03 0 1 0 0-2.06 1.03 1.03 0 0 0 0 2.06M12.25 6.74a1.03 1.03 0 1 0 0-2.06 1.03 1.03 0 0 0 0 2.06M6.86 7.89a1.03 1.03 0 1 0 0-2.06 1.03 1.03 0 0 0 0 2.06M12.25 17.14a1.03 1.03 0 1 0 0-2.06 1.03 1.03 0 0 0 0 2.06"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m11.71 9.28-.36.36-.36.36.36-.36zM16.38 17.87s-.22.06-.5.14c-.27.08-.5.14-.5.14s.22-.06.5-.14.5-.14.5-.14M15.83 12.97s-.2-.12-.44-.27-.44-.27-.44-.27.2.12.44.27.44.27.44.27M8.18 12.97l.45.25.45.25-.45-.25z"
      />
    </svg>
  );
}
export default SvgBreadSlice;
