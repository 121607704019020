function SvgFlashOff({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m14.07 18.09-1.98 2.89c-.34.49-1.14.27-1.14-.32l-.01-5.64c0-.65-.55-1.17-1.23-1.18l-4.03-.05c-.49-.01-.78-.52-.51-.91l1.63-2.37M8.49 8.02l3.42-5c.34-.49 1.14-.27 1.14.32l.01 5.64c0 .65.55 1.17 1.23 1.18l4.03.05c.49.01.78.52.51.91l-3.14 4.58M2.75 2.75l18.5 18.5"
      />
    </svg>
  );
}
export default SvgFlashOff;
