function SvgPauseNormal({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7.55 19C6.14 19 5 17.86 5 16.45V7.54c0-1.41 1.14-2.55 2.55-2.55s2.55 1.14 2.55 2.55v8.91A2.56 2.56 0 0 1 7.55 19M16.45 19c-1.41 0-2.55-1.14-2.55-2.55V7.54c0-1.41 1.14-2.55 2.55-2.55S19 6.13 19 7.54v8.91c0 1.41-1.14 2.55-2.55 2.55"
      />
    </svg>
  );
}
export default SvgPauseNormal;
