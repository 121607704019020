function SvgPercent({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m14.39 9.58-4.87 4.87"
      />
      <path
        fill="currentColor"
        d="M8.92 10.18a1.26 1.26 0 1 0 0-2.52 1.26 1.26 0 0 0 0 2.52M15.08 16.34a1.26 1.26 0 1 0 0-2.52 1.26 1.26 0 0 0 0 2.52"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 21.25a9.25 9.25 0 1 0 0-18.5 9.25 9.25 0 0 0 0 18.5"
      />
    </svg>
  );
}
export default SvgPercent;
