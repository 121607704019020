function SvgPuzzle({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.25 13v7.25c0 .56-.44 1-1 1H13c-.55 0-1-.44-1-1v-2.12c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5V13c0-.55.45-1 1-1h2.12c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5h2.12c.57 0 1.01.45 1.01 1"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M10.5 16.62c0 .83.67 1.5 1.5 1.5v2.12c0 .56-.45 1-1 1H3.75c-.56 0-1-.44-1-1V13c0-.55.44-1 1-1h2.12c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5H11c.55 0 1 .45 1 1v2.12c-.83 0-1.5.67-1.5 1.5"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.5 7.38c0 .83-.67 1.5-1.5 1.5V11c0 .55-.45 1-1 1H8.88c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5H3.76c-.56 0-1-.45-1-1V3.75c0-.56.44-1 1-1H11c.55 0 1 .44 1 1v2.12c.83 0 1.5.67 1.5 1.51"
      />
    </svg>
  );
}
export default SvgPuzzle;
