function SvgLayoutGrid({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8.68 10.36H5.32c-.93 0-1.68-.75-1.68-1.68V5.32c0-.93.75-1.68 1.68-1.68h3.36c.93 0 1.68.75 1.68 1.68v3.36c0 .93-.75 1.68-1.68 1.68M18.68 10.36h-3.36c-.93 0-1.68-.75-1.68-1.68V5.32c0-.93.75-1.68 1.68-1.68h3.36c.93 0 1.68.75 1.68 1.68v3.36c0 .93-.75 1.68-1.68 1.68M8.68 20.36H5.32c-.93 0-1.68-.75-1.68-1.68v-3.36c0-.93.75-1.68 1.68-1.68h3.36c.93 0 1.68.75 1.68 1.68v3.36c0 .93-.75 1.68-1.68 1.68M18.68 20.36h-3.36c-.93 0-1.68-.75-1.68-1.68v-3.36c0-.93.75-1.68 1.68-1.68h3.36c.93 0 1.68.75 1.68 1.68v3.36c0 .93-.75 1.68-1.68 1.68"
      />
    </svg>
  );
}
export default SvgLayoutGrid;
