function SvgTennisBall({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.13 14.95a9.2 9.2 0 0 1 2.71 6.3 9.27 9.27 0 0 1-6.39-2.71 9.16 9.16 0 0 1-2.7-6.31c2.32.05 4.61.95 6.38 2.72"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.54 5.45a9.27 9.27 0 0 1 2.71 6.39 9.23 9.23 0 0 1-2.71 6.71 9.23 9.23 0 0 1-6.71 2.71 9.27 9.27 0 0 1-6.39-2.71 9.16 9.16 0 0 1-2.7-6.31c-.07-2.44.83-4.92 2.7-6.79a9.2 9.2 0 0 1 6.79-2.7c2.3.05 4.57.96 6.31 2.7"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.54 5.45a9.27 9.27 0 0 1 2.71 6.39 9.23 9.23 0 0 1-6.3-2.71 9.22 9.22 0 0 1-2.71-6.38c2.29.05 4.56.96 6.3 2.7"
      />
    </svg>
  );
}
export default SvgTennisBall;
