function SvgRadio({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.59 7.53H6.4c-2.02 0-3.66 1.64-3.66 3.66v5.69c0 2.02 1.64 3.66 3.66 3.66h11.19c2.02 0 3.66-1.64 3.66-3.66v-5.69c0-2.02-1.64-3.66-3.66-3.66"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7.89 16.09a2.06 2.06 0 1 0 0-4.12 2.06 2.06 0 0 0 0 4.12M13.03 11.97h5.14M13.03 16.09h5.14M5.83 7.58l10.28-4.11"
      />
    </svg>
  );
}
export default SvgRadio;
