function SvgNfc({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.06 8.91H19c1.24 0 2.25 1.01 2.25 2.25V19c0 1.24-1.01 2.25-2.25 2.25H5c-1.24 0-2.25-1.01-2.25-2.25v-7.83c0-1.24 1.01-2.25 2.25-2.25h3.94M6.88 18.16H7.9M6.88 15.08h3.08M8.86 3.67c.95-.59 2.06-.93 3.24-.93 1.11 0 2.15.29 3.04.8M10.47 6.3c.47-.3 1.03-.46 1.62-.46.56 0 1.08.14 1.52.4"
      />
      <path
        fill="currentColor"
        d="M12.12 8.17a.749.749 0 1 0 0 1.5.749.749 0 1 0 0-1.5"
      />
    </svg>
  );
}
export default SvgNfc;
