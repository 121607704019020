function SvgSun({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6M12 4v1M6.34 6.34l.71.71M4 12h1M6.34 17.66l.71-.71M12 20v-1M17.66 17.66l-.71-.71M20 12h-1M17.66 6.34l-.71.71"
      />
    </svg>
  );
}
export default SvgSun;
