function SvgStrawberry({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.8 7.25c.44-.97.42-2.46.4-2.8a.54.54 0 0 0-.28-.46.57.57 0 0 0-.54-.03c-.17.08-1.34.65-2.23 1.22-.47-1.42-1.76-2.29-1.83-2.34a.61.61 0 0 0-.64 0c-.07.05-1.36.93-1.83 2.34-.89-.58-2.06-1.14-2.23-1.22a.57.57 0 0 0-.54.03.57.57 0 0 0-.28.46c-.02.34-.04 1.83.4 2.8"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 21.25c3.58 0 6.94-6.26 6.94-10.04 0-2.42-1.38-5.32-8.02-4.95-4.78.32-5.85 2.83-5.85 4.95-.01 3.78 3.35 10.04 6.93 10.04"
      />
      <path
        fill="currentColor"
        d="M16.11 14.06c-.57 0-1.03-.46-1.03-1.03S15.53 12 16.1 12h.01c.57 0 1.03.46 1.03 1.03s-.46 1.03-1.03 1.03M7.89 14.06c-.57 0-1.03-.46-1.03-1.03S7.31 12 7.88 12h.01c.57 0 1.03.46 1.03 1.03s-.46 1.03-1.03 1.03M12.01 14.06c-.57 0-1.03-.46-1.03-1.03S11.43 12 12 12h.01c.57 0 1.03.46 1.03 1.03s-.46 1.03-1.03 1.03M10.46 18.17c-.57 0-1.03-.46-1.03-1.03s.45-1.03 1.02-1.03h.01c.57 0 1.03.46 1.03 1.03s-.46 1.03-1.03 1.03M13.54 18.17c-.57 0-1.03-.46-1.03-1.03s.45-1.03 1.02-1.03h.01c.57 0 1.03.46 1.03 1.03s-.46 1.03-1.03 1.03M9.95 9.94c-.57 0-1.03-.46-1.03-1.03s.45-1.03 1.02-1.03h.01c.57 0 1.03.46 1.03 1.03s-.46 1.03-1.03 1.03M14.06 9.94c-.57 0-1.03-.46-1.03-1.03s.45-1.03 1.02-1.03h.01c.57 0 1.03.46 1.03 1.03-.01.57-.47 1.03-1.03 1.03"
      />
    </svg>
  );
}
export default SvgStrawberry;
