function SvgParcel2({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12.01 21.25v-9.5l9.24-4.5v9zM11.99 21.25v-9.5l-9.24-4.5v9z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m2.75 7.25 9.24 4.5 3.6-1.75 1.5-.73 4.16-2.03-9.24-4.5-3.94 1.91-1.57.76z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.1 4.16c3.09 1.58 5.99 3.01 9.08 4.59v4.3c-.37-.06-.75-.13-1.12-.19l-1.59 1.66v-4.45c-2.98-1.48-5.88-3.1-8.86-4.59"
      />
    </svg>
  );
}
export default SvgParcel2;
