function SvgHome({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.78 21.25H9.22V15.3c0-.55.45-1 1-1h3.56c.55 0 1 .45 1 1z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.25 11.39v6.71c0 1.74-1.34 3.15-3 3.15H5.75c-1.66 0-3-1.41-3-3.15v-6.71c0-.94.39-1.82 1.08-2.42l6.25-5.49c1.11-.98 2.73-.98 3.84 0l6.25 5.49c.69.6 1.08 1.48 1.08 2.42"
      />
    </svg>
  );
}
export default SvgHome;
