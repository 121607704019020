function SvgCloudOff({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8.92 19.16c2.85-.01 5.7-.02 8.56-.04 2.19-.01 3.78-2.42 3.78-4.46 0-2.14-1.52-3.44-1.61-3.52-.44-.36-.94-.64-1.47-.79M15.69 7.87c-1.87-4.7-9.81-4.03-9.94 2.01-.01.28-.17.53-.43.64-1.84.84-2.9 3-2.47 5.13.25 1.23 1.06 2.41 2.19 3.03M17.65 6.33 3.78 20.21"
      />
    </svg>
  );
}
export default SvgCloudOff;
