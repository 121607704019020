function SvgGlass({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.5 2.75h-11c-.6 0-1.07.51-1.03 1.1l1.17 16.44c.04.54.49.95 1.03.95h8.66c.54 0 .99-.42 1.03-.95l1.17-16.44c.04-.59-.43-1.1-1.03-1.1"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.71 8.86c1.76 0 1.76 1 3.52 1s1.76-1 3.53-1c1.76 0 1.76 1 3.53 1"
      />
    </svg>
  );
}
export default SvgGlass;
