function SvgImages({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.71 21.25H8.48c-1.4 0-2.54-1.14-2.54-2.54V8.48c0-1.4 1.14-2.54 2.54-2.54h10.23c1.4 0 2.54 1.14 2.54 2.54v10.23c0 1.4-1.14 2.54-2.54 2.54"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3.49 17.32a2.54 2.54 0 0 1-.74-1.8V5.29c0-1.4 1.14-2.54 2.54-2.54h10.23c.79 0 1.5.36 1.97.93M5.94 18.1l2.93-2.93c.5-.5 1.31-.5 1.8 0l.75.75c.5.5 1.31.5 1.8 0l3.3-3.3c.5-.5 1.31-.5 1.8 0l2.93 2.93"
      />
      <path
        fill="currentColor"
        d="M10.41 8.42c-.77 0-1.39.62-1.39 1.39s.62 1.39 1.39 1.39 1.39-.62 1.39-1.39-.63-1.39-1.39-1.39"
      />
    </svg>
  );
}
export default SvgImages;
