function SvgShip({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.67 4.8v2.1h-3.34V4.8c0-.16.22-.3.48-.3h2.38c.26 0 .48.13.48.3M5.83 13.73l.78-5.41c.11-.78.97-1.37 1.99-1.37h6.79c1.02 0 1.87.59 1.99 1.37l.78 5.41"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.03 18.72 4.1 15.18a.53.53 0 0 1-.04-.37c.04-.12.13-.23.24-.28l1.53-.79 5.95-3.06a.4.4 0 0 1 .22-.05c.07 0 .16.02.22.05l5.95 3.06 1.53.79c.11.05.2.16.24.28.04.13.03.26-.04.37l-1.93 3.54"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.25 18.47c-1.54 0-1.54 1.03-3.08 1.03s-1.54-1.03-3.08-1.03-1.54 1.03-3.08 1.03-1.54-1.03-3.08-1.03-1.54 1.03-3.09 1.03c-1.54 0-1.54-1.03-3.09-1.03"
      />
    </svg>
  );
}
export default SvgShip;
