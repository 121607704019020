function SvgEarthGlobe({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
      className={className}
      {...props}
    >
      <path
        fill="currentColor"
        d="M10 1.667a8.333 8.333 0 1 0 0 16.666 8.333 8.333 0 0 0 0-16.666m4.567 11.45a4.4 4.4 0 0 1-.909.1c-.217 0-.431-.051-.625-.15a2 2 0 0 1-.183-.15 2.2 2.2 0 0 0-.675-.4 1.37 1.37 0 0 0-1.5.533q-.135.22-.217.467a.5.5 0 0 1-.075.183 1.2 1.2 0 0 1-.275.125l-.216.092a2.37 2.37 0 0 0-1.4 2.041v.975A7.11 7.11 0 0 1 2.917 10.5 3.85 3.85 0 0 0 5 10.567q.452-.1.917-.1c.216-.003.428.052.616.158l.192.142c.196.177.426.313.675.4q.184.05.375.05a1.38 1.38 0 0 0 1.125-.584q.135-.22.217-.466a.6.6 0 0 1 .05-.167q.129-.081.275-.125l.208-.092a2.37 2.37 0 0 0 1.4-2.041 7 7 0 0 0 0-.834v-.533a2.6 2.6 0 0 0-.042-.742 2.1 2.1 0 0 0-.225-.55 1.6 1.6 0 0 1-.116-.25.2.2 0 0 1 0-.183q.134-.127.3-.208.32-.178.583-.434a2.5 2.5 0 0 0 .475-.783 7.075 7.075 0 0 1 4.342 9.9 4.3 4.3 0 0 0-1.8-.008"
      />
    </svg>
  );
}
export default SvgEarthGlobe;
