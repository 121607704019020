function SvgPalm({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M10.72 9.81 9.91 20.8c-.01.12.03.24.1.32.08.08.2.13.31.13h3.35c.11 0 .23-.05.31-.13.07-.08.11-.2.11-.32l-.82-10.99"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7.67 8.29c-1.08-.11-2.15.04-3.03.47-1.31.64-1.98 1.82-1.88 3.3.01.12.06.22.14.29.1.09.24.1.3.1 1.17.04 1.75.06 2.05-.12.17-.1.52-.34.79-.85.2.19.52.43.95.52.54.11.71-.13 1.88-.49.84-.26 1.64-.78 2.52-.78.11 0 .21-.06.28-.14.03-.04.07-.09.09-.14.05-.16.01-.34-.12-.45-1.11-.97-2.55-1.56-3.97-1.71M16.33 8.29c1.08-.11 2.15.04 3.03.47 1.31.64 1.98 1.82 1.88 3.3-.01.12-.06.22-.14.29-.1.09-.24.1-.3.1-.27.01-1.02 0-2.02-.05-.27-.3-.55-.6-.82-.9-.35.15-.71.31-1.06.46-.77-.21-1.38-.36-1.77-.45-.86-.21-1.64-.78-2.52-.78-.11 0-.21-.06-.28-.14-.03-.04-.07-.09-.09-.14a.42.42 0 0 1 .12-.45c1.11-.97 2.55-1.56 3.97-1.71M10.4 5c-.63-.89-1.44-1.59-2.35-1.97-1.35-.57-2.67-.29-3.72.76-.08.08-.12.19-.12.29 0 .14.08.24.12.29.17.21.75.8 1.54 1.57.34.01.69.01 1.03.02l.06.83c.78.5 1.39.92 1.81 1.22.72.51 1.67.72 2.25 1.38.07.08.18.12.29.12.05 0 .11-.01.16-.03a.44.44 0 0 0 .26-.39c0-1.45-.51-2.92-1.33-4.09M13.57 5c.63-.89 1.44-1.59 2.35-1.97 1.35-.57 2.67-.29 3.72.76.08.08.12.19.12.29 0 .14-.08.24-.12.29-.17.21-.75.8-1.54 1.57-.34.01-.69.01-1.03.02l-.06.83c-.78.5-1.39.92-1.81 1.22-.72.51-1.67.72-2.25 1.38-.07.08-.18.12-.29.12-.05 0-.11-.01-.16-.03a.44.44 0 0 1-.26-.39c0-1.45.51-2.92 1.33-4.09M10.97 18.17h2.06M10.97 14.06h2.06"
      />
    </svg>
  );
}
export default SvgPalm;
