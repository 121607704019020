function SvgEnvelopeClosed({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.85 19H5.15a2.4 2.4 0 0 1-2.4-2.4V7.4A2.4 2.4 0 0 1 5.15 5h13.7a2.4 2.4 0 0 1 2.4 2.4v9.2c0 1.33-1.07 2.4-2.4 2.4"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m20.22 5.77-6.87 6.82c-.77.77-1.93.77-2.71 0L3.77 5.77M14.13 11.92l6.09 5.91M3.78 17.83 9.94 12"
      />
    </svg>
  );
}
export default SvgEnvelopeClosed;
