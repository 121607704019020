function SvgAirplay({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.86 15.7H4.8c-1.14 0-2.06-.92-2.06-2.06V6.45c0-1.14.92-2.06 2.06-2.06h14.39c1.14 0 2.06.92 2.06 2.06v7.19c0 1.14-.92 2.06-2.06 2.06h-2.06"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m10.95 13.58-2.73 4.06c-.57.84.04 1.97 1.05 1.97h5.47c1.01 0 1.61-1.13 1.05-1.97l-2.73-4.06c-.51-.74-1.61-.74-2.11 0"
      />
    </svg>
  );
}
export default SvgAirplay;
