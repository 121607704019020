function SvgDatabase({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3.78 5.83v12.34M12 8.91c4.54 0 8.22-1.379 8.22-3.08S16.54 2.75 12 2.75 3.78 4.129 3.78 5.83 7.46 8.91 12 8.91M20.22 9.94c0 1.7-3.68 3.08-8.22 3.08s-8.22-1.38-8.22-3.08M20.22 14.06c0 1.7-3.68 3.08-8.22 3.08s-8.22-1.38-8.22-3.08M20.22 18.17c0 1.7-3.68 3.08-8.22 3.08s-8.22-1.38-8.22-3.08M20.22 5.83v12.34"
      />
    </svg>
  );
}
export default SvgDatabase;
