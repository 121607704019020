function SvgLockOpen({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3.76 18.11v-3.99a3.16 3.16 0 0 1 3.16-3.16h10.17a3.16 3.16 0 0 1 3.16 3.16v3.99a3.16 3.16 0 0 1-3.16 3.16H6.92c-1.75 0-3.16-1.41-3.16-3.16"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.15 10.97H6.85V7.92c0-2.84 2.31-5.15 5.15-5.15s5.15 2.31 5.15 5.15M12 18.18V15.6"
      />
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 16.12a1.03 1.03 0 1 0 0-2.06 1.03 1.03 0 0 0 0 2.06"
      />
    </svg>
  );
}
export default SvgLockOpen;
