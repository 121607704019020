function SvgMagicWand({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m4.61 20.93-1.54-1.54a1.08 1.08 0 0 1 0-1.54l10.8-10.8a1.08 1.08 0 0 1 1.54 0l1.54 1.54c.43.43.43 1.12 0 1.54l-10.8 10.8c-.42.43-1.11.43-1.54 0M11.7 9.5l2.8 2.8M18.5 5.5l.77-.77M15.57 3.8l.28-1.05M20.2 8.43l1.05-.28"
      />
    </svg>
  );
}
export default SvgMagicWand;
