function SvgCookie({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.25 16.92a7.55 7.55 0 0 1-6.83 4.33c-4.17 0-7.56-3.39-7.56-7.56 0-3.71 2.68-6.8 6.22-7.44.12 2.25 1.77 4.1 3.93 4.51-.04.27-.07.55-.07.83a5.45 5.45 0 0 0 4.31 5.33"
      />
      <path
        fill="currentColor"
        d="M11.29 9.57c-.61 0-1.11.5-1.11 1.11s.5 1.11 1.11 1.11 1.11-.5 1.11-1.11c0-.62-.5-1.11-1.11-1.11M8.84 3.78c-.61 0-1.11.5-1.11 1.11S8.23 6 8.84 6s1.11-.5 1.11-1.11c-.01-.62-.5-1.11-1.11-1.11M14.52 12.98c-.61 0-1.11.5-1.11 1.11s.5 1.11 1.11 1.11 1.11-.5 1.11-1.11c0-.62-.5-1.11-1.11-1.11"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.48 17.43s-.21.12-.48.28c-.26.15-.48.28-.48.28s.21-.12.48-.28c.26-.16.48-.28.48-.28M10.39 14.56l.51.22.51.22-.51-.22zM5.43 8.27l.4-.38.4-.38-.4.38zM4.42 13.84a6.64 6.64 0 0 1-1.67-4.41c0-3.69 2.99-6.68 6.68-6.68 2.98 0 5.51 1.95 6.37 4.65"
      />
    </svg>
  );
}
export default SvgCookie;
