function SvgBackpack({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 2.75c-3.41 0-6.17 2.76-6.17 6.17v10.35c0 1.1.89 1.99 1.99 1.99h8.36c1.1 0 1.99-.89 1.99-1.99V8.92c0-3.41-2.76-6.17-6.17-6.17"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.29 14.06H9.72c-.44 0-.8.36-.8.8v2.52c0 .44.36.8.8.8h4.57c.44 0 .8-.36.8-.8v-2.52a.8.8 0 0 0-.8-.8M4.16 18.17h1.68v-5.14H4.16c-.78 0-1.41.63-1.41 1.41v2.32c0 .78.63 1.41 1.41 1.41M19.84 18.17h-1.68v-5.14h1.68c.78 0 1.41.63 1.41 1.41v2.32c0 .78-.63 1.41-1.41 1.41M9.43 7.76h5.14M14.06 7.76v1.03"
      />
    </svg>
  );
}
export default SvgBackpack;
