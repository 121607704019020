function SvgTableTennis({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.07 15.45c2.9-2.9 2.9-7.61 0-10.52-2.9-2.9-7.61-2.9-10.52 0-2.45 2.45-2.82 6.18-1.14 9.03l-4.12 4.12c-.73.73-.73 1.9 0 2.63s1.9.73 2.63 0l4.12-4.12c2.85 1.68 6.58 1.31 9.03-1.14M6.8 8.43l8.77 8.77M19.71 21.25a1.54 1.54 0 1 0 0-3.08 1.54 1.54 0 0 0 0 3.08"
      />
    </svg>
  );
}
export default SvgTableTennis;
