function SvgPool({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.4 21.25c-1.85 0-1.85-1.64-3.7-1.64s-1.85 1.64-3.7 1.64-1.85-1.64-3.7-1.64-1.85 1.64-3.7 1.64M19.4 17.95c-1.85 0-1.85-1.64-3.7-1.64s-1.85 1.64-3.7 1.64-1.85-1.64-3.7-1.64-1.85 1.64-3.7 1.64M12 4.81c0-1.14-.92-2.06-2.06-2.06s-2.06.92-2.06 2.06v8.22M19.19 4.81c0-1.14-.92-2.06-2.06-2.06s-2.06.92-2.06 2.06v8.22M8.5 7.89H15M8 10.97h7"
      />
    </svg>
  );
}
export default SvgPool;
