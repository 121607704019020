function SvgEraser({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m7 13.02 4.07 4.09c.8.81 2.1.81 2.91 0l6.68-6.71c.8-.81.8-2.11 0-2.92l-4.07-4.09c-.8-.81-2.1-.81-2.91 0L7 10.1c-.8.81-.8 2.11 0 2.92M6.37 12.5l-1.98 1.98c-.8.81-.8 2.11 0 2.92l2.32 2.33c.8.81 2.1.81 2.91 0l1.88-1.88M2.76 21.29h18.48"
      />
    </svg>
  );
}
export default SvgEraser;
