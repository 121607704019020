function SvgCertificate({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.98 17.45a7.35 7.35 0 1 0 0-14.7 7.35 7.35 0 0 0 0 14.7"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m6 14.5-2.5 4.27 2.95-.16 1.34 2.63L10 17.5c-1.67-.43-3.01-1.64-4-3M18 14.5c-.98 1.37-2.33 2.56-4 3l2.21 3.75 1.34-2.63 2.95.16zM16.18 9.17c.19-.18.08-.51-.18-.54l-2.41-.35a.34.34 0 0 1-.24-.18l-1.08-2.19c-.12-.24-.46-.24-.57 0L10.62 8.1c-.05.09-.14.16-.24.18l-2.41.35a.32.32 0 0 0-.18.54l1.75 1.7c.08.08.11.18.09.28l-.41 2.4c-.05.26.23.46.46.33l2.16-1.13c.09-.05.21-.05.3 0l2.16 1.13c.23.12.51-.08.46-.33l-.41-2.4c-.02-.1.02-.21.09-.28z"
      />
    </svg>
  );
}
export default SvgCertificate;
