function SvgHeadphones({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.75 20.44h-.96c-1.68 0-3.04-1.36-3.04-3.04s1.36-3.04 3.04-3.04h.96zM17.25 20.44h.96c1.68 0 3.04-1.36 3.04-3.04s-1.36-3.04-3.04-3.04h-.96z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.25 17.4v-4.29C21.25 8.08 17.11 4 12 4s-9.25 4.08-9.25 9.11v4.29"
      />
    </svg>
  );
}
export default SvgHeadphones;
