function SvgRupee({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.19 8.82h6zM10.19 8.82c.39 0 .78.07 1.15.2.36.13.69.33.97.58s.5.54.65.87c.15.32.23.67.23 1.02s-.08.7-.23 1.02-.37.62-.65.87-.61.44-.97.58c-.36.13-.75.2-1.15.2h-1l3 2.67zM9.19 11.48h6z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21 12c0 1.18-.23 2.35-.69 3.44a9.1 9.1 0 0 1-1.95 2.92A9.027 9.027 0 0 1 12 21c-1.18 0-2.35-.23-3.44-.69a9.3 9.3 0 0 1-2.92-1.95A9.027 9.027 0 0 1 3 12c0-2.39.95-4.68 2.64-6.36A9 9 0 0 1 12 3c2.39 0 4.68.95 6.36 2.64A8.97 8.97 0 0 1 21 12"
      />
    </svg>
  );
}
export default SvgRupee;
