function SvgPodcast({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7.1 16A6.96 6.96 0 0 1 5 11c0-3.87 3.13-7 7-7s7 3.13 7 7c0 1.96-.8 3.73-2.1 5"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8.54 13A4 4 0 0 1 8 11c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .73-.2 1.41-.54 2"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2M13.08 14.71h-2.17c-.61 0-1.07.54-.99 1.14l.46 3.29c.07.49.49.86.99.86h1.24c.5 0 .92-.37.99-.86l.46-3.29a.98.98 0 0 0-.98-1.14"
      />
    </svg>
  );
}
export default SvgPodcast;
