function SvgToothbrush({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.16 5.35 5.49 20.69c-.55.67-1.53.76-2.19.19-.66-.56-.74-1.56-.19-2.23L15.78 3.31c.55-.67 1.53-.76 2.19-.19.66.56.74 1.56.19 2.23"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m17 13.32 4.01-4.86c.36-.44.31-1.1-.13-1.47l-2.39-2.04-5.33 6.46 2.39 2.04c.43.36 1.08.31 1.45-.13"
      />
    </svg>
  );
}
export default SvgToothbrush;
