function SvgSettingsGear({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 14.57a2.57 2.57 0 1 0 0-5.14 2.57 2.57 0 0 0 0 5.14"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m20.52 8.27-1.03-1.78a1.54 1.54 0 0 0-2.11-.56l-.51.29c-1.03.59-2.31-.15-2.31-1.34v-.59c0-.85-.69-1.54-1.54-1.54h-2.06c-.85 0-1.54.69-1.54 1.54v.59c0 1.19-1.28 1.93-2.31 1.34l-.51-.29c-.74-.43-1.68-.17-2.11.56L3.46 8.27c-.43.74-.17 1.68.56 2.11l.51.29c1.03.59 1.03 2.08 0 2.67l-.51.29a1.54 1.54 0 0 0-.56 2.11l1.03 1.78c.43.74 1.37.99 2.11.56l.51-.29c1.03-.59 2.31.15 2.31 1.34v.59c0 .85.69 1.54 1.54 1.54h2.06c.85 0 1.54-.69 1.54-1.54v-.59c0-1.19 1.28-1.93 2.31-1.34l.51.29c.74.43 1.68.17 2.11-.56l1.03-1.78c.43-.74.17-1.68-.56-2.11l-.51-.29c-1.03-.59-1.03-2.08 0-2.67l.51-.29c.74-.43.99-1.38.56-2.11"
        clipRule="evenodd"
      />
    </svg>
  );
}
export default SvgSettingsGear;
