function SvgRaceFlag({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M2.75 2.75v18.5M7.37 2.75H2.75v4.11h4.62zM7.37 6.86H2.75v4.11h4.62zM7.37 10.97H2.75v4.11h4.62zM12 6.86H7.38v4.11H12zM12 2.75H7.38v4.11H12zM12 10.97H7.38v4.11H12zM16.62 4.81H12v4.11h4.62zM16.62 13.03H12v4.11h4.62z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.62 8.92H12v4.11h4.62zM16.62 4.81v4.11h4.62V6.24c0-.79-.64-1.43-1.43-1.43zM16.62 17.14h3.27c.75 0 1.36-.61 1.36-1.36v-2.76h-4.62v4.12z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.24 8.92h-4.62v4.11h4.62z"
      />
    </svg>
  );
}
export default SvgRaceFlag;
