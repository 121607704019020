function SvgWatermelonSlice({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.69 15.14c-1.99 1.76-4.7 2.83-7.69 2.81-2.97-.02-5.68-1.11-7.7-2.89"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M4.78 19.19c2.15 1.3 4.62 2.04 7.24 2.06 3.07.02 5.92-.96 8.29-2.66.96-.69 1.23-2.04.61-3.08l-.65-1.09-2.69-4.44-3.71-6.14C13 2.4 10.98 2.38 10.12 3.8l-6.4 10.54-.63 1.04a2.35 2.35 0 0 0 .64 3.1c.33.26.69.49 1.05.71"
      />
      <path
        fill="currentColor"
        d="M8.43 13.07c-.24-.1-.51-.1-.75 0-.13.05-.23.12-.33.21-.18.19-.29.44-.29.7 0 .27.1.51.29.7s.43.29.7.29.51-.1.7-.29.29-.44.29-.7-.11-.51-.29-.7c-.09-.1-.2-.17-.32-.21M12.37 14.05c-.24-.1-.51-.1-.75 0q-.18.075-.33.21c-.18.19-.29.44-.29.7 0 .27.1.51.29.7s.43.29.7.29.51-.1.7-.29.29-.43.29-.7c0-.26-.11-.51-.29-.7a.9.9 0 0 0-.32-.21M16.65 13.27c-.37-.37-1.03-.37-1.4 0-.18.19-.29.44-.29.7 0 .27.1.51.29.7a.97.97 0 0 0 1.07.21q.18-.075.33-.21c.19-.19.29-.43.29-.7-.01-.25-.12-.51-.29-.7M14.88 9.65c-.05-.13-.12-.23-.21-.33-.37-.37-1.03-.37-1.4 0-.09.1-.16.2-.21.33-.05.12-.08.25-.08.37 0 .13.03.26.08.37q.075.18.21.33a.97.97 0 0 0 1.07.21q.18-.075.33-.21a.97.97 0 0 0 .21-1.07M10.93 9.65q-.075-.18-.21-.33c-.38-.37-1.04-.37-1.4 0-.09.1-.16.2-.21.33a.967.967 0 0 0 .21 1.07q.15.135.33.21a.967.967 0 0 0 1.08-.21q.135-.15.21-.33c.06-.12.08-.25.08-.37-.01-.12-.03-.25-.09-.37M11.3 6.78a.97.97 0 0 0 1.07.21q.18-.075.33-.21.135-.15.21-.33c.05-.12.08-.25.08-.37 0-.27-.11-.51-.29-.7a1 1 0 0 0-1.08-.21c-.13.05-.23.12-.33.21-.18.19-.29.44-.29.7 0 .13.03.26.08.37.06.12.13.23.22.33"
      />
    </svg>
  );
}
export default SvgWatermelonSlice;
