function SvgHyperlink({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m13.36 6.18 2.26-2.26a3.157 3.157 0 0 1 4.46 0 3.157 3.157 0 0 1 0 4.46l-5.42 5.42a3.157 3.157 0 0 1-4.46 0"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m10.64 17.82-2.26 2.26a3.157 3.157 0 0 1-4.46 0 3.157 3.157 0 0 1 0-4.46l5.42-5.42a3.157 3.157 0 0 1 4.46 0"
      />
    </svg>
  );
}
export default SvgHyperlink;
