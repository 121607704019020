function SvgPlane({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.73 3.27c-.71-.71-1.86-.7-2.55.03l-3.79 3.95-9.25-3.06L3.2 6.13l7.71 4.75-3.9 4.06-2.5-.41-1.75 1.75 3.68 1.29 1.29 3.68 1.75-1.75-.41-2.5 4.06-3.9 4.75 7.71 1.94-1.94-3.06-9.25 3.95-3.79c.71-.7.72-1.85.02-2.56"
      />
    </svg>
  );
}
export default SvgPlane;
