function SvgBaseball({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.25 12c0 2.49-1 4.77-2.6 6.42A9.2 9.2 0 0 1 12 21.25c-2.61 0-4.96-1.08-6.65-2.83A9.24 9.24 0 0 1 2.75 12c0-2.47.97-4.71 2.55-6.36A9.18 9.18 0 0 1 12 2.75c2.64 0 5.03 1.11 6.7 2.89A9.16 9.16 0 0 1 21.25 12"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7.89 12.06a9.2 9.2 0 0 1-2.54 6.36A9.24 9.24 0 0 1 2.75 12c0-2.47.97-4.71 2.55-6.36a9.22 9.22 0 0 1 2.59 6.42M21.25 12c0 2.49-1 4.77-2.6 6.42a9.22 9.22 0 0 1-2.54-6.36c0-2.5.99-4.76 2.59-6.42A9.16 9.16 0 0 1 21.25 12M5.84 9.94h3.09M5.84 14.06h3.09M18.18 9.94h-3.09M18.18 14.06h-3.09"
      />
    </svg>
  );
}
export default SvgBaseball;
