function SvgPound({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15 9.53c0-.47-.21-.92-.59-1.26A2.15 2.15 0 0 0 13 7.75c-.53 0-1.04.19-1.41.52-.38.33-.59.79-.59 1.26v4.44c0 .47-.21.92-.59 1.26-.38.33-.88.52-1.41.52h6M9 12.2h4"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21 12c0 1.18-.23 2.35-.69 3.44a9.1 9.1 0 0 1-1.95 2.92A9.027 9.027 0 0 1 12 21c-1.18 0-2.35-.23-3.44-.69a9.1 9.1 0 0 1-2.92-1.95 9 9 0 0 1-1.95-2.92 8.967 8.967 0 0 1 1.95-9.8A9.03 9.03 0 0 1 12 3c2.39 0 4.68.95 6.36 2.64A8.97 8.97 0 0 1 21 12"
      />
    </svg>
  );
}
export default SvgPound;
