function SvgBookOpen({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 19.31H4.81a2.07 2.07 0 0 1-2.06-2.06V6.97c0-1.13.93-2.06 2.06-2.06h.91"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 4.92v14.39c-.31-.5-1.23-1.83-3.01-2.56a6.7 6.7 0 0 0-2.18-.47c-.55-.02-.97-.48-.97-1.02V3.87c0-.58.47-1.05 1.05-1.03.57.02 1.25.11 1.98.33A8.2 8.2 0 0 1 12 4.92M21.25 6.97v10.28c0 1.13-.93 2.06-2.06 2.06H12V4.92h7.19c1.13 0 2.06.92 2.06 2.05M15.07 7.97h3.11M15.07 12.11h3.11M15.07 16.26h3.11"
      />
    </svg>
  );
}
export default SvgBookOpen;
