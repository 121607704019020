function SvgGolfHole({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.14 20.22H6.86a4.11 4.11 0 1 1 0-8.22h10.28a4.11 4.11 0 1 1 0 8.22M5.83 16.11V3.78M5.83 3.78 12 6.86M12 6.86 5.83 9.94"
      />
      <path
        fill="currentColor"
        d="M17.65 14.85c-.7 0-1.26.57-1.26 1.26s.57 1.26 1.26 1.26a1.261 1.261 0 0 0 0-2.52"
      />
    </svg>
  );
}
export default SvgGolfHole;
