function SvgMenuIcon3DotsHorizontal({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.19 14.06a2.06 2.06 0 1 0 0-4.12 2.06 2.06 0 0 0 0 4.12M12 14.06a2.06 2.06 0 1 0 0-4.12 2.06 2.06 0 0 0 0 4.12M4.81 14.06a2.06 2.06 0 1 0 0-4.12 2.06 2.06 0 0 0 0 4.12"
      />
    </svg>
  );
}
export default SvgMenuIcon3DotsHorizontal;
