function SvgSpoon({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M2.75 21.14 13.21 10.8M16.54 12.16c2.601 0 4.71-2.082 4.71-4.65s-2.109-4.65-4.71-4.65-4.71 2.082-4.71 4.65 2.109 4.65 4.71 4.65"
      />
    </svg>
  );
}
export default SvgSpoon;
