function SvgDrone({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M4.81 6.87a2.06 2.06 0 1 0 0-4.12 2.06 2.06 0 0 0 0 4.12M19.19 6.87a2.06 2.06 0 1 0 0-4.12 2.06 2.06 0 0 0 0 4.12M4.81 21.25a2.06 2.06 0 1 0 0-4.12 2.06 2.06 0 0 0 0 4.12M19.19 21.25a2.06 2.06 0 1 0 0-4.12 2.06 2.06 0 0 0 0 4.12M13.37 7.89h-2.74a2.74 2.74 0 0 0-2.74 2.74v2.74a2.74 2.74 0 0 0 2.74 2.74h2.74a2.74 2.74 0 0 0 2.74-2.74v-2.74a2.74 2.74 0 0 0-2.74-2.74M8.35 15.65l-2 2M8.35 8.35 6.42 6.42M15.6 8.4l2-2M17.64 17.64l-1.97-1.97M10.97 12h2.06"
      />
    </svg>
  );
}
export default SvgDrone;
