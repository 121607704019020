function SvgSmileySad({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 21.25a9.25 9.25 0 1 0 0-18.5 9.25 9.25 0 0 0 0 18.5"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7.89 16.11c.17-.22 1.67-2.07 4.24-2.06 2.47.02 3.92 1.75 4.11 1.99"
      />
      <path
        fill="currentColor"
        d="M8.4 8.74c-.7 0-1.26.57-1.26 1.26s.57 1.26 1.26 1.26a1.261 1.261 0 0 0 0-2.52M15.6 8.74c-.7 0-1.26.57-1.26 1.26s.57 1.26 1.26 1.26c.7 0 1.26-.57 1.26-1.26s-.57-1.26-1.26-1.26"
      />
    </svg>
  );
}
export default SvgSmileySad;
