function SvgShoe({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M2.65 8.89c-.08-.69.36-1.27.91-1.36.4-.06.82.14 1.08.51.24.35.17.86.45 1.19.37.44 1.23.31 1.72.25.78-.08 1.35-.39 1.63-.57l.69-1.24c1.49.81 3.05 1.62 4.69 2.42 2.13 1.04 4.19 1.97 6.18 2.8.88.3 1.44 1.14 1.35 2-.02.2-.07.4-.16.58-.3.65-1.01 1.01-1.73 1.01H5.01c-1.3 0-2.36-1.06-2.36-2.36zM12.66 10.7l1.12-1.95M14.67 11.86l1.13-1.95M10.71 9.79l1.12-1.95"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M2.65 14.12h4.38l1.6 2.36"
      />
    </svg>
  );
}
export default SvgShoe;
