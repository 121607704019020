function SvgUser3({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M2.75 7.9V5.83c0-1.71 1.38-3.08 3.08-3.08h2.16M21.1 7.9V5.83c0-1.71-1.38-3.08-3.08-3.08h-2.16M2.75 16.02v2.07c0 1.71 1.38 3.08 3.08 3.08h2.16M21.1 16.02v2.07c0 1.71-1.38 3.08-3.08 3.08h-2.16M11.8 10.7a2.47 2.47 0 1 0 0-4.94 2.47 2.47 0 0 0 0 4.94M6.82 17.53v-1.41a2.96 2.96 0 0 1 2.96-2.96h4.43a2.96 2.96 0 0 1 2.96 2.96v1.41"
      />
    </svg>
  );
}
export default SvgUser3;
