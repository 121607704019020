function SvgShoppingBagWithCheckmark2({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.99 21.25h3.83c1.1 0 2-.9 2-2V8.68c0-1.1-.9-2-2-2h-3.83M13.13 8.3V5.53c0-1.53-1.23-2.77-2.74-2.77-1.52 0-2.74 1.24-2.74 2.77V8.3"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.28 4.57c-.11.3-.17.62-.17.95v.9M13.3 2.81c-.94.19-1.7.87-2.02 1.76M16.6 8.3V5.53c0-1.53-1.23-2.77-2.74-2.77-.19 0-.37.02-.55.06M8.54 13.96l1.71 1.74 3.43-3.47"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.6 6.68H6.17c-1.1 0-2 .9-2 2v10.57c0 1.1.9 2 2 2h8.42c1.1 0 2-.9 2-2V8.68c.01-1.11-.89-2-1.99-2"
      />
    </svg>
  );
}
export default SvgShoppingBagWithCheckmark2;
