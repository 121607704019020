function SvgFaceId({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16 2.75h2.17c1.71 0 3.08 1.38 3.08 3.08V7.9M2.75 7.9V5.83c0-1.71 1.38-3.08 3.08-3.08h2.12M16 21.25h2.17c1.71 0 3.08-1.38 3.08-3.08V16.1M2.75 16.1v2.07c0 1.71 1.38 3.08 3.08 3.08h2.12M17.87 16.58c-1.69-.84-3.71-1.33-5.87-1.33-2.23 0-4.3.52-6.02 1.41M12 11.72a2.94 2.94 0 1 0 0-5.88 2.94 2.94 0 0 0 0 5.88"
      />
    </svg>
  );
}
export default SvgFaceId;
