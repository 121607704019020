function SvgVideoCam({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.16 19.08H6.22c-1.92 0-3.47-1.55-3.47-3.47v-3.47c0-1.92 1.55-3.47 3.47-3.47h6.94c1.92 0 3.47 1.55 3.47 3.47v3.47a3.48 3.48 0 0 1-3.47 3.47"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m16.62 16.19 3.06 1.15c.76.28 1.56-.28 1.56-1.08V11.5c0-.81-.81-1.37-1.56-1.08l-3.06 1.15zM6.69 7.78a2.57 2.57 0 1 0 0-5.14 2.57 2.57 0 0 0 0 5.14M12.69 7.78a2.57 2.57 0 1 0 0-5.14 2.57 2.57 0 0 0 0 5.14"
      />
    </svg>
  );
}
export default SvgVideoCam;
