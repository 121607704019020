function SvgEnvelopeOpen({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.19 9.94V2.75H4.81v7.19"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.22 9.28c.62.4 1.03 1.1 1.03 1.89V19c0 1.24-1.01 2.25-2.25 2.25H5c-1.24 0-2.25-1.01-2.25-2.25v-7.83c0-.79.41-1.49 1.03-1.89"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m20.22 9.59-6.87 6.01c-.77.68-1.93.68-2.71 0L3.77 9.59M14.13 15.01l6.09 5.21M3.78 20.22l6.16-5.14M7.89 5.83h1.03M7.89 8.92h8.22"
      />
    </svg>
  );
}
export default SvgEnvelopeOpen;
