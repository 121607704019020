function SvgHardDrive({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.11 21.25H6.89c-1.46 0-2.65-1.19-2.65-2.65v-.4c0-1.46 1.19-2.65 2.65-2.65H17.1c1.46 0 2.65 1.19 2.65 2.65v.4c0 1.46-1.18 2.65-2.64 2.65"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m4.25 18.4.02-.29.94-13.28c.08-1.17 1.04-2.08 2.2-2.08h9.17c1.16 0 2.13.91 2.2 2.08l.94 13.28.02.29M7.08 18.4h.98"
      />
    </svg>
  );
}
export default SvgHardDrive;
