function SvgRouter({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.1 14.29H3.9c-.63 0-1.15.51-1.15 1.15v2.84c0 .63.51 1.15 1.15 1.15h16.2c.63 0 1.15-.51 1.15-1.15v-2.84c0-.64-.51-1.15-1.15-1.15M5.83 19.42v1.03M18.17 19.42v1.03"
      />
      <path
        fill="currentColor"
        d="M18.19 17.88c-.57 0-1.03-.46-1.03-1.03s.45-1.03 1.02-1.03h.01c.57 0 1.03.46 1.03 1.03s-.47 1.03-1.03 1.03M15.1 17.88c-.57 0-1.03-.46-1.03-1.03s.45-1.03 1.02-1.03h.01c.57 0 1.03.46 1.03 1.03s-.46 1.03-1.03 1.03"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5.83 14.29 3.78 6.06M18.17 14.29l2.05-8.23M8.86 4.93C9.81 4.34 10.92 4 12.1 4c1.11 0 2.15.29 3.04.8M10.47 7.55c.47-.3 1.03-.46 1.62-.46.56 0 1.08.14 1.52.4"
      />
      <path
        fill="currentColor"
        d="M12.12 11.2c-.57 0-1.03-.46-1.03-1.03s.45-1.03 1.02-1.03h.01c.57 0 1.03.46 1.03 1.03s-.46 1.03-1.03 1.03"
      />
    </svg>
  );
}
export default SvgRouter;
