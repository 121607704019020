function SvgAltKeyboardSign({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5.83 10.31h3.09L12 14.72h6.17M12.88 10.31h5.29"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.17 21.25H5.84c-1.7 0-3.08-1.38-3.08-3.08V5.84c0-1.7 1.38-3.08 3.08-3.08h12.33c1.7 0 3.08 1.38 3.08 3.08v12.33c0 1.7-1.38 3.08-3.08 3.08"
      />
    </svg>
  );
}
export default SvgAltKeyboardSign;
