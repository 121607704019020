function SvgSailboat({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m21.25 16.11-18.5 2.06c0 1.7 1.38 3.08 3.08 3.08h9.65c.89 0 1.74-.39 2.32-1.08zM12 17.14V2.75M12 2.75c1.03.26 4.57 1.3 6.98 4.78 1.54 2.22 1.93 4.44 2.05 5.6"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12.19 3c.65.84 2.81 3.83 2.6 8.05a11.6 11.6 0 0 1-.55 2.99c2.38-.31 4.41-.61 6.8-.91M12 2.75c-.86 2.28-2.07 4.94-3.81 7.75a45 45 0 0 1-3.39 4.73c2.29-.31 4.58-.61 6.87-.92"
      />
    </svg>
  );
}
export default SvgSailboat;
