function SvgReplyAllArrow({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m8 9.31 2.42 2.3M8 9.31 10.42 7M4 9.31l2.42 2.3M4 9.31 6.42 7M8.72 9.31h7.25c2.23 0 4.03 1.72 4.03 3.85 0 2.12-1.8 3.85-4.03 3.85"
      />
    </svg>
  );
}
export default SvgReplyAllArrow;
