function SvgWebcam({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.23 21.25h5.54M12 17.55v3.7M12 17.55a7.4 7.4 0 1 0 0-14.8 7.4 7.4 0 0 0 0 14.8"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 12.62a2.47 2.47 0 1 0 0-4.94 2.47 2.47 0 0 0 0 4.94"
      />
    </svg>
  );
}
export default SvgWebcam;
