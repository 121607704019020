function SvgFilter({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.45 2.75H6.54C4.36 2.75 3.07 5.2 4.31 7c1.87 2.69 3.75 5.37 5.62 8.06v5.18l4.11 1.01v-6.19c1.87-2.69 3.75-5.37 5.62-8.06 1.26-1.8-.02-4.25-2.21-4.25"
      />
    </svg>
  );
}
export default SvgFilter;
