function SvgOnlineStatus({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.86 7.29a9.2 9.2 0 0 1 1.39 4.86c0 1.67-.43 3.22-1.2 4.56M15.93 9.64c.45.71.69 1.54.69 2.44 0 .83-.22 1.62-.6 2.28M4.14 7.29a9.2 9.2 0 0 0-1.39 4.86c0 1.67.43 3.22 1.2 4.56M8.07 9.64c-.45.71-.69 1.54-.69 2.44 0 .83.22 1.62.6 2.28"
      />
      <path
        fill="currentColor"
        d="M11.92 10.65a1.35 1.35 0 1 0 .001 2.701 1.35 1.35 0 0 0-.001-2.701"
      />
    </svg>
  );
}
export default SvgOnlineStatus;
