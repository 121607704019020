function SvgSdCard({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.19 7.8v11.4c0 1.13-.93 2.06-2.06 2.06H6.85a2.07 2.07 0 0 1-2.06-2.06V4.81c0-1.13.93-2.06 2.06-2.06h7.28c.6 0 1.18.24 1.6.66l2.78 2.78c.45.43.68 1.01.68 1.61M7.89 5.83v1.03M10.97 5.83v1.03M14.06 5.83v1.03"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.61 14.06H8.39c-.28 0-.5.22-.5.5v3.11c0 .28.22.5.5.5h7.22c.28 0 .5-.22.5-.5v-3.11c0-.28-.22-.5-.5-.5"
      />
    </svg>
  );
}
export default SvgSdCard;
