function SvgMoonStars({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
      className={className}
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.692 10a7.11 7.11 0 0 0 3.983 6.367.629.629 0 0 1 0 1.125 8.2 8.2 0 0 1-3.65.841c-4.592 0-8.333-3.741-8.333-8.333s3.741-8.333 8.333-8.333a8.2 8.2 0 0 1 3.65.841.629.629 0 0 1 0 1.125A7.11 7.11 0 0 0 9.692 10m4.116-.617c0 .009.009.009.009.009.241.091.416.35.416.608a.67.67 0 0 1-.416.608l-.309.117a.2.2 0 0 0-.141.133l-.109.292a.67.67 0 0 1-.608.425.67.67 0 0 1-.608-.417l-.117-.3a.26.26 0 0 0-.142-.141l-.291-.1a.69.69 0 0 1-.434-.617.69.69 0 0 1 .434-.617l.291-.108c.067-.017.117-.075.142-.133l.108-.292a.67.67 0 0 1 .617-.425c.267 0 .525.183.617.433l.1.284c.025.058.075.116.141.133zm4.067 4.417-.292-.108c-.066-.017-.116-.075-.141-.134l-.109-.283a.67.67 0 0 0-.616-.433.67.67 0 0 0-.609.425l-.108.291c-.025.059-.075.117-.142.134l-.308.116a.67.67 0 0 0-.417.609c0 .266.184.525.425.616l.3.1a.24.24 0 0 1 .142.142l.108.3c.092.242.35.417.609.417a.67.67 0 0 0 .616-.425l.109-.292a.24.24 0 0 1 .141-.142l.292-.1a.68.68 0 0 0 .433-.616.68.68 0 0 0-.433-.617m-.292-8.708.292.108c.25.092.433.35.433.617a.67.67 0 0 1-.433.608l-.292.108a.24.24 0 0 0-.141.142l-.109.292a.68.68 0 0 1-.616.425.67.67 0 0 1-.609-.417l-.108-.3a.24.24 0 0 0-.142-.142l-.3-.108a.67.67 0 0 1-.425-.608c0-.259.175-.517.417-.609l.308-.116A.24.24 0 0 0 16 4.95l.108-.292a.68.68 0 0 1 .609-.425c.266 0 .533.184.616.434l.109.283a.24.24 0 0 0 .141.142"
        clipRule="evenodd"
      />
    </svg>
  );
}
export default SvgMoonStars;
