function SvgFireplace({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.22 6.86H3.78v11.13c0 1.23 1 2.23 2.23 2.23h11.98c1.23 0 2.23-1 2.23-2.23z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.81 10.97H9.19c-1.29 0-2.33 1.04-2.33 2.33v6.92h10.28V13.3c0-1.28-1.04-2.33-2.33-2.33M20.22 3.78H3.78c-.57 0-1.03.46-1.03 1.03v1.03c0 .57.46 1.03 1.03 1.03h16.44c.57 0 1.03-.46 1.03-1.03V4.81c0-.57-.46-1.03-1.03-1.03"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.62 20.1c.42-.35.74-.83.87-1.39.02-.08.04-.16.06-.23.03-.19.05-.34.05-.46v-.05c0-.78-.35-1.66-.68-2.23l-.3-.52a.3.3 0 0 0-.24-.15c-.1 0-.19.05-.24.14l-.35.55-.73-1.54a.29.29 0 0 0-.26-.16c-.08 0-.17.04-.23.11l-1.43 1.8c-.47.57-.73 1.3-.73 2.04q0 .18.06.48c.02.07.03.15.05.23.14.57.45 1.05.87 1.39"
      />
    </svg>
  );
}
export default SvgFireplace;
