function SvgCash({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.25 19H5.75c-1.66 0-3-1.34-3-3V8c0-1.66 1.34-3 3-3h12.5c1.66 0 3 1.34 3 3v8c0 1.66-1.34 3-3 3"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 14.57a2.57 2.57 0 1 0 0-5.14 2.57 2.57 0 0 0 0 5.14"
      />
      <path
        fill="currentColor"
        d="M5.84 13.03a1.03 1.03 0 1 0 0-2.06 1.03 1.03 0 0 0 0 2.06M18.14 13.03a1.03 1.03 0 1 0 0-2.06 1.03 1.03 0 0 0 0 2.06"
      />
    </svg>
  );
}
export default SvgCash;
