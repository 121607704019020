function SvgRepeat({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m9.92 7.89 2.47 2.46M9.92 7.89l2.47-2.47M14.05 16.11l-2.46-2.46M14.05 16.11l-2.46 2.47M13.05 16.11H6.86a4.11 4.11 0 1 1 0-8.22M10.97 7.89h6.17a4.11 4.11 0 1 1 0 8.22"
      />
    </svg>
  );
}
export default SvgRepeat;
