function SvgShare({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.99 14.02c1.149 0 2.08-.904 2.08-2.02s-.931-2.02-2.08-2.02-2.08.904-2.08 2.02.931 2.02 2.08 2.02M17.07 7a2.02 2.02 0 1 0 0-4.04 2.02 2.02 0 0 0 0 4.04M17.07 21.04a2.02 2.02 0 1 0 0-4.04 2.02 2.02 0 0 0 0 4.04M9.23 10.44l5.6-3.9M14.83 17.46l-5.6-3.9"
      />
    </svg>
  );
}
export default SvgShare;
