function SvgBulletList({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.14 21.2H6.86c-1.14 0-2.06-.92-2.06-2.06V4.75c0-1.14.92-2.06 2.06-2.06h10.28c1.14 0 2.06.92 2.06 2.06v14.39a2.07 2.07 0 0 1-2.06 2.06M7.89 6.86h1.03M12 6.86h4.11M7.89 12h1.03M12 12h4.11M7.89 17.14h1.03M12 17.14h4.11"
      />
    </svg>
  );
}
export default SvgBulletList;
