function SvgUsbPort({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.46 17.94V7.53M7.14 13.81v-2.27M16.86 11.86V9.63M11.46 18.32a1.596 1.596 0 0 0 0 3.19c.88 0 1.59-.72 1.59-1.59 0-.89-.71-1.6-1.59-1.6M7.14 8.26c-.82 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5a1.51 1.51 0 0 0-1.5-1.5M17.73 6.23H16c-.35 0-.63.28-.63.63V8.6c0 .35.28.63.63.63h1.73c.35 0 .63-.28.63-.63V6.86c0-.34-.28-.63-.63-.63M13.35 5.04l-.81-1.42A1.21 1.21 0 0 0 11.47 3c-.45 0-.85.23-1.07.62l-.81 1.42c-.22.39-.22.85 0 1.24s.62.62 1.07.62h1.63c.45 0 .85-.23 1.07-.62s.21-.85-.01-1.24M7.14 13.81l4.32 2.14M16.86 11.86l-5.4 2.29"
      />
    </svg>
  );
}
export default SvgUsbPort;
