function SvgHand({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 11.68V8.04c0-.64.56-1.15 1.23-1.15.68 0 1.23.51 1.23 1.15v4.36M9.53 10.43V6.8c0-.64.56-1.15 1.23-1.15.68 0 1.23.51 1.23 1.15v4.63"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.47 12.39V9.46c0-.64.56-1.15 1.23-1.15.68 0 1.23.51 1.23 1.15v4.28c0 2.54-2.2 4.6-4.94 4.6-2.72 0-4.94-2.05-4.94-4.6V9.2c0-.63.56-1.15 1.23-1.15.68 0 1.23.52 1.23 1.15v1.46"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.25 21.25H5.75c-1.66 0-3-1.34-3-3V5.75c0-1.66 1.34-3 3-3h12.5c1.66 0 3 1.34 3 3v12.5c0 1.66-1.34 3-3 3"
      />
    </svg>
  );
}
export default SvgHand;
