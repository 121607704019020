function SvgRoad({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.14 2.75H8.86c-.55 0-1 .43-1.03.97l-.91 16.44c-.03.59.44 1.08 1.03 1.08h8.1c.59 0 1.06-.5 1.03-1.08l-.91-16.44c-.04-.54-.49-.97-1.03-.97M12 5.83v1.03M12 10.97V12M12 16.11v1.03"
      />
    </svg>
  );
}
export default SvgRoad;
