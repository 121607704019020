function SvgBarChartDown({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.139 18.683h4.11v-5.94h-4.11zM9.944 18.681h4.01v-9.65h-4.01zM2.751 18.68h4.11V5.32h-4.11z"
      />
    </svg>
  );
}
export default SvgBarChartDown;
