function SvgForkKnife({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7.77 2.75v18.48M10.85 2.75v4.11c0 1.7-1.38 3.08-3.08 3.08S4.69 8.56 4.69 6.86V2.75M17.67 15.03l-.03 5.37c0 .44-.36.81-.8.82l-1.16.04a.83.83 0 0 1-.85-.82l.03-5.31zM19.31 6.59l-.05 7.59c0 .44-.36.81-.8.82l-3.61.11.08-12.33.46-.01a3.77 3.77 0 0 1 3.92 3.82"
      />
    </svg>
  );
}
export default SvgForkKnife;
