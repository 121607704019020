function SvgBrokeBone({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.67 20.45c-.51.51-1.21.79-1.95.8-.73-.01-1.42-.29-1.94-.81-.41-.41-.66-.91-.77-1.45a2.78 2.78 0 0 1-2.26-2.71c.01-.73.29-1.44.8-1.95.86-.85 2.17-1.04 3.21-.51l1.91-1.91 1.69.31.01 1.41 1.43.04.46 1.49-2.09 2.09c.54 1.03.35 2.34-.5 3.2M14.33 3.55c.51-.51 1.21-.79 1.95-.8.73.01 1.42.29 1.94.81.41.41.66.91.77 1.45.55.11 1.05.36 1.45.77.52.52.8 1.21.81 1.94-.01.73-.29 1.44-.8 1.95-.86.85-2.17 1.04-3.21.51l-2.49 2.49-.46-1.49-1.44-.03-.01-1.42-1.69-.31 2.66-2.66a2.81 2.81 0 0 1 .52-3.21"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m11.16 9.42 1.69.31.01 1.41 1.44.03.45 1.49M8.67 11.91l1.69.31.01 1.41 1.43.03.46 1.49"
      />
    </svg>
  );
}
export default SvgBrokeBone;
