function SvgCrosshairs({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 20.09a8.09 8.09 0 1 0 0-16.18 8.09 8.09 0 0 0 0 16.18M18.94 12h2.31M5.06 12H2.75M12 5.06V2.75M12 18.94v2.31"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 14.31a2.31 2.31 0 1 0 0-4.62 2.31 2.31 0 0 0 0 4.62"
      />
    </svg>
  );
}
export default SvgCrosshairs;
