function SvgSell({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.27 6.85V2.74M9.98 21.23c3.97 0 7.19-3.224 7.19-7.2s-3.22-7.2-7.19-7.2-7.19 3.224-7.19 7.2 3.22 7.2 7.19 7.2M21.28 2.73l-4.11 4.12M17.17 2.74h4.1"
      />
      <path
        fill="currentColor"
        d="M10.53 13.53v-.92l.69.27c.07.02.13.04.2.04.18 0 .35-.09.46-.24.15-.21.13-.49-.04-.68-.3-.35-.77-.6-1.31-.7v-.05a.56.56 0 1 0-1.12 0v.06c-.97.18-1.67.82-1.67 1.61s.7 1.43 1.67 1.61v.92l-.69-.27a.55.55 0 0 0-.65.2c-.15.21-.13.49.04.68.3.35.77.6 1.31.7v.05a.56.56 0 1 0 1.12 0v-.06c.97-.18 1.67-.82 1.67-1.61s-.71-1.43-1.68-1.61m-1.11-.15c-.35-.11-.56-.31-.56-.47s.21-.36.56-.47zm1.11 2.23v-.94c.35.11.56.31.56.47s-.21.36-.56.47"
      />
    </svg>
  );
}
export default SvgSell;
