function SvgSoup({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.7 19.4H8.3v1.06h7.4zM20.18 10.94c.68 0 1.2.64 1.04 1.3A9.52 9.52 0 0 1 12 19.39c-4.44 0-8.17-3.04-9.22-7.15-.17-.66.35-1.3 1.04-1.3zM11.58 7.77c0-1.06.85-1.06.85-2.11s-.85-1.06-.85-2.11M15.17 7.77c0-1.06.85-1.06.85-2.11s-.85-1.06-.85-2.11M7.98 7.77c0-1.06.85-1.06.85-2.11s-.85-1.06-.85-2.11M3.54 14.11h16.92"
      />
    </svg>
  );
}
export default SvgSoup;
