function SvgArrowExpand({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12.13 21.25h-.25c-5.04 0-9.12-4.09-9.12-9.12v-.25c0-5.04 4.09-9.12 9.12-9.12h.25c5.04 0 9.12 4.09 9.12 9.12v.25c0 5.03-4.09 9.12-9.12 9.12M13.03 10.97 16.1 7.9M16.11 7.89h-2.46M16.11 7.89v2.46M10.97 13.03 7.9 16.1M7.89 16.11h2.46M7.89 16.11v-2.46"
      />
    </svg>
  );
}
export default SvgArrowExpand;
