function SvgChefHat({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.16 8.62a3.6 3.6 0 0 1-2.99 4.37v6.21c0 1.14-.92 2.06-2.06 2.06H7.89c-1.14 0-2.06-.92-2.06-2.06v-6.21a3.595 3.595 0 0 1-2.99-4.37c.31-1.38 1.47-2.49 2.86-2.73a3.52 3.52 0 0 1 2.24.33 4.11 4.11 0 0 1 8.12 0c.65-.34 1.43-.46 2.24-.33 1.39.24 2.55 1.35 2.86 2.73"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8.02 7.89a4.11 4.11 0 1 1 8.09-1.03c0 .35-.04.7-.13 1.03M5.83 18.17h12.34M12 13.03v2.05M15.08 14.06v1.02M8.92 14.06v1.02"
      />
    </svg>
  );
}
export default SvgChefHat;
