function SvgAddressBook({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.57 21.25H8.46c-1.73 0-3.14-1.41-3.14-3.14V5.89c0-1.73 1.41-3.14 3.14-3.14h8.11c1.73 0 3.14 1.41 3.14 3.14v12.22c0 1.73-1.41 3.14-3.14 3.14M6.35 5.83H4.29M6.35 12H4.29M6.35 18.17H4.29"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.51 5.83h-6v4.11h6zM9.51 14.06h6M9.51 17.14h6"
      />
    </svg>
  );
}
export default SvgAddressBook;
