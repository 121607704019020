function SvgDiamond({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12.14 18.23 5.32 9.29l2.3-2.93h8.77l2.29 2.93zM5.32 9.29h13.36M12.14 18.23 9.43 9.29M14.57 9.29l-2.43 8.94M10.47 6.36 9.43 9.29M13.54 6.36l1.03 2.93"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.25 21.25H5.75c-1.66 0-3-1.34-3-3V5.75c0-1.66 1.34-3 3-3h12.5c1.66 0 3 1.34 3 3v12.5c0 1.66-1.34 3-3 3"
      />
    </svg>
  );
}
export default SvgDiamond;
