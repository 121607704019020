function SvgScissors({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.17 21.25a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5M16.18 20.26 6.02 2.75M5.81 21.25a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5M7.8 20.26 17.96 2.75"
      />
      <path
        fill="currentColor"
        d="M12 8.64a1.26 1.26 0 1 0 0-2.52 1.26 1.26 0 0 0 0 2.52M12 4.52A1.26 1.26 0 1 0 12 2a1.26 1.26 0 0 0 0 2.52"
      />
    </svg>
  );
}
export default SvgScissors;
