function SvgSunglasses({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5.06 14.55c-.26-.24-.48-.51-.67-.82-.58-.96-.85-2.37-.44-3.46.28-.76.88-1.34 1.88-1.5.73-.12 2.63-.14 2.63-.14h7.01s1.91.03 2.63.14c1.17.19 1.8.95 2 1.9.18.94-.04 2.19-.56 3.05-1.08 1.78-3.4 2.55-5.75.13-.75-.77-1.11-1.42-1.29-2.07 0 0-.03-.15-.05-.21-.07-.2-.26-.37-.49-.37-.24 0-.44.17-.5.38-.02.05-.05.2-.05.2-.18.65-.54 1.29-1.29 2.05-1.92 2.01-3.84 1.84-5.06.72M3.81 10.8H2.75M20.19 10.8h1.06"
      />
    </svg>
  );
}
export default SvgSunglasses;
