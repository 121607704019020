function SvgPlayNormal({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.14 10.82 7.08 5.15c-.88-.45-1.94.19-1.94 1.18v11.34c0 .99 1.05 1.64 1.94 1.18l11.06-5.67c.96-.49.96-1.87 0-2.36"
      />
    </svg>
  );
}
export default SvgPlayNormal;
