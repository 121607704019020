function SvgBarChartUpWithBorder({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7.41 12v4zM12 10v6zM16.59 8v8zM18.17 21.25H5.84c-1.7 0-3.08-1.38-3.08-3.08V5.83c0-1.7 1.38-3.08 3.08-3.08h12.33c1.7 0 3.08 1.38 3.08 3.08v12.33c0 1.71-1.38 3.09-3.08 3.09"
      />
    </svg>
  );
}
export default SvgBarChartUpWithBorder;
