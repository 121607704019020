function Svg2SpeechBubblesChat({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M10.67 19.97q-.36.03-.72.03c-1.17 0-2.28-.28-3.27-.77l-3.89.56.59-3.91h.01c-.4-.88-.64-1.85-.64-2.88 0-1.89.77-3.6 2.01-4.86"
      />
      <path
        fill="currentColor"
        d="M10.86 11.78c.442 0 .8-.35.8-.78a.79.79 0 0 0-.8-.78.79.79 0 0 0-.8.78c0 .43.358.78.8.78M14.06 11.78c.442 0 .8-.35.8-.78a.79.79 0 0 0-.8-.78.79.79 0 0 0-.8.78c0 .43.358.78.8.78M17.25 11.78c.442 0 .8-.35.8-.78a.79.79 0 0 0-.8-.78.79.79 0 0 0-.8.78c0 .43.358.78.8.78"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m20.62 13.88.59 3.91-3.89-.56c-.7.35-1.46.59-2.27.7-.32.05-.66.07-1 .07-3.98 0-7.19-3.13-7.19-7 0-1.93.79-3.65 2.09-4.92C10.24 4.79 12.05 4 14.06 4c3.98 0 7.19 3.13 7.19 7 0 1.03-.24 2-.64 2.88z"
      />
    </svg>
  );
}
export default Svg2SpeechBubblesChat;
