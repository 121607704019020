function SvgCar({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.15 15.72h.45c.91 0 1.64-.86 1.64-1.88v-1.39c0-.73-.38-1.41-.97-1.71l-1.48-.77-1.32-2.37c-.38-.69-1.05-1.1-1.76-1.09h-5.59c-.65 0-1.26.34-1.65.92l-1.7 2.51-2.81.86c-.72.22-1.21.96-1.21 1.8v1.23c0 1.02.73 1.88 1.64 1.88h.24M8.87 15.72h5.9"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.69 17.46a2.07 2.07 0 1 0 0-4.14 2.07 2.07 0 0 0 0 4.14M17.08 17.46a2.07 2.07 0 1 0 0-4.14 2.07 2.07 0 0 0 0 4.14"
      />
    </svg>
  );
}
export default SvgCar;
