function SvgWaterBottle({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.03 2.75h-2.06v1.03h2.06zM12.34 4.81h-.67c-.85 0-1.65.34-2.13.92L7.32 8.41c-.28.34-.43.73-.43 1.14v9.66c0 1.14 1.14 2.06 2.56 2.06h5.11c1.41 0 2.56-.92 2.56-2.06V9.55c0-.41-.15-.8-.43-1.14l-2.22-2.68c-.48-.58-1.28-.92-2.13-.92"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.76 12.65c.01.84-.75 1.74-1.76 1.74s-1.77-.9-1.76-1.74c.01-.56.34-.96.48-1.11.43-.42.85-.84 1.28-1.26.43.42.85.84 1.28 1.26.14.16.48.56.48 1.11"
      />
    </svg>
  );
}
export default SvgWaterBottle;
