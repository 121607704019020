function SvgBreakfastBowl({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.7 19.4H8.3v1.06h7.4zM20.18 10.94c.68 0 1.2.64 1.04 1.3A9.52 9.52 0 0 1 12 19.39c-4.44 0-8.17-3.04-9.22-7.15-.17-.66.35-1.3 1.04-1.3zM8.01 10.94a2.11 2.11 0 1 0 0-4.22 2.11 2.11 0 0 0 0 4.22M15.99 10.78a2.11 2.11 0 1 0 0-4.22 2.11 2.11 0 0 0 0 4.22"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12.33 10.79a1.59 1.59 0 1 0 0-3.18 1.59 1.59 0 0 0 0 3.18M6.86 6.86 3.54 3.54M4.08 14.09h15.84"
      />
    </svg>
  );
}
export default SvgBreakfastBowl;
