function SvgCloudServer({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.19 16.54c1.25-.83 2.05-2.47 2.06-3.92 0-2.14-1.52-3.44-1.61-3.52-.79-.66-1.79-1.02-2.82-.93-.38.03-.72-.23-.75-.61-.41-6.51-10.17-6.42-10.32.28-.01.28-.17.53-.43.64-1.84.84-2.9 3-2.47 5.13.25 1.23 1.06 2.41 2.19 3.03"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.31 13.54H9.69c-.99 0-1.8.81-1.8 1.8s.81 1.8 1.8 1.8h4.63c.99 0 1.8-.81 1.8-1.8-.01-.99-.81-1.8-1.81-1.8"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.31 9.94H9.69c-.99 0-1.8.81-1.8 1.8s.81 1.8 1.8 1.8h4.63c.99 0 1.8-.81 1.8-1.8-.01-.99-.81-1.8-1.81-1.8M2.75 21.25h6.07M15.08 21.25h6.17M12 17.14v3.08"
      />
      <path
        fill="currentColor"
        d="M12 22a1.26 1.26 0 1 0 0-2.52A1.26 1.26 0 0 0 12 22"
      />
    </svg>
  );
}
export default SvgCloudServer;
