function SvgCherrys({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 21.25c-2.16 0-4.19-.84-5.72-2.37a8.08 8.08 0 0 1-2.36-5.72c0-1.9.41-3.41 1.23-4.49.9-1.18 2.24-1.78 3.98-1.78.91 0 1.45.32 1.89.59.35.19.5.38 1.34.27.23-.06.44-.15.69-.25.51-.22 1.09-.46 1.93-.46 1.56 0 2.87.59 3.77 1.7.88 1.08 1.35 2.6 1.35 4.43 0 2.16-.84 4.19-2.36 5.72A8.2 8.2 0 0 1 12 21.25"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.77 9.94c.14-1.11.56-3.16 2.06-5.14.69-.92 1.43-1.58 2.06-2.06M9.65 9.94c.12.06.85.45 2.06.47 1.29.01 2.08-.41 2.19-.47"
      />
    </svg>
  );
}
export default SvgCherrys;
