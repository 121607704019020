function SvgMortarboard({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m19.49 9.13-1.21.54-.26.12-.71.31-5.12 2.28c-.13.06-.25.09-.39.11-.21.04-.43.04-.64 0-.14-.02-.26-.05-.39-.11L5.65 10.1l-.71-.31-.26-.12-1.21-.54c-.97-.43-.97-1.53 0-1.96l7.3-3.25c.44-.18.98-.18 1.42 0l7.3 3.25c.97.42.97 1.53 0 1.96"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.31 10.1v3.83c0 .34-.16.66-.45.85-.94.62-2.83 1.63-5.38 1.63-.78 0-3.08-.09-5.38-1.63-.28-.19-.44-.51-.44-.85v-3.82l5.12 2.28c.13.06.25.09.39.11.21.04.43.04.64 0 .14-.02.26-.05.39-.11zM20.22 8.15v8.99"
      />
      <path
        fill="currentColor"
        d="M20.22 15.36c-.98 0-1.78.8-1.78 1.78s.8 1.78 1.78 1.78 1.78-.8 1.78-1.78-.8-1.78-1.78-1.78"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.22 18.17v2.05"
      />
    </svg>
  );
}
export default SvgMortarboard;
