function SvgBoxingBag({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M2.75 2.75h18.5M12 2.75v3.08M15.08 21.25H8.91c-.57 0-1.03-.46-1.03-1.03V7.89c0-.57.46-1.03 1.03-1.03h6.17c.57 0 1.03.46 1.03 1.03v12.33c0 .57-.46 1.03-1.03 1.03M12 2.75l4.11 4.11M12 2.75 7.89 6.86"
      />
    </svg>
  );
}
export default SvgBoxingBag;
