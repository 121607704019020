function SvgShoppingBasket({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 5.95c.85 0 1.54-.716 1.54-1.6s-.69-1.6-1.54-1.6-1.54.716-1.54 1.6.69 1.6 1.54 1.6M4.81 11.26l5.65-5.85M19.11 11.26l-5.65-5.85M20.21 11.49l-.53 7.41c-.13 1.34-1.04 2.35-2.11 2.35H6.44c-1.07 0-1.98-1.01-2.11-2.35l-.65-7.63M11.94 15.19v2.13M7.83 15.19v2.13M16.05 15.19v2.13M2.75 11.26h18.5"
      />
    </svg>
  );
}
export default SvgShoppingBasket;
