function SvgGps({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.62 4.81c-1.7 0-3.08 1.34-3.08 2.98 0 2.04 2.76 5.03 2.88 5.16.11.12.3.12.41 0 .12-.13 2.88-3.12 2.88-5.16 0-1.65-1.38-2.98-3.09-2.98"
      />
      <path
        fill="currentColor"
        d="M16.62 8.91a1.06 1.06 0 1 0 0-2.12 1.06 1.06 0 0 0 0 2.12"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.62 13.03v2.05M16.62 14.73v4.74c0 .98-.8 1.78-1.78 1.78H6.07c-.98 0-1.78-.8-1.78-1.78V4.53c0-.98.8-1.78 1.78-1.78h8.77c.98 0 1.78.8 1.78 1.78v.28M4.87 18.15h11.3M16 17.5 4.5 6M9.43 10.97l-5.14 5.14"
      />
    </svg>
  );
}
export default SvgGps;
