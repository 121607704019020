function SvgFlag({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.04 14.47c.71.61.21 1.64-.8 1.64h-7.39V4.55h7.39c1.01 0 1.51 1.04.8 1.64l-3.01 2.57c-1.01.86-1.01 2.27 0 3.13zM3.63 10.97v10.28"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M10.82 14.06H4.65c-.57 0-1.03-.46-1.03-1.03V3.78c0-.57.46-1.03 1.03-1.03h6.17c.57 0 1.03.46 1.03 1.03v9.25c0 .57-.46 1.03-1.03 1.03"
      />
    </svg>
  );
}
export default SvgFlag;
