function SvgLocationArrow({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 2.75c-3.69 0-6.7 3.01-6.7 6.7 0 4.58 6 11.32 6.25 11.6.24.27.66.27.9 0 .26-.28 6.25-7.02 6.25-11.6 0-3.69-3.01-6.7-6.7-6.7"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12.373 11.79a2.37 2.37 0 1 0-.745-4.68 2.37 2.37 0 0 0 .745 4.68"
      />
    </svg>
  );
}
export default SvgLocationArrow;
