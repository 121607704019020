function SvgPieChart5050({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.1 3v18c-4.6 0-8.34-4.03-8.34-9s3.73-9 8.34-9M21.26 12c0 4.35-2.99 7.88-6.67 7.88V4.12c3.68 0 6.67 3.53 6.67 7.88"
      />
    </svg>
  );
}
export default SvgPieChart5050;
