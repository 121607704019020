function SvgNewBadge({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.93 11.24 18.9 9.21V6.25c0-.64-.51-1.15-1.15-1.15h-2.96l-2.03-2.03c-.42-.42-1.1-.42-1.52 0L9.21 5.1H6.25c-.64 0-1.15.51-1.15 1.15v2.96l-2.03 2.03c-.42.42-.42 1.1 0 1.52l2.03 2.03v2.96c0 .64.51 1.15 1.15 1.15h2.96l2.03 2.03c.42.42 1.1.42 1.52 0l2.03-2.03h2.96c.64 0 1.15-.52 1.15-1.15v-2.96l2.03-2.03c.43-.42.43-1.1 0-1.52M5.32 8.57h13.36M5.5 15.34h13"
      />
      <path
        fill="currentColor"
        d="M6.99 11.86v1.53c0 .07-.01.13-.04.19s-.06.11-.11.16a.54.54 0 0 1-.35.14c-.07 0-.13-.01-.19-.04a.457.457 0 0 1-.27-.26.4.4 0 0 1-.04-.19v-2.77c0-.06.01-.13.04-.19.02-.06.06-.11.1-.16s.1-.08.16-.11.13-.04.2-.04q.225 0 .39.18l1.62 1.8v-1.5c0-.07.01-.13.04-.19s.06-.11.11-.15a.54.54 0 0 1 .35-.14c.07 0 .13.01.19.04.06.02.11.06.15.1a.5.5 0 0 1 .14.34v2.79c0 .07-.01.13-.04.19-.02.06-.06.11-.1.16-.04.04-.1.08-.16.1-.06.03-.13.04-.2.04h-.03c-.07-.01-.12-.02-.17-.06a.6.6 0 0 1-.14-.13zM10.49 13.88a.502.502 0 0 1-.32-.15.6.6 0 0 1-.1-.15c-.02-.06-.04-.12-.04-.18v-2.79c0-.07.01-.13.04-.19s.06-.11.1-.16c.04-.04.1-.08.16-.11s.12-.04.2-.04h1.83c.07 0 .13.01.19.04s.11.06.16.11c.04.04.08.1.1.16.03.06.04.12.04.19s-.01.13-.04.19c-.02.06-.06.11-.1.16s-.1.08-.16.11-.12.04-.19.04h-1.34v.34h1.11c.07 0 .13.01.19.04s.11.06.16.11c.04.05.08.1.11.16s.04.13.04.2-.01.13-.04.19-.06.11-.11.15-.1.08-.16.1-.12.04-.19.04h-1.11v.46h1.34c.07 0 .13.01.19.04s.11.06.16.11c.04.05.08.1.1.16.03.06.04.13.04.19 0 .07-.01.13-.04.19-.02.06-.06.11-.1.16-.04.04-.1.08-.16.1s-.12.04-.19.03zM16.06 11.53l.26.62.73-1.73c.03-.06.06-.12.11-.16.05-.05.1-.08.15-.1.06-.02.12-.04.18-.04s.13.01.19.04a.5.5 0 0 1 .17.11c.05.05.08.1.11.15.03.06.04.12.04.19s-.01.13-.04.2l-1.18 2.78c-.04.1-.1.17-.19.22a.54.54 0 0 1-.56 0 .45.45 0 0 1-.19-.22l-.33-.78-.33.78c-.04.1-.11.17-.19.22-.09.05-.18.08-.28.08s-.19-.02-.28-.08a.5.5 0 0 1-.19-.23l-1.18-2.78a.4.4 0 0 1-.04-.19c0-.07.01-.13.04-.19s.06-.11.11-.16a.5.5 0 0 1 .17-.11c.06-.03.12-.04.19-.04.06 0 .12.01.18.04.06.02.11.06.15.1.05.05.08.1.11.16l.74 1.74.74-1.74c.03-.06.06-.11.11-.16s.1-.08.16-.1.12-.04.19-.04.13.01.19.04.12.06.16.11c.05.05.08.1.11.15.03.06.04.12.04.19s-.01.13-.04.2z"
      />
    </svg>
  );
}
export default SvgNewBadge;
