function SvgShoppingBagWithCheckmark3({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.66 6.68H5.7c-1.07 0-1.96.8-2.04 1.84l-.89 10.57c-.1 1.17.84 2.16 2.04 2.16h10.75c1.2 0 2.14-1 2.04-2.16l-.9-10.58c-.09-1.03-.97-1.83-2.04-1.83"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.18 6.68h4.12c1.07 0 1.96.8 2.04 1.84l.89 10.57c.1 1.17-.84 2.16-2.04 2.16h-3.46M12.99 8.3V5.53c0-1.53-1.26-2.77-2.81-2.77S7.37 4 7.37 5.53V8.3"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.69 8.3V5.53c0-1.53-1.26-2.77-2.81-2.77S11.07 4 11.07 5.53v.92M7.54 13.96 9.3 15.7l3.51-3.47"
      />
    </svg>
  );
}
export default SvgShoppingBagWithCheckmark3;
