function SvgSuratKargo({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 72 13"
      className={className}
      {...props}
    >
      <path fill="#292929" d="M0 0h72v13H0z" />
      <g clipPath="url(#surat-kargo_svg__a)">
        <path
          fill="#F6F6F6"
          d="M-542-3926a8 8 0 0 1 8-8H725a8 8 0 0 1 8 8V390.439a8 8 0 0 1-8 8H-534a8 8 0 0 1-8-8z"
        />
        <path fill="currentColor" d="M-542-22H733v420.439H-542z" />
        <path
          fill="#31327D"
          fillRule="evenodd"
          d="M.7 12.416s6.107-6.171 14.249-8.42L11.717 2.52S5.674 4.332.7 12.416M45.153 2.273l-.368 2.454 2.495-2.454h2.103l-2.76 2.553 1.787 4.106h-2.103l-.889-2.254q-.273-.675-.564-.575t-.32.3l-.139.926-.24 1.601h-1.751L43.695.32h.902q.35 0 .562.25.213.252.165.576l-.17 1.127zm7.705 3.304a5.4 5.4 0 0 1-1.024.326l-.595.125q-.47.124-.7.326-.216.275-.257.551-.057.376.136.601.194.2.62.2.7 0 1.162-.401.46-.401.565-1.102l.095-.626zm-.06 3.08q-.57.275-1.175.326-.587.075-1.038.075-.9 0-1.505-.476-.602-.5-.432-1.627.158-1.05.827-1.503.693-.45 1.667-.6.125 0 .303-.026.183-.05.413-.075 1.125-.151 1.198-.65.056-.377-.329-.477a2.1 2.1 0 0 0-.682-.125q-.351 0-.641.1a.85.85 0 0 0-.457.376H49.27q.216-.776.892-1.277.762-.575 2.014-.575 1.401 0 2.031.476.65.5.516 1.402l-.342 2.278q-.15 1.002-.612 1.577-.434.552-.972.8m1.628.275.484-3.229q.146-.975.52-1.627.401-.676.962-1.075.533-.376 1.184-.552.676-.174 1.354-.176h.25l-.266 1.778h-.575q-.727 0-1.129.35t-.516 1.1l-.515 3.43h-1.753m8.59-.401.063-.426q-.555.701-1.358.876a2.37 2.37 0 0 1-1.484-.124q-.707-.3-1.136-1.102-.402-.827-.203-2.153.232-1.553 1.076-2.502.872-.976 2.45-.977 1.202 0 2.13.65.928.652.667 2.404l-.53 3.53q-.262 1.754-1.382 2.38-1.124.649-2.325.65-2.304 0-2.532-2.153h1.827a1 1 0 0 0 .522.526q.396.199.81.1.438-.075.824-.476.41-.4.58-1.202zm-2.424-2.879q-.26 1.728.713 1.928.976.174 1.549-.475.267-.275.452-.852a3.6 3.6 0 0 0 .176-1.176 1.95 1.95 0 0 0-.246-1.026q-.259-.45-.884-.451-.851.001-1.245.626a3.7 3.7 0 0 0-.515 1.428zm5.474 2.679q-.457-.451-.57-1.202a5.6 5.6 0 0 1 .014-1.602q.1-.675.379-1.352.301-.675.77-1.127a3.03 3.03 0 0 1 1.81-.875q1.047-.15 1.83.15.894.376 1.206 1.3.301.828.138 1.928-.12.801-.382 1.376-.262.576-.598.977-.725.827-1.681 1.026a3.4 3.4 0 0 1-1.682.026q-.8-.176-1.232-.627zm1.862-4.407q-.611.576-.735 1.728-.12 1.127.189 1.578.12.2.473.35.357.125.748.025.419-.125.788-.575.367-.45.543-1.453.135-.902-.097-1.352-.209-.45-.565-.576a1.16 1.16 0 0 0-.75 0q-.391.102-.592.275z"
          clipRule="evenodd"
        />
        <path
          fill="#31327D"
          fillRule="evenodd"
          d="M5.057 5.628s3.88-4.06 12.347-5.54c0 0-7.73-.89-14.97 3.94zm36.398 3.306a13 13 0 0 1-1.798-.025q-.94-.075-.767-1.226l.626-4.175h-.751l.053-.35q.056-.375.343-.626.243-.192.534-.22l.299-1.989h.901q.327 0 .539.25a.72.72 0 0 1 .16.602l-.17 1.127h1.026l-.052.35a1 1 0 0 1-.344.626 1 1 0 0 1-.634.226h-.176l-.556 3.704q-.066.426.436.425h.526l-.195 1.303zM15.727 6.732h1.703q-.05.502.262.751.337.252.963.251.45.001.803-.176.326-.175.372-.474.067-.45-.95-.676a4.4 4.4 0 0 1-.653-.15q-1.278-.325-1.66-.777-.433-.451-.322-1.202.143-.952.962-1.578.838-.575 2.04-.575 1.3 0 1.991.575.633.626.532 1.627h-1.077q-.475 0-.662-.425l-.169-.2q-.267-.225-.793-.225c-.35 0-.591.05-.773.15a.6.6 0 0 0-.293.45q-.06.402 1.2.677.142.052.24.075.122.027.223.026 1.226.325 1.665.75.407.45.296 1.203-.165 1.1-1.057 1.702-.834.551-2.335.551-1.427 0-2.04-.575-.642-.576-.476-1.677l.012-.075zM28.05 8.934l.484-3.23q.145-.976.52-1.627A3.5 3.5 0 0 1 30.013 3q.533-.375 1.185-.551a5.4 5.4 0 0 1 1.353-.176h.251l-.267 1.777h-.575q-.726 0-1.128.35-.403.35-.517 1.101l-.514 3.43H28.05zm8.48-3.354q-.377.174-1.025.326l-.594.124q-.47.125-.7.326-.216.277-.258.55-.056.376.136.601.196.2.62.2.701 0 1.162-.401t.566-1.101l.095-.627zm-.06 3.08q-.57.275-1.176.326-.586.075-1.037.075-.901 0-1.505-.477-.602-.5-.432-1.627.157-1.051.826-1.502.693-.45 1.667-.6.127 0 .304-.027.182-.049.412-.075 1.125-.15 1.2-.65.056-.377-.33-.476a2.1 2.1 0 0 0-.683-.125q-.35 0-.64.1a.85.85 0 0 0-.458.376h-1.676q.215-.776.892-1.277.763-.575 2.013-.575 1.402 0 2.032.474.65.502.516 1.402l-.342 2.278q-.15 1.002-.612 1.577-.434.552-.972.8zM22.581 3.472l.05-.346q.053-.35.315-.6a1 1 0 0 1 .663-.252h.913l-.18 1.196h-1.76zm5.746.47-.335 2.239q-.267 1.776-1.276 2.328a4.34 4.34 0 0 1-2.11.552q-1.069-.05-1.887-.601-.695-.482-.583-1.864l.378-2.655h1.758l-.022.15-.366 2.44q-.09.6.219.876.309.274.76.274.45 0 .841-.274.419-.277.507-.876L26.6 3.94h1.728zm.25-1.666-.18 1.196h-1.727l.05-.346q.055-.35.316-.6a1 1 0 0 1 .663-.252h.876z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="surat-kargo_svg__a">
          <path
            fill="#fff"
            d="M-542-3926a8 8 0 0 1 8-8H725a8 8 0 0 1 8 8V390.439a8 8 0 0 1-8 8H-534a8 8 0 0 1-8-8z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgSuratKargo;
