function SvgFirstAidBag({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
      className={className}
      {...props}
    >
      <path
        fill="currentColor"
        d="M14.942 5.167h-1.75v-.3c0-1.767-1.434-3.2-3.192-3.2a3.2 3.2 0 0 0-3.192 3.2v.3h-1.75a3.4 3.4 0 0 0-3.391 3.391v6.384a3.395 3.395 0 0 0 3.391 3.391h9.884a3.395 3.395 0 0 0 3.391-3.391V8.558a3.4 3.4 0 0 0-3.391-3.391m-6.659 7.208a.624.624 0 1 1 0-1.25h1.092V10A.63.63 0 0 1 10 9.375a.63.63 0 0 1 .625.625v1.125h1.092a.624.624 0 1 1 0 1.25h-1.092V13.5a.624.624 0 1 1-1.25 0v-1.125zm-.225-7.508c0-1.075.867-1.95 1.942-1.95s1.942.875 1.942 1.95v.3H8.058z"
      />
    </svg>
  );
}
export default SvgFirstAidBag;
