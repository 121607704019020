function SvgFlask({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.09 2.93v6.81l-3.91 7.64c-.43.83-.39 1.8.1 2.6.48.8 1.34 1.27 2.27 1.27h8.88c.94 0 1.79-.47 2.27-1.27.49-.8.53-1.77.1-2.6l-3.91-7.64V2.93M7.89 2.75h8.22M6.86 14.06h10.28"
      />
    </svg>
  );
}
export default SvgFlask;
