function SvgIMac({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.25 14.31v1.98c0 1.17-.96 2.13-2.13 2.13H4.88c-1.17 0-2.13-.96-2.13-2.13v-1.98zM21.25 5.13v9.18H2.75V5.13C2.75 3.96 3.71 3 4.88 3h14.24c1.17 0 2.13.96 2.13 2.13M14.06 18.42H9.94L8.92 21.5h6.16z"
      />
    </svg>
  );
}
export default SvgIMac;
