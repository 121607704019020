function SvgWifiAlert({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 21.24c-5.1 0-9.24-4.14-9.24-9.24S6.9 2.76 12 2.76 21.24 6.9 21.24 12"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8.85 9.84c.94-.59 2.05-.92 3.23-.92 1.11 0 2.15.29 3.04.8M10.47 12.46c.47-.3 1.03-.46 1.62-.46.55 0 1.08.14 1.52.4"
      />
      <path
        fill="currentColor"
        d="M12.11 16.1c-.57 0-1.03-.46-1.03-1.03s.45-1.03 1.02-1.03h.01c.57 0 1.03.46 1.03 1.03s-.46 1.03-1.03 1.03"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.66 21.25a3.59 3.59 0 1 0 0-7.18 3.59 3.59 0 0 0 0 7.18"
      />
      <path
        fill="currentColor"
        d="M17.65 19.86c-.08 0-.17-.02-.25-.05a.7.7 0 0 1-.21-.14.7.7 0 0 1-.19-.47.7.7 0 0 1 .19-.47c.25-.25.69-.25.94 0 .12.12.2.3.2.48 0 .17-.07.34-.2.47a.75.75 0 0 1-.48.18M17.66 15.46c-.37 0-.66.3-.66.66v1.31a.66.66 0 1 0 1.32 0v-1.31c0-.37-.3-.66-.66-.66"
      />
    </svg>
  );
}
export default SvgWifiAlert;
