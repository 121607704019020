function SvgShoppingBagWithHeart({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.16 7.89H6.83c-1.07 0-1.95.81-2.05 1.88l-.81 9.25c-.11 1.2.84 2.24 2.05 2.24h11.96c1.21 0 2.15-1.03 2.05-2.24l-.81-9.25c-.1-1.07-.99-1.88-2.06-1.88"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.11 10.97V6.86a4.11 4.11 0 1 0-8.22 0v4.11M15.08 14.8c0 .46-.18.91-.53 1.26l-1.86 1.81c-.4.39-1.04.39-1.45 0l-1.73-1.69c-.78-.76-.83-2.08.02-2.76.33-.26.74-.4 1.15-.4.47 0 .93.17 1.29.52.71-.69 1.87-.69 2.58 0 .35.35.53.81.53 1.26"
      />
    </svg>
  );
}
export default SvgShoppingBagWithHeart;
