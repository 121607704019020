function SvgCandlesticksChartAvarage({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.152 14.252h4.1v-5.92h-4.1zM9.985 19.14h3.99V9.52h-3.99zM2.748 14.186h4.1v-9.44h-4.1zM19.2 8.094V4.96M11.98 9.07V5.69M4.8 4.41V2.74M19.2 16.37v-2.11M11.97 21.26v-2.12M4.8 17.07v-2.88"
      />
    </svg>
  );
}
export default SvgCandlesticksChartAvarage;
