function SvgJoystick({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.19 16.11H4.8c-1.14 0-2.06.92-2.06 2.06v1.03c0 1.14.92 2.06 2.06 2.06h14.39c1.14 0 2.06-.92 2.06-2.06v-1.03c0-1.14-.92-2.06-2.06-2.06M12 16.11V8.92M12 8.91a3.08 3.08 0 1 0 0-6.16 3.08 3.08 0 0 0 0 6.16M5.83 16.11v-1.58"
      />
    </svg>
  );
}
export default SvgJoystick;
