function SvgHotAirBalloon({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m14.21 15.68 3.32-4.04a5.44 5.44 0 0 0-3.97-8.89c-.08 0-.15-.01-.23-.01h-2.6500000000000004c-.08 0-.15 0-.23.01a5.44 5.44 0 0 0-3.97 8.89l3.32 4.04M9.79 15.68h4.42M14.05 18.17H9.94v3.08h4.11z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 2.75c-.53.57-2.45 2.77-2.61 6.2-.16 3.7 1.85 6.19 2.32 6.74M12 2.75c.53.57 2.45 2.77 2.61 6.2.16 3.7-1.85 6.19-2.32 6.74"
      />
    </svg>
  );
}
export default SvgHotAirBalloon;
