function SvgEuro({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.45 14.83c-1.15 1.56-3.01 1.56-4.16 0s-1.15-4.1 0-5.66 3.01-1.56 4.16 0M8.45 10.8h3.92zM8.45 13.2h3.92z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21 12c0 1.18-.23 2.35-.69 3.44a9.1 9.1 0 0 1-1.95 2.92A9.027 9.027 0 0 1 12 21c-1.18 0-2.35-.23-3.44-.69a9.3 9.3 0 0 1-2.92-1.95A9.027 9.027 0 0 1 3 12c0-2.39.95-4.68 2.64-6.36A9 9 0 0 1 12 3c2.39 0 4.68.95 6.36 2.64A8.97 8.97 0 0 1 21 12"
      />
    </svg>
  );
}
export default SvgEuro;
