function SvgFootball({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.37 17.59c-.25.33-.52.65-.83.95a9.1 9.1 0 0 1-3.51 2.21L12 18.89v-3.28l3.43-2.49 3.12 1.03zM12 15.61v3.28l-3.01 1.86a8.9 8.9 0 0 1-3.53-2.21c-.31-.3-.58-.62-.83-.95l.81-3.46 3.12-1.01z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m9.87 9.08-1.31 4.04-3.12 1.01-2.69-2.33c.04-1.86.63-3.63 1.69-5.12l3.54-.27zM21.25 11.82l-2.69 2.33-3.12-1.03-1.31-4.04 1.93-2.65 3.51.26a9.16 9.16 0 0 1 1.68 5.13"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m5.44 14.13-.81 3.46A9.14 9.14 0 0 1 2.75 12v-.21zM9.32 3.14 7.97 6.41l-3.54.27c.3-.43.65-.83 1.03-1.21a9.06 9.06 0 0 1 3.86-2.33M19.37 17.59c-.25.33-.52.65-.83.95a9.1 9.1 0 0 1-3.51 2.21L12 18.89v-3.28l3.43-2.49 3.12 1.03zM21.25 11.82l-2.69 2.33-3.12-1.03-1.31-4.04 1.93-2.65 3.51.26a9.16 9.16 0 0 1 1.68 5.13M19.57 6.69l-3.51-.26-1.37-3.29c1.44.44 2.75 1.22 3.84 2.32.4.38.74.79 1.04 1.23M21.25 11.82V12c0 2.05-.66 3.99-1.88 5.59l-.81-3.44zM15.02 20.75c-.97.33-1.98.5-3.02.5s-2.06-.17-3.01-.5L12 18.89z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m16.06 6.43-1.93 2.65H9.87l-1.9-2.67 1.35-3.27c.85-.26 1.76-.39 2.68-.39s1.83.13 2.69.39z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.43 13.12 12 15.61l-3.43-2.49 1.3-4.04h4.26z"
      />
    </svg>
  );
}
export default SvgFootball;
