function SvgDesktopMonitor({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.19 17.16H4.8c-1.14 0-2.06-.92-2.06-2.06V4.8c0-1.14.92-2.06 2.06-2.06h14.39c1.14 0 2.06.92 2.06 2.06v10.3c0 1.14-.92 2.06-2.06 2.06M10.14 17.16l-.59 3.55M13.76 17.33l.56 3.34M7.83 21.25h8.22M3 14.04h18.19"
      />
    </svg>
  );
}
export default SvgDesktopMonitor;
