function SvgLemon({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.23 5.41c-.06-.63-.35-1.22-.79-1.67l-.19-.19c-.44-.44-1.04-.73-1.67-.79-.64-.07-1.27.08-1.81.42a1.7 1.7 0 0 1-1.24.25c-1.48-.29-3.09-.15-4.66.37-1.59.54-3.09 1.48-4.35 2.72-1.24 1.25-2.18 2.76-2.72 4.35-.52 1.56-.66 3.18-.37 4.66.08.43-.01.87-.25 1.24-.34.53-.49 1.17-.42 1.81.06.63.35 1.22.79 1.67l.19.19c.44.44 1.04.73 1.67.79.1.01.21.02.3.02.53 0 1.06-.15 1.51-.44a1.7 1.7 0 0 1 1.24-.25c1.48.29 3.09.15 4.66-.37 1.59-.54 3.09-1.48 4.35-2.72 1.24-1.25 2.18-2.76 2.72-4.35.52-1.56.66-3.18.37-4.66a1.7 1.7 0 0 1 .25-1.24c.34-.54.49-1.17.42-1.81"
      />
      <path
        fill="currentColor"
        d="M15.04 15.16c-.04 0-.07 0-.11-.01a1.1 1.1 0 0 1-.7-.4c-.17-.21-.26-.5-.23-.78.07-.56.61-1.01 1.17-.93.28.03.54.19.71.41.16.21.25.5.22.77v.03c-.07.51-.54.91-1.06.91M14.02 12.07c-.04 0-.07 0-.11-.01a1.1 1.1 0 0 1-.7-.4c-.17-.21-.26-.5-.23-.78.07-.56.61-1.01 1.17-.93.28.03.54.19.71.41.16.21.25.5.22.77v.03c-.08.52-.55.91-1.06.91M17.1 12.07c-.04 0-.07 0-.11-.01a1.1 1.1 0 0 1-.7-.4c-.17-.21-.26-.5-.23-.78.07-.56.61-1.01 1.17-.93.28.03.54.19.71.41.16.21.25.5.22.77v.03c-.08.52-.55.91-1.06.91"
      />
    </svg>
  );
}
export default SvgLemon;
