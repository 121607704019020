function SvgShoppingCart({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.08 21.25c.64 0 1.16-.524 1.16-1.17s-.52-1.17-1.16-1.17-1.16.524-1.16 1.17.52 1.17 1.16 1.17M16.95 21.25c.64 0 1.16-.524 1.16-1.17s-.52-1.17-1.16-1.17-1.16.524-1.16 1.17.52 1.17 1.16 1.17M18.45 15.42H8.17c-.54 0-1.01-.38-1.13-.92L5.52 7.51c-.16-.73.39-1.41 1.13-1.41h13.32c.74 0 1.29.69 1.13 1.41l-1.52 6.99c-.11.54-.58.92-1.13.92"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M2.75 2.75h.83c.55 0 1.02.38 1.13.91L5.64 8M17.35 15.42H6.92c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h12.51"
      />
    </svg>
  );
}
export default SvgShoppingCart;
