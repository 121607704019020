function SvgGoal({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3 16.11h1.85M19.15 16.11h2.15M21.3 16.11V3.78c0-.57-.46-1.03-1.03-1.03H3.83c-.57 0-1.03.46-1.03 1.03v12.33M5.88 7.89h12.33M5.88 12h12.33M7.94 5.83v8.23M12.05 5.83v8.23M16.16 5.83v8.23M14.06 21.25a2.06 2.06 0 1 0 0-4.12 2.06 2.06 0 0 0 0 4.12"
      />
    </svg>
  );
}
export default SvgGoal;
