function SvgFlash({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m18.84 11.12-6.75 9.86c-.34.49-1.14.27-1.14-.32l-.01-5.64c0-.65-.55-1.17-1.23-1.18l-4.03-.05c-.49-.01-.78-.52-.51-.91l6.75-9.86c.34-.49 1.14-.27 1.14.32l.01 5.64c0 .65.55 1.17 1.23 1.18l4.03.05c.48 0 .77.52.51.91"
      />
    </svg>
  );
}
export default SvgFlash;
