function SvgTeaPot({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.03 5.83H11c-1.4 0-2.56.89-2.66 2.05l-.95 11.01c-.11 1.27 1.11 2.36 2.66 2.36h4.94c1.55 0 2.77-1.08 2.66-2.36L16.7 7.88c-.11-1.15-1.27-2.05-2.67-2.05M9.43 8.92h7.19M12.51 5.83V3.78M10.46 2.75h4.11M17.65 9.94h1.34c.96 0 1.74.78 1.74 1.74v1.34c0 1.52-.83 2.85-2.06 3.56M6.86 17.14c-1.14 0-2.06-.92-2.06-2.06v-3.26c0-.55-.22-1.07-.6-1.45l-.94-.94"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.62 14.36c-1.37 0-1.37-.6-2.74-.6s-1.37.6-2.74.6-1.37-.6-2.74-.6"
      />
    </svg>
  );
}
export default SvgTeaPot;
