function SvgReceipt({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.14 2.75v16.87c0 .53-.2 1.01-.52 1.38l-1.16-1.62a.735.735 0 0 0-1.19-.02l-1.23 1.6c-.3.39-.88.39-1.18 0l-1.24-1.63a.74.74 0 0 0-1.18 0l-1.25 1.63c-.3.39-.88.39-1.18 0l-1.25-1.63a.73.73 0 0 0-1.17 0l-1.3 1.7c-.33-.38-.54-.87-.54-1.41V4.81c0-1.14.92-2.06 2.06-2.06zM17.14 4.81V12h2.06c1.14 0 2.06-.92 2.06-2.06V4.8c0-1.14-.92-2.06-2.06-2.06M5.83 6.91h8.23M5.83 10.97h8.23M5.83 15.08h8.23M12 2.75h7.19"
      />
    </svg>
  );
}
export default SvgReceipt;
