function SvgShop({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3.78 10.64v7.33c0 1.14.92 2.06 2.06 2.06h12.33c1.14 0 2.06-.92 2.06-2.06V10.8"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8.92 8.81c0 1.34-1.38 2.42-3.08 2.42-.6 0-1.15-.13-1.62-.36-1.03-.5-1.54-1.69-1.3-2.81l.58-2.74c.16-.78 1.02-1.35 2.03-1.35h12.96c1 0 1.86.57 2.03 1.35l.58 2.71c0 .01.01.02 0 .02.25 1.12-.26 2.31-1.3 2.81-.47.23-1.03.36-1.62.36-1.71 0-3.08-1.08-3.08-2.42"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.08 8.81c0 1.34-1.38 2.42-3.08 2.42-1.71 0-3.08-1.08-3.08-2.42M8.92 8.81V3.98M15.08 8.81V3.98M14.05 15.91H9.94v4.11h4.11z"
      />
    </svg>
  );
}
export default SvgShop;
