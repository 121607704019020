function SvgVerification({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <g clipPath="url(#verification_svg__a)">
        <path
          fill="currentColor"
          d="M24 12c0 .99-1.965 1.71-2.21 2.624-.252.946 1.082 2.55.604 3.377-.486.84-2.547.483-3.228 1.164-.682.682-.325 2.742-1.165 3.229-.827.478-2.431-.856-3.377-.603C13.71 22.035 12.991 24 12 24c-.99 0-1.71-1.965-2.624-2.21-.946-.252-2.55 1.082-3.377.604-.84-.486-.483-2.547-1.164-3.229-.682-.681-2.743-.324-3.228-1.164-.48-.828.855-2.431.602-3.377C1.965 13.71 0 12.991 0 12s1.965-1.71 2.21-2.624c.252-.946-1.082-2.55-.604-3.377.486-.84 2.547-.483 3.229-1.164.681-.682.324-2.743 1.164-3.229.827-.479 2.431.856 3.377.603C10.29 1.965 11.009 0 12 0c.99 0 1.71 1.965 2.624 2.21.946.252 2.55-1.082 3.377-.604.84.486.483 2.547 1.164 3.229.682.681 2.742.324 3.229 1.164.478.827-.856 2.431-.603 3.377C22.035 10.29 24 11.009 24 12"
        />
        <path
          fill="#EDEDED"
          d="M10.146 16.203 7.46 13.516a.62.62 0 0 1 0-.876l.76-.76a.62.62 0 0 1 .876 0l1.484 1.485 5.147-5.181a.62.62 0 0 1 .875-.003l.763.757a.62.62 0 0 1 .003.876L11.023 16.2a.62.62 0 0 1-.878.002"
        />
      </g>
      <defs>
        <clipPath id="verification_svg__a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgVerification;
