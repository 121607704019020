function SvgFolder({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.84 7.35h-3.83c-.72 0-1.39-.38-1.76-1l-.65-1.09c-.37-.62-1.04-1-1.76-1H4.81c-1.14 0-2.06.92-2.06 2.06V17.3c0 1.33 1.08 2.41 2.41 2.41h13.68c1.33 0 2.41-1.08 2.41-2.41V9.75c0-1.32-1.08-2.4-2.41-2.4"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.84 7.35H2.75v9.97c0 1.33 1.08 2.41 2.41 2.41h13.68c1.33 0 2.41-1.08 2.41-2.41V9.77c0-1.34-1.08-2.42-2.41-2.42"
      />
    </svg>
  );
}
export default SvgFolder;
