function SvgPizzaSlice({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.99 5.78c2.77.17 5.54 1.38 7.73 3.6 2.17 2.2 3.36 5 3.54 7.8"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m19.4 17.83-1.23.3-12.48 3.05c-1.68.41-3.15-1.09-2.73-2.79l.88-3.6.06-.23.77-3.13.07-.3 1.3-5.25.32-1.28c.3-1.23 1.49-2.02 2.71-1.82 3 .49 5.82 1.87 8.07 4.15 2.24 2.26 3.59 5.11 4.08 8.14.2 1.24-.6 2.46-1.82 2.76"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.86 14.63c.884 0 1.6-.725 1.6-1.62a1.61 1.61 0 0 0-1.6-1.62c-.884 0-1.6.725-1.6 1.62s.716 1.62 1.6 1.62M5.94 14.02c-.55.56-1.34.74-2.04.52l1-4.08c.38.09.74.27 1.04.57.82.83.82 2.17 0 2.99M7.2 19.72c-.15-.58 0-1.22.44-1.67a1.69 1.69 0 0 1 2.42 0c.22.22.37.49.44.78"
      />
    </svg>
  );
}
export default SvgPizzaSlice;
