function SvgArrowLeft({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.87 2.75h.25c5.04 0 9.12 4.09 9.12 9.12v.25c0 5.04-4.09 9.12-9.12 9.12h-.25c-5.04 0-9.12-4.09-9.12-9.12v-.25c0-5.03 4.09-9.12 9.12-9.12M15.09 12H8.93M8.91 12l2.46 2.47M8.91 12l2.46-2.47"
      />
    </svg>
  );
}
export default SvgArrowLeft;
