function SvgWineGlass({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 2.75H8.24c-.25 0-.46.15-.54.39-.05.15-1.18 3.59-1.18 5.39 0 3.62 3.14 6.46 6.72 5.65 2.44-.59 4.26-2.9 4.26-5.65 0-1.8-1.13-5.23-1.18-5.39a.57.57 0 0 0-.54-.39zM12 15.08v5.14M8.92 21.25h6.16"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.78 7.39c1.76 0 1.76 1 3.52 1s1.76-1 3.53-1c1.76 0 1.76 1 3.53 1"
      />
    </svg>
  );
}
export default SvgWineGlass;
