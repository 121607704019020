function SvgHotelKeys({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 5.08a1.261 1.261 0 0 0 0 2.52c.69 0 1.26-.57 1.26-1.26S12.7 5.08 12 5.08"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 2.75c-2.84 0-5.14 2.3-5.14 5.14 0 2.11 1.27 3.91 3.08 4.71v.43l-1.03 1.03 1.03 1.03-1.03 1.03 1.03 1.03-1.03 1.03 1.03 1.03v1.03c.69.34 1.37.69 2.06 1.03.69-.34 1.37-.69 2.06-1.03v-7.63c1.81-.79 3.08-2.6 3.08-4.71 0-2.85-2.3-5.15-5.14-5.15"
      />
    </svg>
  );
}
export default SvgHotelKeys;
