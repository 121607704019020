function SvgGasStation({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.46 18.17c.87 0 1.58-.67 1.58-1.49 0-1.02-1.41-2.51-1.47-2.58a.145.145 0 0 0-.21 0c-.06.06-1.47 1.56-1.47 2.58-.01.82.7 1.49 1.57 1.49M2.75 21.25h17.41z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.87 2.75H7.04c-1.17 0-2.12.95-2.12 2.12v16.38h13.06V4.87c.01-1.17-.94-2.12-2.11-2.12"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.66 10.97H9.25c-.58 0-1.06-.47-1.06-1.06V6.89c0-.58.47-1.06 1.06-1.06h4.41c.58 0 1.06.47 1.06 1.06v3.02c0 .59-.47 1.06-1.06 1.06M20.16 16.11h-2.18V5.83l2.95 2.78c.2.19.32.45.32.73v5.74c0 .57-.49 1.03-1.09 1.03M17.99 12h3.26"
      />
    </svg>
  );
}
export default SvgGasStation;
