function SvgFingerprint({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.32 7.85c.37.85.58 1.78.58 2.77v2.75c0 3.8-3.08 6.87-6.87 6.87-.77 0-1.52-.13-2.21-.36M16.74 5.62a6.85 6.85 0 0 0-4.72-1.87c-3.8 0-6.88 3.08-6.88 6.87 0 1.37-.17 2.76.13 4.11.28 1.27.91 2.47 1.8 3.42"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.13 16.3a3.124 3.124 0 0 1-4.22-2.92v-2.43M10.66 7.81a3.124 3.124 0 0 1 4.48 2.81v2.75c0 .17-.01.35-.04.51M12.02 11.07v1.54"
      />
    </svg>
  );
}
export default SvgFingerprint;
