function SvgDiscountBubble({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m14.39 9.58-4.87 4.87"
      />
      <path
        fill="currentColor"
        d="M8.92 7.66a1.261 1.261 0 0 0 0 2.52c.69 0 1.26-.57 1.26-1.26.01-.69-.56-1.26-1.26-1.26M15.08 13.81a1.261 1.261 0 0 0 0 2.52c.69 0 1.26-.57 1.26-1.26s-.56-1.26-1.26-1.26"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.44 15.8h-.01c.52-1.16.82-2.45.82-3.8 0-5.11-4.14-9.25-9.25-9.25S2.75 6.89 2.75 12a9.248 9.248 0 0 0 13.45 8.24l5 .74z"
      />
    </svg>
  );
}
export default SvgDiscountBubble;
