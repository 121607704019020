function SvgCoins({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.25 2.75H5.75a3 3 0 0 0-3 3v12.5a3 3 0 0 0 3 3h12.5a3 3 0 0 0 3-3V5.75a3 3 0 0 0-3-3"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.73 10.78c0 .56-.65 1.06-1.64 1.37a7.6 7.6 0 0 1-1.82.28 2 2 0 0 1-.34 0c-2.09 0-3.79-.75-3.79-1.67s1.7-1.67 3.79-1.67h.83a5 5 0 0 1 2.48.91 1.1 1.1 0 0 1 .49.78"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.73 10.78v2.39c0 .61-.73 1.13-1.83 1.42a7.9 7.9 0 0 1-2 .25c-1.73 0-3.19-.51-3.64-1.2"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.73 13.17v2.49c0 .93-1.7 1.67-3.8 1.67s-3.79-.74-3.79-1.67M10.49 8.9a.92.92 0 0 1-.22-.56c0-.92 1.7-1.67 3.8-1.67s3.79.75 3.79 1.67S16.16 10 14.07 10a8 8 0 0 1-.83 0M13.73 14.88h.34c2.09 0 3.79-.74 3.79-1.66M13.73 12.39h.34c2.09 0 3.79-.75 3.79-1.66M17.86 13.22V8.34M6.14 15.66v-4.88"
      />
    </svg>
  );
}
export default SvgCoins;
