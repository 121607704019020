function SvgCandlesticksChartDown({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M4.8 4.46v-1.7M2.748 14.205h4.1v-9.44h-4.1zM17.152 18.503h4.1v-5.92h-4.1zM19.2 12.47V9.83M19.2 21.25v-2.64M4.8 16.39v-1.7M9.985 17.082h3.99v-9.62h-3.99zM11.98 7.01V3.63M11.97 19.2v-2.11"
      />
    </svg>
  );
}
export default SvgCandlesticksChartDown;
