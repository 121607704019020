function SvgEye({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.2 9.01c1.4 1.69 1.4 4.29 0 5.98-2 2.41-4.93 3.95-8.2 3.95s-6.19-1.53-8.2-3.95c-1.4-1.69-1.4-4.29 0-5.98 2-2.41 4.93-3.95 8.2-3.95s6.19 1.53 8.2 3.95"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.2 9.01c1.4 1.69 1.4 4.29 0 5.98-2 2.41-4.93 3.95-8.2 3.95s-6.19-1.53-8.2-3.95c-1.4-1.69-1.4-4.29 0-5.98 2-2.41 4.93-3.95 8.2-3.95s6.19 1.53 8.2 3.95"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 15.08a3.08 3.08 0 1 0 0-6.16 3.08 3.08 0 0 0 0 6.16"
      />
    </svg>
  );
}
export default SvgEye;
