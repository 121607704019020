function SvgUser1({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.76 10.86a2.93 2.93 0 1 0 0-5.86 2.93 2.93 0 0 0 0 5.86M5.84 19v-1.67c0-1.95 1.58-3.52 3.52-3.52h5.27c1.95 0 3.52 1.58 3.52 3.52V19"
      />
    </svg>
  );
}
export default SvgUser1;
