function SvgFolderConnect({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 15.08v3.09M9.94 19.71H3.78M20.22 19.71h-6.16M12 21.25a1.54 1.54 0 1 0 0-3.08 1.54 1.54 0 0 0 0 3.08M17.7 5.83h-3.19c-.6 0-1.16-.38-1.47-1l-.54-1.09c-.31-.62-.87-1-1.47-1H6c-.95 0-1.71.92-1.71 2.06v7.859999999999999c0 1.33.9 2.41 2.01 2.41h11.4c1.11 0 2.01-1.08 2.01-2.41V8.23c0-1.32-.9-2.4-2.01-2.4"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.51 5.83H4.29v7.05c0 1.22.99 2.2 2.2 2.2h11.02a2.2 2.2 0 0 0 2.2-2.2V8.03c0-1.21-.99-2.2-2.2-2.2"
      />
    </svg>
  );
}
export default SvgFolderConnect;
