function SvgBinanceCoin({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18"
      />
      <path
        fill="currentColor"
        d="m11.66 13.03-.69-.69a.477.477 0 0 1 0-.68l.69-.69c.19-.19.49-.19.68 0l.69.69c.19.19.19.49 0 .68l-.69.69c-.19.19-.49.19-.68 0M7.83 13.03l-.69-.69a.477.477 0 0 1 0-.68l.69-.69c.19-.19.49-.19.68 0l.69.69c.19.19.19.49 0 .68l-.69.69c-.19.19-.49.19-.68 0M15.49 13.03l-.69-.69a.477.477 0 0 1 0-.68l.69-.69c.19-.19.49-.19.68 0l.69.69c.19.19.19.49 0 .68l-.69.69c-.19.19-.49.19-.68 0"
      />
      <path
        fill="currentColor"
        d="m9.85 11-.68-.68a.477.477 0 0 1 0-.68l2.49-2.5c.19-.19.49-.19.68 0l2.5 2.5c.19.19.19.49 0 .68l-.67.68c-.19.19-.49.19-.68 0l-1.16-1.14a.496.496 0 0 0-.68 0L10.53 11c-.19.19-.49.19-.68 0M14.15 13l.68.68c.19.19.19.49 0 .68l-2.49 2.5c-.19.19-.49.19-.68 0l-2.5-2.5a.477.477 0 0 1 0-.68l.67-.68c.19-.19.49-.19.68 0l1.16 1.14c.19.18.49.18.68 0l1.13-1.13c.18-.2.48-.2.67-.01"
      />
    </svg>
  );
}
export default SvgBinanceCoin;
