function SvgCoffeeCup({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.59 6.86c0-1.03.82-1.03.82-2.06s-.82-1.03-.82-2.06M15.08 6.86c0-1.03.82-1.03.82-2.06s-.82-1.03-.82-2.06M8.09 6.86c0-1.03.82-1.03.82-2.06s-.82-1.03-.82-2.06M19.71 21.25H3.78h15.93m0 0h.51zM17.14 9.94H5.83v4.63a5.65 5.65 0 0 0 11.3 0V9.94zM17.65 16.11h-.51v-5.14h.51a2.57 2.57 0 1 1 0 5.14"
      />
    </svg>
  );
}
export default SvgCoffeeCup;
