function SvgMedicalFile({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 8.92V12M13.54 10.46h-3.08M16.11 4.29h1.03c1.14 0 2.06.92 2.06 2.06V19.2c0 1.14-.92 2.06-2.06 2.06H6.86c-1.14 0-2.06-.92-2.06-2.06V6.35c0-1.14.92-2.06 2.06-2.06h2.06"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.06 5.83H9.95c-.57 0-1.03-.46-1.03-1.03V3.77c0-.57.46-1.03 1.03-1.03h4.11c.57 0 1.03.46 1.03 1.03V4.8c-.01.57-.47 1.03-1.03 1.03M7.89 15.08h8.22M7.89 18.17h8.22"
      />
    </svg>
  );
}
export default SvgMedicalFile;
