function SvgShirt({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8.54 2.77c-.13.04-1.74.87-2.93 1.48-.82.42-1.42 1.16-1.69 2.04L2.8 10.04c-.18.6.19 1.23.8 1.37l2.68.59M12 5.81c-.46 0-.98-.09-1.51-.36-1.62-.81-1.93-2.52-1.95-2.68M15.46 2.77c.13.04 1.74.87 2.93 1.48.82.42 1.42 1.16 1.69 2.04l1.12 3.75c.18.6-.19 1.23-.8 1.37l-2.68.59M12 5.81c.46 0 .98-.09 1.51-.36 1.62-.81 1.93-2.52 1.95-2.68"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.69 10.64v8.27c0 .63-.34 1.2-.89 1.5-.28.15-.6.3-.96.43 0 0-.19.07-.39.13-1.33.4-5.57.4-6.9 0-.19-.06-.38-.13-.38-.13-.36-.13-.68-.28-.96-.43-.55-.3-.89-.87-.89-1.5v-8.27M8.54 2.77h6.92"
      />
    </svg>
  );
}
export default SvgShirt;
