function SvgDeposit({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.98 10.97V2.74M15.06 7.87l-3.08 3.08M8.89 7.87l3.09 3.08M10.97 17.12h2.06M15.1 13.01h2.04a4.11 4.11 0 1 1 0 8.22H6.86a4.11 4.11 0 1 1 0-8.22h2.07"
      />
    </svg>
  );
}
export default SvgDeposit;
