function SvgSyncPromptAlert({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M10 20.28a9 9 0 0 1-7.25-8.66A9.07 9.07 0 0 1 12 2.74a9.07 9.07 0 0 1 9.25 8.88A9 9 0 0 1 14 20.29M9.99 20.3l-.98-2.77M9.99 20.3l-2.89.94M12.03 7.89V12"
      />
      <path
        fill="currentColor"
        d="M12 14.06a1.06 1.06 0 1 0 1.1 1.06 1.1 1.1 0 0 0-1.1-1.06"
      />
    </svg>
  );
}
export default SvgSyncPromptAlert;
