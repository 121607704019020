function SvgDisplay({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.04 3.78H5.96a3.21 3.21 0 0 0-3.21 3.21v6.94a3.21 3.21 0 0 0 3.21 3.21h12.08a3.21 3.21 0 0 0 3.21-3.21V6.99a3.21 3.21 0 0 0-3.21-3.21M9.94 20.22h4.12M12 17.14v2.88"
      />
    </svg>
  );
}
export default SvgDisplay;
