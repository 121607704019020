function SvgDownload({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.8 8.4h.9M17.1 8.4h1.3c1.6 0 2.9 1.3 2.9 3v6.9c0 1.7-1.3 3-2.9 3H5.5c-1.6 0-2.9-1.3-2.9-3v-6.9c0-1.7 1.3-3 2.9-3H7M11.9 2.9v8.3M11.9 11.2l2.9-2.9M11.9 11.2 9.1 8.3M15 14.7H8.9M15 17.7H8.9"
      />
    </svg>
  );
}
export default SvgDownload;
