function SvgHotDog({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.15 4.91 4.91 15.15a2.78 2.78 0 0 0 0 3.94 2.78 2.78 0 0 0 3.94 0L19.09 8.85a2.78 2.78 0 0 0 0-3.94 2.78 2.78 0 0 0-3.94 0"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7.06 16.51c1.18-1.18 1.6-.76 2.79-1.94 1.18-1.18.76-1.6 1.94-2.79 1.18-1.18 1.61-.76 2.79-1.94s.76-1.61 1.94-2.79M11.61 3.73l-7.88 7.88a3.34 3.34 0 0 0 0 4.73l12.6-12.6a3.317 3.317 0 0 0-4.72-.01M12.39 20.27l7.88-7.88a3.34 3.34 0 0 0 0-4.73l-12.6 12.6a3.317 3.317 0 0 0 4.72.01"
      />
    </svg>
  );
}
export default SvgHotDog;
