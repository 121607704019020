function SvgEgg({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.09 6.44c-.65-1.11-1.4-1.98-2.21-2.62-.92-.71-1.89-1.07-2.88-1.07s-1.95.36-2.88 1.07c-.81.64-1.56 1.51-2.21 2.62-1.24 2.12-1.98 4.85-1.98 7.3 0 1.23.18 2.34.54 3.31q.525 1.41 1.53 2.37c1.24 1.19 2.97 1.83 4.99 1.83s3.75-.64 4.99-1.83q1.005-.975 1.53-2.37c.36-.97.54-2.08.54-3.31.02-2.45-.72-5.18-1.96-7.3"
      />
    </svg>
  );
}
export default SvgEgg;
