function SvgTooth({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.97 4.72c-1-1.33-2.04-1.95-3.28-1.95-.8 0-1.58.26-2.33.51h-.03c-.8.27-1.56.51-2.38.51-.81 0-1.62-.27-2.41-.52-.77-.26-1.56-.52-2.35-.52-1.25 0-2.27.64-3.2 1.99-1.47 2.13-.64 4.41.04 6.26.3.81.58 1.59.58 2.17 0 3 .64 8.08 3 8.08 1.62 0 2.13-1.88 2.57-3.56.46-1.76.87-2.99 1.83-2.99.83 0 1.14.8 1.58 2.73.38 1.69.85 3.78 2.85 3.78 2.79 0 3-6.16 3-8.05 0-.56.27-1.33.56-2.14l.02-.03c.64-1.87 1.48-4.2-.05-6.27"
      />
    </svg>
  );
}
export default SvgTooth;
