function SvgBlockchain({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m18.35 6.22-6.24-3.47-6.5 3.61M13.57 19.35l6.37-3.54V8.76M4.28 8.93v6.88l6.04 3.36M15.69 13.63V9.28L12.11 7.1 8.53 9.28v4.35l3.58 2.18zM12.11 15.81v-4.35M8.53 9.28l3.58 2.18M15.69 9.28l-3.58 2.18"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M4.28 8.64c.845 0 1.53-.69 1.53-1.54s-.685-1.54-1.53-1.54-1.53.69-1.53 1.54.685 1.54 1.53 1.54M12.04 21.25c.845 0 1.53-.69 1.53-1.54s-.685-1.54-1.53-1.54-1.53.69-1.53 1.54.685 1.54 1.53 1.54M19.72 8.64c.845 0 1.53-.69 1.53-1.54s-.685-1.54-1.53-1.54-1.53.69-1.53 1.54.685 1.54 1.53 1.54"
      />
    </svg>
  );
}
export default SvgBlockchain;
