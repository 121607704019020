function SvgAccessibility({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 21.25a9.25 9.25 0 1 0 0-18.5 9.25 9.25 0 0 0 0 18.5"
      />
      <path
        fill="currentColor"
        d="M13.54 6.89c0 .83-.68 1.52-1.52 1.52-.88 0-1.56-.69-1.56-1.52 0-.84.68-1.56 1.56-1.56.84 0 1.52.72 1.52 1.56"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8.07 9.58a17 17 0 0 0 7.86 0M9.74 17.45c.4-.76.82-1.7 1.17-2.79.47-1.48.67-2.82.74-3.88M14.26 17.45c-.4-.76-.82-1.7-1.17-2.79-.47-1.48-.67-2.82-.74-3.88"
      />
    </svg>
  );
}
export default SvgAccessibility;
