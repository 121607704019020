function SvgParcel3({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.25 12H13c-.55 0-1 .45-1 1v7.25c0 .55.45 1 1 1h7.25c.55 0 1-.45 1-1V13c0-.55-.45-1-1-1"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.39 15.8c-.58-.3-1.16-.59-1.74-.89-.6.3-1.19.59-1.79.89V12h3.53zM11 12H3.75c-.55 0-1 .45-1 1v7.25c0 .55.45 1 1 1H11c.55 0 1-.45 1-1V13c0-.55-.45-1-1-1"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.14 15.8c-.58-.3-1.16-.59-1.74-.89-.6.3-1.19.59-1.79.89V12h3.53zM15.62 2.75H8.37c-.55 0-1 .45-1 1V11c0 .55.45 1 1 1h7.25c.55 0 1-.45 1-1V3.75c0-.55-.44-1-1-1"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.76 6.55c-.58-.3-1.16-.59-1.74-.89-.6.3-1.19.59-1.79.89v-3.8h3.53z"
      />
    </svg>
  );
}
export default SvgParcel3;
