function SvgCandlesticksChartUp({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.82 13.24h-4.1v5.92h4.1zM13.99 7.5H10v9.62h3.99zM21.22 4.79h-4.1v9.44h4.1zM4.77 13.24V9.86M11.99 7.04V3.66M19.17 4.719V2.78M4.77 21.27v-2.11M12 19.23V17.5M19.17 17.11v-2.88"
      />
    </svg>
  );
}
export default SvgCandlesticksChartUp;
