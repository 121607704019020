function SvgNavigation({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 21.25a9.25 9.25 0 1 0 0-18.5 9.25 9.25 0 0 0 0 18.5"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m8.98 14.14 1.51-3.32q.105-.225.33-.33l3.32-1.51c.56-.25 1.13.32.88.88l-1.51 3.32q-.105.225-.33.33l-3.32 1.51a.663.663 0 0 1-.88-.88"
      />
    </svg>
  );
}
export default SvgNavigation;
