function SvgTouchId({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.58 9.11c.27.62.42 1.3.42 2.01v2c0 2.76-2.24 5-5 5-.56 0-1.1-.09-1.61-.26M15.43 7.49A5.002 5.002 0 0 0 7 11.13c0 1-.12 2.01.1 2.99.21.92.66 1.8 1.31 2.49"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12.8 15.26c-.25.09-.52.15-.8.15-1.25 0-2.27-1.02-2.27-2.27v-1.77M11.01 9.08c.3-.15.64-.23.99-.23 1.25 0 2.27 1.02 2.27 2.27v2c0 .13-.01.25-.03.37M12 11.45v1.12"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.25 21.25H5.75c-1.66 0-3-1.34-3-3V5.75c0-1.66 1.34-3 3-3h12.5c1.66 0 3 1.34 3 3v12.5c0 1.66-1.34 3-3 3"
      />
    </svg>
  );
}
export default SvgTouchId;
