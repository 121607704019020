function SvgPencil({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.46 21.24 21.25 6.45l-3.7-3.7L2.76 17.54l-.01 3.71zM15.35 6.13l2.52 2.52"
      />
    </svg>
  );
}
export default SvgPencil;
