function SvgMobilePay({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.02 14.73v4.74c0 .98-.8 1.78-1.78 1.78H5.47c-.98 0-1.78-.8-1.78-1.78V4.53c0-.98.8-1.78 1.78-1.78h8.77c.98 0 1.78.8 1.78 1.78v1.72"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.8 6.38h9.13c.77 0 1.39.62 1.39 1.39v5.45c0 .77-.62 1.39-1.39 1.39H9.8zM17.11 8.96h-3.08"
      />
      <path
        fill="currentColor"
        d="M17.98 11.52a.72.72 0 0 0-.4-.41.73.73 0 0 0-.58 0 .716.716 0 0 0-.4.41c-.04.09-.06.18-.06.28a.75.75 0 0 0 1.5 0c0-.1-.02-.19-.06-.28"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.79 15.63V5.35M4.26 18.15h11.31"
      />
    </svg>
  );
}
export default SvgMobilePay;
