function SvgServerDownload({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.65 7.89H6.34a2.57 2.57 0 1 0 0 5.14h11.31a2.57 2.57 0 1 0 0-5.14M14.74 10.46h2.74"
      />
      <path
        fill="currentColor"
        d="M7.2 11.49c-.28 0-.53-.1-.72-.3a1.014 1.014 0 0 1 .15-1.58c.05-.04.11-.07.17-.09.06-.03.12-.05.19-.06a.9.9 0 0 1 .4 0c.06.01.12.03.19.06a.7.7 0 0 1 .17.09l.15.12c.09.1.16.22.23.34.05.12.07.26.07.39 0 .28-.1.53-.3.73-.17.19-.42.3-.7.3"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.65 2.75H6.34a2.57 2.57 0 1 0 0 5.14h11.31a2.57 2.57 0 1 0 0-5.14M14.74 5.32h2.74"
      />
      <path
        fill="currentColor"
        d="M7.2 6.35a1.05 1.05 0 0 1-.72-.3 1.014 1.014 0 0 1 0-1.46c.09-.09.21-.16.33-.22.38-.16.83-.07 1.12.22.09.1.17.21.23.34.05.12.07.26.07.39 0 .28-.1.53-.3.73-.09.09-.21.16-.33.22-.12.05-.26.08-.4.08"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 16.11v5.12M12 21.25l2.47-2.47M12 21.25l-2.47-2.47"
      />
    </svg>
  );
}
export default SvgServerDownload;
