function SvgPrinter({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.11 2.75H7.89c-.57 0-1.03.46-1.03 1.03v4.11h10.28V3.78c0-.57-.46-1.03-1.03-1.03"
      />
      <path
        fill="currentColor"
        d="M3.5 10.22c0-.876.704-1.58 1.58-1.58h13.84c.876 0 1.58.704 1.58 1.58v4.59c0 .876-.704 1.58-1.58 1.58h-1.78v1.5h1.78A3.076 3.076 0 0 0 22 14.81v-4.59a3.076 3.076 0 0 0-3.08-3.08H5.08A3.076 3.076 0 0 0 2 10.22v4.59a3.076 3.076 0 0 0 3.08 3.08h1.78v-1.5H5.08c-.876 0-1.58-.704-1.58-1.58z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.14 12H6.86v7.87c0 .76.62 1.38 1.38 1.38h7.52c.76 0 1.38-.62 1.38-1.38zM9.94 18.17h1.03M9.94 15.08h4.12"
      />
    </svg>
  );
}
export default SvgPrinter;
