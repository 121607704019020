function SvgFreeBadge({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        fill="currentColor"
        d="M14.57 10.08a.48.48 0 0 1 .4.46.47.47 0 0 1-.36.44h-1.43v.55h1.17a.48.48 0 0 1 .37.44.46.46 0 0 1-.34.46h-1.2V13h1.37a.46.46 0 0 1 .06.91h-1.94a.38.38 0 0 1-.28-.08.38.38 0 0 1-.12-.27v-3.08a.43.43 0 0 1 .1-.28.45.45 0 0 1 .26-.12zM8.07 10.08a.46.46 0 0 1 0 .9h-1.4v.9h1.18a.47.47 0 0 1 0 .92H6.68v.88a.5.5 0 0 1-.47.24.51.51 0 0 1-.41-.25v-3.18a.38.38 0 0 1 .1-.28.42.42 0 0 1 .26-.12zM10.43 10.08a1.45 1.45 0 0 1 1.29 1.29 1.42 1.42 0 0 1-.67 1.23l.48.48a.45.45 0 0 1-.53.72l-1-1v.83a.45.45 0 0 1-.45.29.45.45 0 0 1-.55-.37v-3.07a.4.4 0 0 1 .1-.27.46.46 0 0 1 .26-.13zm-.5.9v.89h.49a.46.46 0 0 0 0-.88zM17.83 10.08a.47.47 0 0 1 .39.46.46.46 0 0 1-.36.44h-1.42v.55h1.17A.49.49 0 0 1 18 12a.48.48 0 0 1-.34.46h-1.2V13h1.37a.47.47 0 0 1 .42.46.47.47 0 0 1-.37.45h-1.96a.36.36 0 0 1-.27-.08.38.38 0 0 1-.12-.27v-3.08a.38.38 0 0 1 .1-.28.42.42 0 0 1 .26-.12z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m20.93 11.24-2-2v-3a1.14 1.14 0 0 0-1.18-1.14h-3l-2-2a1.06 1.06 0 0 0-1.52 0l-2 2h-3A1.14 1.14 0 0 0 5.1 6.25v3l-2 2a1.06 1.06 0 0 0 0 1.52l2 2v3a1.14 1.14 0 0 0 1.15 1.13h3l2 2a1.06 1.06 0 0 0 1.52 0l2-2h3a1.14 1.14 0 0 0 1.15-1.15v-3l2-2a1.06 1.06 0 0 0 .01-1.51M5.42 8.57h13.35M5.42 15.43h13.35"
      />
    </svg>
  );
}
export default SvgFreeBadge;
