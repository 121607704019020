function SvgRadioactive({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.06 12H12l1.03-1.78c.61.36 1.03 1.02 1.03 1.78M12 12H9.94c0-.76.41-1.42 1.03-1.78zM13.03 13.78c-.3.17-.66.28-1.03.28s-.73-.1-1.03-.28L12 12z"
      />
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.25 12h-7.19c0-.76-.41-1.42-1.03-1.78l3.6-6.23c.62.35 1.19.78 1.71 1.27.07.06.13.12.21.2.22.21.41.42.6.66.09.11.18.23.28.35.36.47.67.98.92 1.52.13.27.25.54.35.83a9.359 9.359 0 0 1 .55 3.18M16.62 20.01c-.01.01-.02.02-.03.02-.15.09-.32.17-.49.26-.16.08-.33.16-.5.24-.01 0-.02.01-.03.01-.18.08-.37.15-.55.22-.2.07-.38.12-.58.17s-.38.1-.58.14h-.01c-.18.04-.38.07-.57.09a9 9 0 0 1-2.56 0c-.18-.02-.38-.05-.57-.09h-.01c-.2-.04-.38-.09-.58-.14s-.38-.1-.58-.17c-.18-.06-.37-.13-.55-.22-.01 0-.02-.01-.03-.01a5 5 0 0 1-.5-.24c-.17-.08-.34-.16-.49-.26-.01 0-.02-.01-.03-.02l3.6-6.23c.3.17.66.28 1.03.28s.73-.1 1.03-.28zM10.97 10.22A2.05 2.05 0 0 0 9.94 12H2.75c0-.24.01-.47.03-.71.02-.3.05-.59.1-.86 0-.01.01-.03.01-.04.03-.15.05-.3.09-.45 0-.02 0-.03.01-.04.05-.23.1-.43.17-.65.04-.14.09-.29.14-.42.1-.29.22-.57.35-.83.11-.24.23-.46.36-.68l.01-.01c.05-.09.11-.18.17-.28.11-.2.25-.38.38-.55.09-.12.18-.24.28-.35.18-.24.38-.45.6-.66.07-.07.13-.13.21-.2.51-.49 1.09-.93 1.71-1.27z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.25 12c0 3.42-1.86 6.41-4.62 8.01l-3.6-6.23c-.3.17-.66.28-1.03.28s-.73-.1-1.03-.28l-3.6 6.23A9.23 9.23 0 0 1 2.75 12h7.19c0-.76.41-1.42 1.03-1.78l-3.6-6.23a9.2 9.2 0 0 1 4.62-1.24c1.68 0 3.27.45 4.62 1.24l-3.6 6.23A2.05 2.05 0 0 1 14.04 12z"
      />
      <path
        fill="#fff"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.06 12c0 .76-.41 1.42-1.03 1.78-.3.17-.66.28-1.03.28s-.73-.1-1.03-.28a2.05 2.05 0 0 1 0-3.56c.3-.17.66-.28 1.03-.28s.73.1 1.03.28c.61.36 1.03 1.02 1.03 1.78"
      />
    </svg>
  );
}
export default SvgRadioactive;
