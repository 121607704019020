function SvgDrop({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
      className={className}
      {...props}
    >
      <path
        fill="currentColor"
        d="M10.442 1.85a.63.63 0 0 0-.884 0L4.742 6.608c-.734.784-1.95 2.409-1.975 4.575-.017 1.784.766 3.6 2.141 4.984 1.384 1.4 3.192 2.166 5.092 2.166s3.708-.766 5.092-2.166c1.375-1.384 2.158-3.2 2.141-4.984-.025-2.166-1.241-3.791-1.991-4.591zm2.3 11.992a.6.6 0 0 1-.442.183.65.65 0 0 1-.442-.175.63.63 0 0 1 0-.883c.5-.509.825-1.192.817-1.742 0-.342.275-.625.625-.625s.625.275.625.617c.008.891-.433 1.875-1.183 2.625"
      />
    </svg>
  );
}
export default SvgDrop;
