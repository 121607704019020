function SvgTags({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m8.91 18.98-3.89-3.89c-.72-.72-.7-1.9.04-2.63l7.39-7.39c.41-.41.97-.61 1.53-.56l3.55.34c.86.08 1.54.76 1.62 1.62l.34 3.55c.05.56-.15 1.12-.56 1.53l-7.39 7.39c-.73.73-1.91.75-2.63.04"
      />
      <path
        fill="currentColor"
        d="M16.47 7.53c-.42-.42-1.09-.42-1.51 0s-.42 1.09 0 1.51c.21.21.48.31.75.31s.55-.1.75-.31c.43-.42.43-1.1.01-1.51"
      />
    </svg>
  );
}
export default SvgTags;
