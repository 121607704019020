function SvgLifebuoy({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.91 14.91a4.08 4.08 0 0 1-3.61 1.14 4.1 4.1 0 0 1-2.2-1.14c-.62-.62-1-1.4-1.14-2.2A4.08 4.08 0 0 1 9.1 9.1a4.08 4.08 0 0 1 3.61-1.14c.81.14 1.58.52 2.2 1.14a4.115 4.115 0 0 1 0 5.81"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.2 20.24c-1.16-1.4-2.33-2.8-3.5-4.19a4.1 4.1 0 0 0 2.2-1.14c.62-.62 1-1.4 1.14-2.2 1.4 1.16 2.8 2.33 4.19 3.5-.43.85-1 1.64-1.7 2.34-.69.7-1.48 1.26-2.33 1.69M3.76 7.8c.43-.85 1-1.64 1.7-2.34s1.49-1.27 2.34-1.7c1.16 1.4 2.33 2.8 3.5 4.19-.81.14-1.58.52-2.2 1.14s-1 1.4-1.14 2.2c-1.4-1.16-2.81-2.33-4.2-3.49M20.24 7.8c-1.4 1.16-2.8 2.33-4.19 3.5a4.1 4.1 0 0 0-1.14-2.2c-.62-.62-1.4-1-2.2-1.14 1.16-1.4 2.33-2.8 3.5-4.19.85.43 1.64 1 2.34 1.7.7.69 1.26 1.48 1.69 2.33M7.8 20.24c-.85-.43-1.64-1-2.34-1.7s-1.27-1.49-1.7-2.34c1.4-1.16 2.8-2.33 4.19-3.5.14.81.52 1.58 1.14 2.2s1.4 1 2.2 1.14c-1.16 1.4-2.33 2.81-3.49 4.2"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 21.25a9.25 9.25 0 1 0 0-18.5 9.25 9.25 0 0 0 0 18.5"
      />
    </svg>
  );
}
export default SvgLifebuoy;
