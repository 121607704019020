function SvgIlon({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 54 21"
      className={className}
      {...props}
    >
      <path fill="#292929" d="M0 0h54v21H0z" />
      <g clipPath="url(#ilon_svg__a)">
        <path
          fill="#F6F6F6"
          d="M-1182-3922c0-4.42 3.58-8 8-8H85c4.418 0 8 3.58 8 8V394.439a8 8 0 0 1-8 8h-1259c-4.42 0-8-3.581-8-8z"
        />
        <path fill="currentColor" d="M-1182-18H93v420.439h-1275z" />
        <path
          fill="#31327D"
          d="M48.514 19.654c-.026-.312-.077-.626-.069-.937.008-.304-.083-.427-.406-.416-.621.023-1.246.034-1.866-.006-.197-.012-.463-.149-.561-.31-1.865-3.073-3.71-6.157-5.558-9.24-.274-.458-.553-.911-.913-1.338v.456c0 3.3-.007 6.599.01 9.9.002.438-.115.567-.558.558-1.45-.028-2.9-.01-4.39-.01V.83c.136-.011.286-.032.438-.032 1.947-.003 3.894.009 5.841-.011.343-.003.525.12.674.4q1.558 2.932 3.129 5.854c.448.835.906 1.664 1.463 2.479v-.449c0-2.503.008-5.005-.008-7.508-.003-.378.104-.498.485-.484 1.915.07 3.302 1.017 4.325 2.569.093.142.084.364.084.55.006 3.54.006 7.079.01 10.618h-2.129v4.839zM22.144 13.154h6.147V6.001c-.164-.01-.317-.027-.47-.027-1.994-.002-3.99.009-5.985-.011-.401-.005-.511.115-.508.51.018 1.913 0 3.825.015 5.738.003.346-.083.475-.451.47q-1.964-.029-3.927 0c-.341.005-.431-.111-.43-.44.01-2.534-.067-5.072.05-7.6.036-.766.512-1.56.94-2.246C18.23 1.267 19.29.76 20.673.789c2.712.058 5.426-.014 8.139.03 1.656.025 3.041.676 3.907 2.151.224.38.372.864.375 1.301.031 3.443-.006 6.887.031 10.33.012 1.123-.586 1.896-1.29 2.607-.701.707-1.588.956-2.612.932-2.186-.055-4.373-.015-6.559-.014h-.52v-4.9719999999999995"
        />
        <path
          fill="#31327D"
          d="M7.697.934c2.152-.268 3.91.834 4.912 2.526.104.176.12.425.12.64.009 2.886.016 5.772-.004 8.657-.003.449.1.597.574.593 2.506-.023 5.012 0 7.518-.022.457-.005.557.144.55.57a129 129 0 0 0-.004 3.921c.005.35-.061.505-.467.502-3.129-.018-6.257-.012-9.386-.013H7.695V.934zM5.709 17.89c-1.773.2-3.664-.81-4.518-2.357-.362-.656-.55-1.323-.534-2.088.041-1.958.022-3.919.006-5.878-.003-.355.098-.467.458-.461 1.404.018 2.807.01 4.211.005.248 0 .424.01.422.344-.009 3.409-.006 6.818-.009 10.227 0 .061-.021.12-.038.208zM27.433 12.263h-5.285V6.87h5.285zM.5.257h5.122v5.379H.5zM48.531 19.654v-4.839H53.5v5.367h-4.932z"
        />
      </g>
      <defs>
        <clipPath id="ilon_svg__a">
          <path
            fill="#fff"
            d="M-1182-3922c0-4.42 3.58-8 8-8H85c4.418 0 8 3.58 8 8V394.439a8 8 0 0 1-8 8h-1259c-4.42 0-8-3.581-8-8z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgIlon;
