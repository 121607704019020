function SvgBin({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.96 5.83H5.69v13.36c0 1.13.92 2.05 2.05 2.05h8.16c1.13 0 2.05-.92 2.05-2.05V5.83zM3.79 5.83h16.42M15.08 2.74H8.92v3.08h6.16zM9.94 9.75v7.39M14.05 9.75v7.39"
      />
    </svg>
  );
}
export default SvgBin;
