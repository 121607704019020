function SvgArrowLeftRight({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m18.75 12-4-3.69M18.75 12l-4 3.69M5.25 12l4-3.69M5.25 12l4 3.69"
      />
    </svg>
  );
}
export default SvgArrowLeftRight;
