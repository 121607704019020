function SvgDna({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.09 2.75c.07.81.16 2.93-.85 5.14-1.36 2.97-3.71 3.92-4.24 4.11-.52-.23-2.97-1.36-4.24-4.11-.67-1.45-.84-2.99-.85-3.08-.09-.84-.05-1.55 0-2.06M7.76 4.81h3.39M7.76 7.89h2.54M6.91 21.25c-.07-.81-.16-2.93.85-5.14C9.12 13.14 11.47 12.19 12 12c.52.23 2.97 1.36 4.24 4.11.67 1.45.84 2.99.85 3.08.09.84.05 1.55 0 2.06M16.24 19.19h-3.39M16.24 16.11H13.7"
      />
    </svg>
  );
}
export default SvgDna;
