function SvgBarChartUp({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.86 12.74H2.75v5.94h4.11zM14.06 9.03h-4.01v9.65h4.01zM21.25 5.32h-4.11v13.36h4.11z"
      />
    </svg>
  );
}
export default SvgBarChartUp;
