function SvgFloppyDisk({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.35 6a3.07 3.07 0 0 1 .9 2.18v10a3.09 3.09 0 0 1-3.08 3.09H5.83a3.1 3.1 0 0 1-3.08-3.1V5.85a3.08 3.08 0 0 1 3.08-3.08h10a3.07 3.07 0 0 1 2.18.9z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7.89 14.05h8.22a2.05 2.05 0 0 1 2.05 2.05v5.14H5.83v-5.13a2.05 2.05 0 0 1 2.06-2.06M5.83 2.76v4.42a1.74 1.74 0 0 0 1.74 1.74h6.8a1.74 1.74 0 0 0 1.74-1.74V2.86M13.03 5.32v1.03"
      />
    </svg>
  );
}
export default SvgFloppyDisk;
