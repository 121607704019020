function SvgEyeOff({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.52 18.63a10 10 0 0 1-2.52.31c-3.27 0-6.2-1.53-8.2-3.95-1.4-1.69-1.4-4.3 0-5.98.16-.2.34-.39.52-.58M20.2 14.99c-.8.96-1.75 1.78-2.8 2.42L6.59 6.59c1.59-.98 3.43-1.53 5.41-1.53 3.27 0 6.2 1.53 8.2 3.95 1.4 1.68 1.4 4.3 0 5.98"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.08 12c0 .85-.35 1.62-.9 2.18L9.82 9.82c.55-.56 1.33-.9 2.18-.9 1.71 0 3.08 1.37 3.08 3.08M2.75 2.75l3.84 3.84 3.23 3.23 4.36 4.36 3.23 3.23 3.84 3.84"
      />
    </svg>
  );
}
export default SvgEyeOff;
