function SvgSunshade({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m4.59 19.41 6.17-6.17M18.23 20.34 3.66 5.77c4.03-4.03 10.55-4.03 14.57 0 4.03 4.02 4.03 10.54 0 14.57"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.5 6.26c.05.85.1 3.37-1.44 6.02-.91 1.56-2.02 2.58-2.84 3.18M17.71 5.47c-.85-.05-3.37-.1-6.02 1.44-1.56.91-2.58 2.02-3.18 2.84M3.66 5.77l-.91.91M8.21 10.32l-.91.91M13.68 15.79l-.91.91M18.23 20.34l-.91.91"
      />
    </svg>
  );
}
export default SvgSunshade;
