function SvgServerLocker({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.26 8.92H5.73c-1.65 0-2.99 1.34-2.99 2.99v.2c0 1.65 1.34 2.99 2.99 2.99h12.53c1.65 0 2.99-1.34 2.99-2.99v-.2c0-1.66-1.34-2.99-2.99-2.99M15.08 12h3.09"
      />
      <path
        fill="currentColor"
        d="M6.6 13.16c-.3 0-.6-.12-.81-.34-.12-.12-.2-.24-.25-.38a1.12 1.12 0 0 1 0-.88c.06-.15.14-.28.25-.38.1-.1.23-.19.37-.24.21-.09.44-.12.67-.07.07.01.14.04.22.07.06.02.13.06.2.1.06.04.12.09.17.14.1.12.19.23.25.38.06.14.08.29.08.44 0 .31-.12.6-.34.82-.1.1-.23.19-.37.24s-.28.1-.44.1"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.26 15.08H5.73c-1.65 0-2.99 1.34-2.99 2.99v.2c0 1.65 1.34 2.99 2.99 2.99h12.53c1.65 0 2.99-1.34 2.99-2.99v-.2c0-1.65-1.34-2.99-2.99-2.99M15.08 18.17h3.09"
      />
      <path
        fill="currentColor"
        d="M6.6 19.32c-.3 0-.6-.12-.81-.34a1.16 1.16 0 0 1-.35-.82c0-.3.13-.6.35-.81.43-.44 1.2-.44 1.63 0a1.15 1.15 0 0 1 0 1.63c-.1.1-.23.2-.37.25-.15.07-.29.09-.45.09"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.26 2.75H5.73c-1.65 0-2.99 1.34-2.99 2.99v.2c0 1.65 1.34 2.99 2.99 2.99h12.53c1.65 0 2.99-1.34 2.99-2.99v-.2c0-1.65-1.34-2.99-2.99-2.99M15.08 5.83h3.09"
      />
      <path
        fill="currentColor"
        d="M6.6 6.99c-.3 0-.6-.13-.81-.35-.22-.22-.35-.51-.35-.81q0-.225.09-.45c.06-.14.14-.27.25-.37.1-.1.23-.2.37-.25.21-.08.44-.11.67-.06.07.01.15.04.22.06.07.03.13.07.2.1.06.05.12.09.17.15a1.15 1.15 0 0 1 0 1.63c-.21.23-.5.35-.81.35"
      />
    </svg>
  );
}
export default SvgServerLocker;
