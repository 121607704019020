function SvgPaperPlane({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m15.17 8.86-4.77 4.89c-.35.36-.9.44-1.34.2L3.3 10.8c-.85-.46-.75-1.7.15-2.03l16.31-5.95c.89-.32 1.76.54 1.44 1.43l-5.81 16.26c-.32.89-1.52 1.01-2.01.21l-2.17-3.55"
      />
    </svg>
  );
}
export default SvgPaperPlane;
