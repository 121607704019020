function SvgVirus({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 18.17a6.17 6.17 0 1 0 0-12.34 6.17 6.17 0 0 0 0 12.34M12 5.83V4.81M8.92 6.66l-.52-.89M6.66 8.92l-.89-.52M5.83 12H4.81M6.66 15.08l-.89.52M8.92 17.34l-.52.89M12 18.17v1.02M15.08 17.34l.52.89M17.34 15.08l.89.52M18.17 12h1.02M17.34 8.92l.89-.52M15.08 6.66l.52-.89M12 5.83V3.78M10.97 2.75h2.06M6.66 8.92 4.88 7.89M3.48 8.27 4.5 6.48M6.66 15.08l-1.78 1.03M4.5 17.52l-1.02-1.79M12 18.17v2.05M13.03 21.25h-2.06M17.34 15.08l1.78 1.03M20.52 15.73l-1.02 1.79M17.34 8.92l1.78-1.03M19.5 6.48l1.02 1.79"
      />
      <path
        fill="currentColor"
        d="M12.02 9.94c-.57 0-1.03-.46-1.03-1.03s.46-1.03 1.02-1.03h.01c.57 0 1.03.46 1.03 1.03s-.46 1.03-1.03 1.03M8.94 15.08c-.57 0-1.03-.46-1.03-1.03s.46-1.03 1.02-1.03h.01c.57 0 1.03.46 1.03 1.03-.01.57-.47 1.03-1.03 1.03M15.1 14.06c-.57 0-1.03-.46-1.03-1.03S14.53 12 15.09 12h.01c.57 0 1.03.46 1.03 1.03s-.46 1.03-1.03 1.03"
      />
    </svg>
  );
}
export default SvgVirus;
