function SvgMobilePhoneIPhoneShape({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18 14.73v4.74c0 .98-.78 1.78-1.73 1.78H7.73c-.96 0-1.73-.8-1.73-1.78V4.53c0-.98.78-1.78 1.73-1.78h8.54c.96 0 1.73.8 1.73 1.78v10.55M11.5 5.83h1"
      />
      <path fill="currentColor" d="M12 19.01a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
    </svg>
  );
}
export default SvgMobilePhoneIPhoneShape;
