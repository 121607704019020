function SvgReplyArrow({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m4.8 8.8 2.9 2.7M4.8 8.8 7.7 6M5.8 8.8h8.6c2.7 0 4.8 2.1 4.8 4.6S17 18 14.4 18"
      />
    </svg>
  );
}
export default SvgReplyArrow;
