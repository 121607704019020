function SvgWithdrawal({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 2.75v8.23M8.92 5.85 12 2.76M15.08 5.85 12 2.76M10.97 17.14h2.06M15.1 13.02h2.04a4.11 4.11 0 1 1 0 8.22H6.86a4.11 4.11 0 1 1 0-8.22h2.07"
      />
    </svg>
  );
}
export default SvgWithdrawal;
