function SvgLoop({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m11.3 8.2 2.4 2.5M11.3 8.2l2.4-2.4M12 8.2h4.3c2.8 0 5 2.2 5 5s-2.2 5-5 5H7.7c-2.8 0-5-2.2-5-5s2.2-5 5-5"
      />
    </svg>
  );
}
export default SvgLoop;
