function SvgRocket({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 2.75c.33 0 .66.12.92.35.89.79 2.35 2.32 3.35 4.45.21.45.4.92.56 1.42.13.42.24.84.33 1.27.47 2.31-.05 4.7-.78 6.9-.28.86-.5 1.85-1.01 2.61-.03.04-.05.08-.07.11-.27.36-.69.57-1.14.57H9.84c-.45 0-.87-.21-1.14-.57a.7.7 0 0 0-.07-.11c-.51-.76-.73-1.74-1.01-2.61-.72-2.2-1.25-4.59-.78-6.9.09-.43.2-.85.33-1.27.16-.5.35-.97.56-1.42 1-2.13 2.46-3.65 3.35-4.45.26-.23.59-.35.92-.35"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m15.37 19.75 1.79 1.32c.27.2.6.24.91.13.13-.06.24-.13.33-.22.13-.13.22-.29.26-.48l.64-3.17c.09-.43-.03-.85-.3-1.18l-1.71-1.99c.01-.02.01-.04.01-.06M6.7 14.15l-1.71 1.99c-.27.33-.39.76-.3 1.18l.64 3.17c.06.32.29.58.59.7.31.11.64.07.91-.13l1.79-1.32M12 11.79a1.98 1.98 0 1 0 0-3.96 1.98 1.98 0 0 0 0 3.96M8.04 16.74h7.92"
      />
    </svg>
  );
}
export default SvgRocket;
