function SvgCalendar({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.86 4.81V2.75M17.14 4.81V2.75M18.05 3.78H5.95c-1.77 0-3.2 1.43-3.2 3.2v11.08c0 1.77 1.43 3.2 3.2 3.2h12.11c1.77 0 3.2-1.43 3.2-3.2V6.98a3.21 3.21 0 0 0-3.21-3.2M2.75 7.9h18.5"
      />
      <path
        fill="currentColor"
        d="M18 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2M14 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2M10 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2M6 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2M18 15.49a1 1 0 1 0 0-2 1 1 0 0 0 0 2M14 15.49a1 1 0 1 0 0-2 1 1 0 0 0 0 2M10 15.49a1 1 0 1 0 0-2 1 1 0 0 0 0 2M6 15.49a1 1 0 1 0 0-2 1 1 0 0 0 0 2M14 18.97a1 1 0 1 0 0-2 1 1 0 0 0 0 2M10 18.97a1 1 0 1 0 0-2 1 1 0 0 0 0 2M6 18.97a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
      />
    </svg>
  );
}
export default SvgCalendar;
