function SvgHospitalBuilding({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.08 3.78H8.91c-1.14 0-2.06.92-2.06 2.06v14.39h10.28V5.83a2.04 2.04 0 0 0-2.05-2.05"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M4.81 9.94h2.06v10.28H2.76V12c-.01-1.14.91-2.06 2.05-2.06M17.14 9.94h2.06c1.14 0 2.06.92 2.06 2.06v8.22h-4.11V9.94zM12 6.86v4.11M14.06 8.92H9.94M13.54 16.11h-3.08v4.11h3.08z"
      />
    </svg>
  );
}
export default SvgHospitalBuilding;
