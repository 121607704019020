function SvgLineChartDownWithBorder({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5.83 21.25h12.33c1.7 0 3.08-1.38 3.08-3.08V5.83c0-1.7-1.38-3.08-3.08-3.08H5.83c-1.7 0-3.08 1.38-3.08 3.08v12.33c0 1.71 1.38 3.09 3.08 3.09"
      />
      <path
        fill="currentColor"
        d="M16.46 18.02a1.54 1.54 0 1 0 0-3.08 1.54 1.54 0 0 0 0 3.08M11.64 13.88a1.54 1.54 0 1 0 0-3.08 1.54 1.54 0 0 0 0 3.08M7.55 9.08a1.54 1.54 0 1 0 0-3.08 1.54 1.54 0 0 0 0 3.08"
      />
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="m16.46 16.48-4.78-4.14-4.34-4.88"
      />
    </svg>
  );
}
export default SvgLineChartDownWithBorder;
