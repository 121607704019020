function SvgFingerprintScan({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16 2.75h2.17c1.71 0 3.08 1.38 3.08 3.08V7.9M2.75 7.9V5.83c0-1.71 1.38-3.08 3.08-3.08h2.12M16 21.25h2.17c1.71 0 3.08-1.38 3.08-3.08V16.1M2.75 16.1v2.07c0 1.71 1.38 3.08 3.08 3.08h2.12M16.6 8.99c.27.62.42 1.3.42 2.01v2c0 2.76-2.24 5-5 5-.56 0-1.1-.09-1.61-.26M15.45 7.36A5.002 5.002 0 0 0 7.02 11c0 1-.12 2.01.1 2.99.21.92.66 1.8 1.31 2.49"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12.82 15.13c-.25.09-.52.15-.8.15-1.25 0-2.27-1.02-2.27-2.27v-1.77M11.03 8.95c.3-.15.64-.23.99-.23 1.25 0 2.27 1.02 2.27 2.27v2c0 .13-.01.25-.03.37M12.02 11.32v1.12"
      />
    </svg>
  );
}
export default SvgFingerprintScan;
