function SvgKindle({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.14 2.75H6.87a2.06 2.06 0 0 0-2.06 2.06v14.38a2.06 2.06 0 0 0 2.06 2.06h10.27a2.06 2.06 0 0 0 2.06-2.06V4.81a2.06 2.06 0 0 0-2.06-2.06M4.81 17.14h14.38"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.87 7.89 9.8 6.71l-2 1.18V2.75h4.07zM7.89 10.97h8.22M7.89 14.06h8.22"
      />
    </svg>
  );
}
export default SvgKindle;
