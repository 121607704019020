function SvgDashboard({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3.72 18.68a9.15 9.15 0 0 1-.97-4.11c0-5.11 4.14-9.25 9.25-9.25s9.25 4.14 9.25 9.25c0 1.47-.35 2.88-.97 4.11"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 15.6a1.03 1.03 0 1 0 0-2.06 1.03 1.03 0 0 0 0 2.06M12 14.57l3.19-3.19"
      />
    </svg>
  );
}
export default SvgDashboard;
