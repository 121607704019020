function SvgFish({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M10.62 5.87A3.94 3.94 0 0 0 7 6.91a1.1 1.1 0 0 0 0 1.53l1.14 1.18M18.08 13.46A4 4 0 0 1 17 17.11a1.07 1.07 0 0 1-1.52 0l-1.16-1.18M8.93 16.17a4.07 4.07 0 0 1 .63 2.18 3.94 3.94 0 0 1-1.17 2.8.38.38 0 0 1-.51 0A3.7 3.7 0 0 1 7.11 20a3.4 3.4 0 0 1-.34-1 3 3 0 0 1 0-.64 4.5 4.5 0 0 1 1.41-2.52 4.58 4.58 0 0 1-2.55 1.36q-.32.03-.64 0a3.5 3.5 0 0 1-1-.36A3.7 3.7 0 0 1 2.85 16a.38.38 0 0 1 0-.51 4 4 0 0 1 2.8-1.14 4 4 0 0 1 2.16.65"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.4 3.66a2.3 2.3 0 0 0-.4-.36 3.67 3.67 0 0 0-2.8-.48A9.5 9.5 0 0 0 14 3.91 10.8 10.8 0 0 0 9.48 8a13.3 13.3 0 0 0-1.59 5.77c0 .14-.17 1.19-.06 1.26l1.11 1.14c.07.11 1.11 0 1.25-.05A13.1 13.1 0 0 0 16 14.59a10.6 10.6 0 0 0 4.12-4.45C21 8.31 22 5.4 20.4 3.66"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.44 4.82a8.8 8.8 0 0 0 2.13 3.63 8.7 8.7 0 0 0 3.59 2.2"
      />
      <path
        fill="currentColor"
        d="M17.76 4.85a1 1 0 1 0-1.44 1.388 1 1 0 0 0 1.44-1.388"
      />
    </svg>
  );
}
export default SvgFish;
