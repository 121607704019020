function SvgWifiCheck({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m16.44 17.78.86.87 1.58-1.58M12 21.24c-5.1 0-9.24-4.14-9.24-9.24S6.9 2.76 12 2.76 21.24 6.9 21.24 12"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8.85 9.84c.94-.59 2.05-.92 3.23-.92 1.11 0 2.15.29 3.04.8M10.47 12.46c.47-.3 1.03-.46 1.62-.46.55 0 1.08.14 1.52.4"
      />
      <path
        fill="currentColor"
        d="M12.11 16.11c-.57 0-1.03-.46-1.03-1.03s.45-1.03 1.02-1.03h.01c.57 0 1.03.46 1.03 1.03s-.46 1.03-1.03 1.03"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.66 21.25a3.59 3.59 0 1 0 0-7.18 3.59 3.59 0 0 0 0 7.18"
      />
    </svg>
  );
}
export default SvgWifiCheck;
