function SvgGlobe({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.25 12c0 2.32-.86 4.46-2.28 6.07a9.15 9.15 0 0 1-5.64 3.07c-.43.07-.87.1-1.33.1s-.89-.03-1.33-.1a9.15 9.15 0 0 1-5.64-3.07A9.17 9.17 0 0 1 2.75 12c0-2.32.86-4.46 2.28-6.07a9.15 9.15 0 0 1 5.64-3.07c.43-.07.87-.1 1.33-.1s.89.03 1.33.1a9.15 9.15 0 0 1 5.64 3.07A9.17 9.17 0 0 1 21.25 12"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.67 21.15C11.03 20.48 8 17.17 8 12s3.03-8.48 3.67-9.15M12.33 21.15C12.97 20.48 16 17.17 16 12s-3.03-8.48-3.67-9.15M2.75 12h18.5"
      />
    </svg>
  );
}
export default SvgGlobe;
