function SvgUsers({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8.84 10.84a3.02 3.02 0 1 0 0-6.04 3.02 3.02 0 0 0 0 6.04M2.75 19.2v-1.72c0-2 1.62-3.62 3.62-3.62h5.42c2 0 3.62 1.62 3.62 3.62v1.72M16.38 11.7a2.24 2.24 0 1 0 0-4.48 2.24 2.24 0 0 0 0 4.48M17.02 13.94h1.54c1.48 0 2.68 1.2 2.68 2.68v1.28"
      />
    </svg>
  );
}
export default SvgUsers;
