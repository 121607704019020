function SvgIceCubes({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8.82 12H4.77c-1.12 0-2.02.91-2.02 2.02v4.05c0 1.12.91 2.02 2.02 2.02h4.05c1.12 0 2.02-.91 2.02-2.02v-4.05c0-1.11-.9-2.02-2.02-2.02M19.23 12h-4.05c-1.12 0-2.02.91-2.02 2.02v4.05c0 1.12.91 2.02 2.02 2.02h4.05c1.12 0 2.02-.91 2.02-2.02v-4.05c0-1.11-.91-2.02-2.02-2.02M14.02 3.91H9.97c-1.12 0-2.02.91-2.02 2.02v4.05c0 1.12.91 2.02 2.02 2.02h4.05c1.12 0 2.02-.91 2.02-2.02V5.93c.01-1.12-.9-2.02-2.02-2.02"
      />
    </svg>
  );
}
export default SvgIceCubes;
