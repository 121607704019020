function SvgLamp({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M10.6 3.143c-2.45.51-4.43 2.48-4.95 4.94-.44 2.08.1 4.17 1.49 5.73.68.76 1.07 1.77 1.07 2.76v1.08c0 .73.47 1.34 1.12 1.55.22 1.26 1.32 2.28 2.67 2.28 1.36 0 2.46-1.02 2.67-2.28.65-.22 1.12-.82 1.12-1.55v-1.08c0-1 .39-1.99 1.1-2.81a6.46 6.46 0 0 0 1.61-4.28c0-4.08-3.76-7.2-7.9-6.34"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.99 6.083c1.89 0 3.43 1.54 3.43 3.42M8.91 18.424h6.17M8.408 15.333h7.235"
      />
    </svg>
  );
}
export default SvgLamp;
