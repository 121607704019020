function SvgSettingsSlider({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19.19 4.85h2.06M2.75 4.85h12.33M17.14 6.89a2.05 2.05 0 0 0 2.06-2.04 2.05 2.05 0 0 0-2.06-2.04 2.05 2.05 0 0 0-2.06 2.04 2.05 2.05 0 0 0 2.06 2.04M19.19 19.15h2.06M2.75 19.15h12.33M17.14 21.19a2.05 2.05 0 0 0 2.06-2.04 2.05 2.05 0 0 0-2.06-2.04 2.05 2.05 0 0 0-2.06 2.04 2.05 2.05 0 0 0 2.06 2.04M8.92 12h12.33M2.75 12h2.06M6.86 14.04A2.05 2.05 0 0 0 8.92 12a2.05 2.05 0 0 0-2.06-2.04A2.05 2.05 0 0 0 4.8 12a2.05 2.05 0 0 0 2.06 2.04"
      />
    </svg>
  );
}
export default SvgSettingsSlider;
