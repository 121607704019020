function SvgSynchronize({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5.39 14.64 8.03 12M5.39 14.64 2.75 12M18.61 9.78l-2.64 2.64M18.61 9.78l2.64 2.64M18.61 10.28v5.49c0 1.56-1.27 2.83-2.83 2.83H8.22M5.39 14.31V8.22c0-1.56 1.27-2.83 2.83-2.83h7.55"
      />
    </svg>
  );
}
export default SvgSynchronize;
