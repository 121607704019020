function SvgTrain({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.25 19.19H2.75M21.25 15.79v.32H2.75V4.81h7.92c3 0 5.71 1.3 7.63 3.39.9.97 1.63 2.11 2.14 3.39.53 1.29.81 2.71.81 4.2"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.23 12H13.7c-.94 0-1.7-.69-1.7-1.54s.76-1.54 1.7-1.54h5.08M4.81 9.94H3.78v1.03h1.03zM8.92 9.94H7.89v1.03h1.03zM16.11 16.11c0 .57.46 1.03 1.03 1.03s1.03-.46 1.03-1.03M4.81 16.11c0 .57.46 1.03 1.03 1.03s1.03-.46 1.03-1.03"
      />
    </svg>
  );
}
export default SvgTrain;
