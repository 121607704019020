function SvgCode({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.25 5.83v3.08H2.75V5.83c0-1.71 1.38-3.08 3.08-3.08h12.33c1.71 0 3.09 1.38 3.09 3.08M21.25 8.92v9.25c0 1.71-1.38 3.08-3.08 3.08H5.84c-1.71 0-3.08-1.38-3.08-3.08V8.92z"
      />
      <path
        fill="currentColor"
        d="M5.83 6.86a1.03 1.03 0 1 0 0-2.06 1.03 1.03 0 0 0 0 2.06"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m8.94 12.84-2.05 2.05M8.94 16.95l-2.05-2.06M15.12 12.84l2.05 2.05M15.12 16.95l2.05-2.06M12.68 11.75l-1.36 6.17"
      />
    </svg>
  );
}
export default SvgCode;
