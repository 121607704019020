function SvgPieChart2575({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M10.43 13.56h7.68c0 4.22-3.47 7.69-7.68 7.69-4.22 0-7.68-3.47-7.68-7.69s3.47-7.69 7.68-7.69z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.57 10.44V2.75c2.04 0 3.99.81 5.43 2.25a7.7 7.7 0 0 1 2.25 5.43h-7.68z"
      />
    </svg>
  );
}
export default SvgPieChart2575;
