function SvgBank({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.521}
        d="M19.72 17.14H4.29c-.48 0-.89.32-1 .77l-.5 2.11c-.15.63.34 1.23 1 1.23h16.44c.66 0 1.15-.6 1-1.23l-.5-2.11c-.12-.45-.53-.77-1.01-.77"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.7 9.84H5.59v7.3H9.7zM17.91 9.84H13.8v7.3h4.11zM21.25 7.95c0 1.04-.84 1.89-1.87 1.89H4.62c-1.03 0-1.87-.84-1.87-1.89 0-.75.43-1.42 1.11-1.72l7.38-3.31c.48-.22 1.03-.22 1.52 0l7.38 3.31c.68.3 1.11.97 1.11 1.72M11.49 6.72h1.02"
      />
    </svg>
  );
}
export default SvgBank;
