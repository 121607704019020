function SvgHeartbeat({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
      className={className}
      {...props}
    >
      <path
        fill="currentColor"
        d="M10.358 12.575c-.266.408-.725.65-1.216.65l-.125-.008a1.45 1.45 0 0 1-1.2-.85L6.55 9.583l-.658.659a1.43 1.43 0 0 1-1.025.425H3.242l6.266 6.266c.034.034.084.084.159.125a.6.6 0 0 0 .333.1.6.6 0 0 0 .342-.1c.041-.025.125-.1.158-.133l6.275-6.267h-5.133zM2.292 7.75h1.966l1.725-1.717a1.45 1.45 0 0 1 1.034-.425c.083 0 .166.009.25.017.475.092.875.4 1.075.833l1.05 2.317.25-.383c.275-.409.725-.65 1.216-.65h6.85q.313 0 .575.125c.034-.192.05-.4.05-.6a4.42 4.42 0 0 0-1.291-3.134c-1.725-1.708-4.525-1.716-6.25 0L10 4.925l-.783-.792a4.436 4.436 0 0 0-6.25 0 4.38 4.38 0 0 0-1.3 3.134q0 .31.05.608c.175-.083.375-.125.575-.125"
      />
      <path
        fill="currentColor"
        d="M17.708 8.575h-6.85c-.208 0-.408.1-.516.275L9.25 10.483 7.583 6.808a.63.63 0 0 0-1.017-.183L4.6 8.583H2.292a.6.6 0 0 0-.375.134.61.61 0 0 0-.25.491c0 .35.283.625.625.625h2.575a.6.6 0 0 0 .441-.183l1.509-1.508 1.758 3.883c.092.208.292.35.517.367h.05a.63.63 0 0 0 .525-.275l1.525-2.292h6.516c.35 0 .625-.283.625-.625a.6.6 0 0 0-.25-.492.6.6 0 0 0-.375-.133"
      />
    </svg>
  );
}
export default SvgHeartbeat;
