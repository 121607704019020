function SvgMicrophone({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.32 12c-.29 3.24-3.02 5.78-6.32 5.78S5.96 15.24 5.68 12"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12.13 14.31h-.27a2.76 2.76 0 0 1-2.76-2.76V5.5a2.76 2.76 0 0 1 2.76-2.76h.27a2.76 2.76 0 0 1 2.76 2.76v6.05c0 1.53-1.23 2.76-2.76 2.76M9.69 21.25h4.62M12 17.78v3.47"
      />
    </svg>
  );
}
export default SvgMicrophone;
