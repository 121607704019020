function SvgClock({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12.09 18.94c3.397 0 6.15-2.767 6.15-6.18s-2.753-6.18-6.15-6.18-6.15 2.767-6.15 6.18 2.753 6.18 6.15 6.18M8.63 18.3l-1.67 1.67M15.56 18.3l1.65 1.66M5.3 7.5l1.41-1.41a.996.996 0 0 0 0-1.41l-.06-.06c-.78-.78-2.04-.78-2.82 0s-.78 2.05 0 2.83l.06.06c.38.38 1.02.38 1.41-.01M18.7 7.5l-1.41-1.41a.996.996 0 0 1 0-1.41l.06-.06c.78-.78 2.04-.78 2.82 0s.78 2.05 0 2.83l-.06.06c-.38.38-1.02.38-1.41-.01M12.09 13.35l-1.57-1.05M14.56 11.58l-2.47 1.77"
      />
    </svg>
  );
}
export default SvgClock;
