function SvgLocation({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.08 3.66C13.38 3.66 12 5 12 6.64c0 2.04 2.76 5.03 2.88 5.16.11.12.3.12.41 0 .12-.13 2.88-3.12 2.88-5.16 0-1.64-1.39-2.98-3.09-2.98"
      />
      <path
        fill="currentColor"
        d="M15.08 7.77a1.06 1.06 0 1 0 0-2.12 1.06 1.06 0 0 0 0 2.12"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.03 10.11H4.81L2.75 20.34h18.5l-2.06-10.23h-2.05M8.92 20.34l11.3-5.11M6.86 10.11l6.17 7.67"
      />
    </svg>
  );
}
export default SvgLocation;
