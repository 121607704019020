function SvgTrophy({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.86 2.75h10.28v7.4a4.94 4.94 0 0 1-4.94 4.94h-.4a4.94 4.94 0 0 1-4.94-4.94z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.73 4.81H4.68a1 1 0 0 0-1 .89v3.23a.86.86 0 0 0 .3.64l3.66 3.25M17.21 4.81h2.05a1 1 0 0 1 1 .89v3.23a.86.86 0 0 1-.3.64l-3.49 3.13M12 15.08v3.09M15.22 18.17H8.78a1 1 0 0 0-1 .88l-.15 1a1 1 0 0 0 1 1.17h6.72a1 1 0 0 0 1-1.17l-.15-1a1 1 0 0 0-.98-.88"
      />
      <path
        fill="currentColor"
        d="M14.61 7.76a.75.75 0 0 0-.61-.51l-.92-.13-.41-.84a.78.78 0 0 0-1.34 0l-.41.84-.92.13a.75.75 0 0 0-.42 1.28l.67.65-.16.92a.75.75 0 0 0 1.09.79l.82-.44.82.44q.167.078.35.08a.77.77 0 0 0 .44-.14.75.75 0 0 0 .3-.73l-.16-.92.67-.65a.76.76 0 0 0 .19-.77"
      />
    </svg>
  );
}
export default SvgTrophy;
