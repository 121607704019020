function SvgHeadset({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5.83 19H3.76c-.56 0-1.01-.45-1.01-1.01v-3.97c0-.56.45-1.01 1.01-1.01h2.07zM20.24 19h-2.07v-6h2.07c.56 0 1.01.45 1.01 1.01v3.97c0 .57-.45 1.02-1.01 1.02"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.17 19v-8c0-3.31-2.76-6-6.17-6s-6.17 2.69-6.17 6v8"
      />
    </svg>
  );
}
export default SvgHeadset;
