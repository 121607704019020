function SvgStopwatch({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 21.25a7.71 7.71 0 1 0 0-15.42 7.71 7.71 0 0 0 0 15.42M12 4.81V2.75M10.8 2.75h2.23M17.46 7.57l.71-.71"
      />
      <path
        fill="currentColor"
        d="M12 15.32a1.78 1.78 0 1 0 0-3.56 1.78 1.78 0 0 0 0 3.56"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 13.03V9.94"
      />
    </svg>
  );
}
export default SvgStopwatch;
