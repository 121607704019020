function SvgDeliveryTruck({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.06 16.84c-.25.13-.52.23-.82.27-.12.02-.25.03-.37.03H8.92M4.81 17.13c-1.15-.07-2.06-.9-2.06-1.9V6.7c0-1.05 1-1.9 2.22-1.9h7.89c1.22 0 2.22.85 2.22 1.9v8.37M15.08 15.32V9.15c0-.7.69-1.26 1.54-1.26h.83c.45 0 .87.16 1.17.44l2.25 2.15c.24.23.37.52.37.82v4.58c0 .7-.69 1.26-1.54 1.26h-1.54M20.836 11.25h-5.598"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.86 19.2a2.06 2.06 0 1 0 0-4.12 2.06 2.06 0 0 0 0 4.12M16.11 19.2a2.06 2.06 0 1 0 0-4.12 2.06 2.06 0 0 0 0 4.12"
      />
    </svg>
  );
}
export default SvgDeliveryTruck;
