function SvgDollar({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 8.4c-1.49 0-2.7.81-2.7 1.8S10.51 12 12 12s2.7.81 2.7 1.8-1.21 1.8-2.7 1.8M12 8.4v7.2M12 8.4c1 0 1.87.36 2.34.9zM12 8.4v-.9M12 15.6v.9"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 15.6c-1 0-1.87-.36-2.34-.9z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21 12c0 1.18-.23 2.35-.69 3.44a9.1 9.1 0 0 1-1.95 2.92 9 9 0 0 1-2.92 1.95c-1.09.46-2.26.69-3.44.69s-2.35-.23-3.44-.69a9.3 9.3 0 0 1-2.92-1.95 9 9 0 0 1-1.95-2.92 8.967 8.967 0 0 1 1.95-9.8A8.97 8.97 0 0 1 12 3c2.39 0 4.68.95 6.36 2.64A8.97 8.97 0 0 1 21 12"
      />
    </svg>
  );
}
export default SvgDollar;
