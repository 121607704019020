function SvgPiggybank({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.57 5.99a1.62 1.62 0 1 0 0-3.24 1.62 1.62 0 0 0 0 3.24M4.14 12.08c.42-.69.99-1.28 1.71-1.72.77-.47 1.65-.73 2.55-.87 1.46-.21 2.93-.16 4.4-.1.07 0 .13.01.2.01.38.05.69-.03 1.03-.26 1-.66 2.14-.9 3.36-.84.27.01.44.1.52.24.09.18-.02.4-.07.51-.2.42-.44 1-.74 1.79.15.21.36.49.63.81.1.12.49.58.83.89.32.29.96.79 2.07 1.15l-.03 2.87c-.25.06-.62.18-1.01.42-.78.49-.93 1.06-1.53 1.58-.34.3-.91.65-1.84.79-.17.19-.39.47-.57.84-.19.41-.27.77-.31 1.03h-2.63q-.12-.21-.24-.45c-.07-.14-.14-.28-.19-.42H9.22c-.06.11-.12.23-.19.36-.09.18-.16.36-.22.52-.88-.01-1.75-.01-2.63-.02-.05-.07-.13-.18-.2-.33-.21-.42-.17-.75-.27-1.01-.15-.37-.32-.74-.58-1.05-.96-1.16-1.75-2.5-1.77-3.99-.01-.93.27-1.91.78-2.75M9.93 12.49h3.29"
      />
      <path
        fill="currentColor"
        d="M15.98 13.84c.453 0 .82-.363.82-.81a.815.815 0 0 0-.82-.81.815.815 0 0 0-.82.81c0 .447.367.81.82.81"
      />
    </svg>
  );
}
export default SvgPiggybank;
