function SvgArchive({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.42 20.11H5.58c-1.77 0-3.02-1.71-2.8-3.73L4.11 4.14c.15-1.42 1.41-2.53 2.81-2.53h10.16c1.4 0 2.66 1.11 2.81 2.53l1.33 12.24c.22 2.02-1.03 3.73-2.8 3.73"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3.06 13.67h4.83c.78 0 1.43.47 1.8 1.14.44.8 1.31 1.35 2.31 1.35s1.87-.54 2.31-1.35c.36-.67 1.02-1.14 1.8-1.14h4.83"
      />
    </svg>
  );
}
export default SvgArchive;
