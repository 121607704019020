function SvgProjector({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M4.81 4.81h14.38M18.17 10.97H5.83a3.08 3.08 0 0 0-3.08 3.08v2.06a3.08 3.08 0 0 0 3.08 3.08h12.34a3.08 3.08 0 0 0 3.08-3.08v-2.06a3.08 3.08 0 0 0-3.08-3.08"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.53 16.72a1.64 1.64 0 1 0 0-3.28 1.64 1.64 0 0 0 0 3.28M2.97 13.54h3.89M2.97 16.62h3.89M12 4.81v5.88"
      />
    </svg>
  );
}
export default SvgProjector;
