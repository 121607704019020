function SvgHoodie({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7.38 5.16c-2.28.58-3.98 2.58-4.15 4.95l-.47 6.1c-.01.1.03.21.1.29s.17.12.29.12h1.66"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5.83 12v7.33c0 1.06.86 1.92 1.93 1.92H12M16.62 5.16c2.28.58 3.98 2.58 4.15 4.95l.47 6.1c.01.1-.03.21-.1.29a.37.37 0 0 1-.29.12h-1.66M16.62 5.16c.09-.17.25-.55.23-1.04-.01-.25-.04-.65-.35-.98-.16-.17-.48-.39-1.04-.39H8.54c-.55 0-.87.22-1.04.39-.23.25-.35.59-.35.98 0 .32.08.68.23 1.04.93 1.68 2.7 2.73 4.62 2.73s3.7-1.05 4.62-2.73"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.17 12v7.33c0 1.06-.86 1.92-1.93 1.92H12M10.46 7.89v1.73M13.54 7.89v3.08"
      />
    </svg>
  );
}
export default SvgHoodie;
