function SvgTrafficCone({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M2.75 20.09h18.5M13.44 3.91h-2.88c-.52 0-.97.34-1.11.84L5.06 20.1h13.88L14.55 4.75c-.14-.5-.59-.84-1.11-.84M8.53 8.53h6.94M7.38 14.31h9.24"
      />
    </svg>
  );
}
export default SvgTrafficCone;
