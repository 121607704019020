function SvgBrowserWindow({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.25 6.75v2.17H2.75V6.75c0-1.66 1.34-3 3-3h12.5c1.66 0 3 1.34 3 3M21.25 8.92v8.33c0 1.66-1.34 3-3 3H5.75c-1.66 0-3-1.34-3-3V8.92z"
      />
      <path fill="currentColor" d="M5.83 7.33a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
    </svg>
  );
}
export default SvgBrowserWindow;
