function SvgIceCream({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7.89 9.94 12 21.25l4.11-11.31"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.17 9.43c-.17 0-.32.01-.46.03-1.1.18-1.25 1-2.63 1-1.55 0-1.55-1.03-3.08-1.03-1.55 0-1.55 1.03-3.08 1.03-1.38 0-1.52-.81-2.63-1-.13-.02-.29-.03-.46-.03"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.14 7.89c0 .54-.08 1.08-.25 1.57-.95.18-1.07 1-2.25 1-1.33 0-1.33-1.03-2.64-1.03-1.33 0-1.33 1.03-2.64 1.03-1.18 0-1.31-.81-2.25-1a5.1 5.1 0 0 1-.25-1.57c0-2.84 2.3-5.14 5.14-5.14s5.14 2.3 5.14 5.14M17.14 10.97H6.86"
      />
    </svg>
  );
}
export default SvgIceCream;
