function SvgSpeaker({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.81 21.25H7.19A2.19 2.19 0 0 1 5 19.06V4.94c0-1.21.98-2.19 2.19-2.19h9.62c1.21 0 2.19.98 2.19 2.19v14.12c0 1.21-.98 2.19-2.19 2.19"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 18.16c1.657 0 3-1.379 3-3.08S13.657 12 12 12s-3 1.379-3 3.08 1.343 3.08 3 3.08M12 8.92c.828 0 1.5-.69 1.5-1.54s-.672-1.54-1.5-1.54-1.5.69-1.5 1.54.672 1.54 1.5 1.54"
      />
    </svg>
  );
}
export default SvgSpeaker;
