function SvgMecialMask({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.17 16.2c-1.03.64-3.22 1.8-6.17 1.8-1.17 0-3.57-.18-6.17-1.8V7.8C6.86 7.16 9.05 6 12 6c1.17 0 3.57.18 6.17 1.8zM5.83 16.2V7.8c-1.7 0-3.08 1.38-3.08 3.08v2.23c0 1.71 1.38 3.09 3.08 3.09M18.17 16.2V7.8c1.7 0 3.08 1.38 3.08 3.08v2.23c0 1.71-1.38 3.09-3.08 3.09M15.08 9.6H8.92M15.08 14.4H8.92"
      />
    </svg>
  );
}
export default SvgMecialMask;
