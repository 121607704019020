function SvgWifi({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8.86 9.84c.95-.59 2.06-.93 3.24-.93 1.11 0 2.15.29 3.04.8M10.47 12.46c.47-.3 1.03-.46 1.62-.46.56 0 1.08.14 1.52.4"
      />
      <path
        fill="currentColor"
        d="M12.12 16.11c-.57 0-1.03-.46-1.03-1.03s.46-1.03 1.02-1.03h.01c.57 0 1.03.46 1.03 1.03s-.46 1.03-1.03 1.03"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 21.25a9.25 9.25 0 1 0 0-18.5 9.25 9.25 0 0 0 0 18.5"
      />
    </svg>
  );
}
export default SvgWifi;
