function SvgWallet({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.27 7.18H2.75V17.2c0 1.65 1.34 2.98 2.98 2.98h12.53c1.65 0 2.98-1.34 2.98-2.98v-7.03a2.975 2.975 0 0 0-2.97-2.99"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8.98 3.82h5.13c1.7 0 3.08 1.51 3.08 3.36M2.75 10.86V5.89"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.48 7.18H4.29c-.85 0-1.54-.75-1.54-1.68s.69-1.68 1.54-1.68h7.19"
      />
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.71 14.19a.51.51 0 1 0 0-1.02.51.51 0 0 0 0 1.02"
      />
    </svg>
  );
}
export default SvgWallet;
