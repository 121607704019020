function SvgGoldBars({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.85 20.17h-6c-.71 0-1.24-.62-1.07-1.27l.53-2.06c.12-.46.56-.79 1.07-.79h4.93c.51 0 .95.32 1.07.78l.54 2.06c.17.65-.35 1.28-1.07 1.28M14.98 14h-6c-.71 0-1.24-.62-1.07-1.27l.53-2.06c.12-.46.56-.79 1.07-.79h4.93c.51 0 .95.32 1.07.78l.54 2.06c.17.66-.35 1.28-1.07 1.28M20.15 20.17h-6c-.71 0-1.24-.62-1.07-1.27l.53-2.06c.12-.46.56-.79 1.07-.79h4.93c.51 0 .95.32 1.07.78l.54 2.06c.17.65-.36 1.28-1.07 1.28M8.29 7.87 6.84 6.41M15.37 7.87l1.45-1.46M11.86 5.89V3.83"
      />
    </svg>
  );
}
export default SvgGoldBars;
