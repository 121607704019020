function SvgAttachment({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m9.61 15.58 6.65-6.65c.65-.65.65-1.7 0-2.35a1.66 1.66 0 0 0-2.35 0l-9 9a3.32 3.32 0 0 0 0 4.7c1.3 1.3 3.4 1.3 4.7 0l8.98-8.98a4.997 4.997 0 0 0 0-7.08 4.997 4.997 0 0 0-7.08 0l-6.63 6.63"
      />
    </svg>
  );
}
export default SvgAttachment;
