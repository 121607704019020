function SvgDragNDrop({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7.89 14.06H4.81c-1.14 0-2.06.92-2.06 2.06v3.08c0 1.14.92 2.06 2.06 2.06h3.08c1.14 0 2.06-.92 2.06-2.06v-3.08a2.07 2.07 0 0 0-2.06-2.06M9.94 14.06l4.12-4.12M15.08 8.92V12M15.08 8.92H12"
      />
      <path
        fill="currentColor"
        d="M15.73 16.02h-3c-.41 0-.75.34-.75.75s.34.75.75.75h3c.41 0 .75-.34.75-.75s-.34-.75-.75-.75M21.23 13.52c-.41 0-.75.34-.75.75v.5c0 .69-.56 1.25-1.25 1.25h-.5c-.41 0-.75.34-.75.75s.34.75.75.75h.5c1.52 0 2.75-1.23 2.75-2.75v-.5c0-.41-.34-.75-.75-.75M21.23 7.52c-.41 0-.75.34-.75.75v3c0 .41.34.75.75.75s.75-.34.75-.75v-3c0-.41-.34-.75-.75-.75M19.23 2.02h-.5c-.41 0-.75.34-.75.75s.34.75.75.75h.5c.69 0 1.25.56 1.25 1.25v.5c0 .41.34.75.75.75s.75-.34.75-.75v-.5c0-1.52-1.23-2.75-2.75-2.75M15.73 2.02h-3c-.41 0-.75.34-.75.75s.34.75.75.75h3c.41 0 .75-.34.75-.75s-.34-.75-.75-.75M9.73 2.02h-.5c-1.52 0-2.75 1.23-2.75 2.75v.5c0 .41.34.75.75.75s.75-.34.75-.75v-.5c0-.69.56-1.25 1.25-1.25h.5c.41 0 .75-.34.75-.75s-.34-.75-.75-.75M7.23 7.52c-.41 0-.75.34-.75.75v3c0 .41.34.75.75.75s.75-.34.75-.75v-3c0-.41-.34-.75-.75-.75"
      />
    </svg>
  );
}
export default SvgDragNDrop;
