function SvgBike({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5.56 17.58a2.84 2.84 0 1 0 0-5.68 2.84 2.84 0 0 0 0 5.68M18.44 17.58a2.84 2.84 0 1 0 0-5.68 2.84 2.84 0 0 0 0 5.68M5.67 14.87l6.64.07M5.67 14.87l3.71-5.08M12.31 14.94l3.03-5.15M9.38 9.79h5.96M8.59 6.41H6.34M18.44 14.6l-3.1-4.81M15.34 9.79V6.41M15.34 6.41h3.38M12.31 14.94 7.47 6.41"
      />
    </svg>
  );
}
export default SvgBike;
