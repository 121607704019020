function SvgServerConnect({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.36 8.53H5.64c-1.6 0-2.89 1.29-2.89 2.89s1.29 2.89 2.89 2.89h12.72c1.6 0 2.89-1.29 2.89-2.89 0-1.59-1.29-2.89-2.89-2.89M15.08 11.42h3.09"
      />
      <path
        fill="currentColor"
        d="M6.6 12.58c-.31 0-.6-.12-.81-.34-.12-.12-.2-.24-.25-.38a1.12 1.12 0 0 1 0-.88c.06-.14.14-.27.25-.38.05-.05.1-.1.17-.14.06-.05.13-.08.2-.1.07-.04.14-.06.22-.07.23-.05.45-.02.67.07.14.06.27.14.37.24.1.12.2.24.25.38s.08.29.08.44-.02.3-.08.44-.15.27-.25.38c-.22.22-.51.34-.82.34"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.36 2.75H5.64c-1.6 0-2.89 1.29-2.89 2.89s1.29 2.89 2.89 2.89h12.72c1.6 0 2.89-1.29 2.89-2.89s-1.29-2.89-2.89-2.89M15.08 5.64h3.09"
      />
      <path
        fill="currentColor"
        d="M6.6 6.8c-.31 0-.6-.12-.81-.34a1.16 1.16 0 0 1-.35-.82c0-.3.13-.6.35-.82a1.183 1.183 0 0 1 1.63 0c.22.22.34.52.34.82 0 .31-.12.6-.34.82s-.51.34-.82.34"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 14.31v3.13M9.69 19.52H2.75M21.25 19.52h-6.94M12 21.25a1.73 1.73 0 1 0 0-3.46 1.73 1.73 0 0 0 0 3.46"
      />
    </svg>
  );
}
export default SvgServerConnect;
