function SvgCannabis({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7.84 16.15a6.43 6.43 0 0 0-3.5 1.06 6.8 6.8 0 0 0-1.59 1.39 7.6 7.6 0 0 0 1.87 1.12 6.44 6.44 0 0 0 3.29.45A6.53 6.53 0 0 0 12 17.78"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.1 16.15a6.43 6.43 0 0 1 3.5 1.06 6.8 6.8 0 0 1 1.59 1.39 7.6 7.6 0 0 1-1.87 1.12 6.44 6.44 0 0 1-3.29.45 6.53 6.53 0 0 1-4.09-2.39"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.85 12.72A11.8 11.8 0 0 0 3 8.33c.155.917.417 1.813.78 2.67a11.7 11.7 0 0 0 2.61 3.79 11.7 11.7 0 0 0 5.61 3"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.13 12.72a11.65 11.65 0 0 1 4.23-3.48A12 12 0 0 1 21 8.33a12 12 0 0 1-.81 2.67 11.5 11.5 0 0 1-2.61 3.79 11.7 11.7 0 0 1-5.61 3"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 17.78a13.5 13.5 0 0 0 2.31-6.94A13.63 13.63 0 0 0 12 2.75a13.63 13.63 0 0 0-2.31 8.09A13.5 13.5 0 0 0 12 17.78M11.97 17.78v3.47"
      />
    </svg>
  );
}
export default SvgCannabis;
