function SvgCmdKeyboardSign({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.37 14.54V8.75c0-1.04.85-1.88 1.88-1.88 1.04 0 1.88.85 1.88 1.88 0 1.04-.85 1.88-1.88 1.88H8.74c-1.04 0-1.88-.85-1.88-1.88 0-1.04.85-1.88 1.88-1.88 1.04 0 1.88.85 1.88 1.88v6.51c0 1.04-.85 1.88-1.88 1.88-1.04 0-1.88-.85-1.88-1.88 0-1.04.85-1.88 1.88-1.88h5.58-.27 1.2c1.04 0 1.88.85 1.88 1.88 0 1.04-.85 1.88-1.88 1.88-1.04 0-1.88-.85-1.88-1.88v-1.2"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.17 21.25H5.84c-1.7 0-3.08-1.38-3.08-3.08V5.84c0-1.7 1.38-3.08 3.08-3.08h12.33c1.7 0 3.08 1.38 3.08 3.08v12.33c0 1.7-1.38 3.08-3.08 3.08"
      />
    </svg>
  );
}
export default SvgCmdKeyboardSign;
