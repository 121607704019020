function SvgCap({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m2.76 14.71-.01-2.73c-.01-2.71 2-5 4.7-5.33l3.19-.38a5.323 5.323 0 0 1 5.76 3.78l.89 3 3.78 2.06c.1.06.17.16.18.29.01.11-.04.23-.13.31a9.58 9.58 0 0 1-5.62 2.08h-.32c-1.94 0-3.81-.59-5.42-1.71l-2.12-1.47M2.88 14.97l14.4-1.92M16.39 10.05l-3.66.53"
      />
    </svg>
  );
}
export default SvgCap;
