function SvgSyringe({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m6.64 19.3-1.95-1.95c-.54-.54-.54-1.41 0-1.95l6.82-6.82c.54-.54 1.41-.54 1.95 0l1.95 1.95c.54.54.54 1.41 0 1.95L8.59 19.3c-.54.54-1.41.54-1.95 0M10.54 5.67l7.79 7.79M21.25 8.59l-5.84-5.84M5.67 18.33l-2.92 2.92"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.356 4.694 13.46 8.59l1.945 1.945L19.3 6.639zM10.55 10.55l.72.72M8.37 12.73l.72.72M6.19 14.91l.72.72"
      />
    </svg>
  );
}
export default SvgSyringe;
