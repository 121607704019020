function SvgAlignLeft({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3.87 2.75v18.5M12.99 10.53H6.82V4.79h6.17c.55 0 1 .45 1 1v3.74c0 .56-.45 1-1 1M19.13 19.14H6.81V13.4h12.32c.55 0 1 .45 1 1v3.74c0 .55-.44 1-1 1"
      />
    </svg>
  );
}
export default SvgAlignLeft;
