function SvgBlackboard({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.17 16.11H5.84c-1.7 0-3.08-1.38-3.08-3.08V5.84c0-1.7 1.38-3.08 3.08-3.08h12.33c1.7 0 3.08 1.38 3.08 3.08v7.19c0 1.7-1.38 3.08-3.08 3.08M12 16.11v5.14M7.89 16.11l-2.06 5.14M16.11 16.11l2.06 5.14M5.83 5.83h1.03M5.83 8.92h4.11M5.83 12h4.11"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.17 5.83h-4.11v4.11h4.11z"
      />
    </svg>
  );
}
export default SvgBlackboard;
