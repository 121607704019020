function SvgImageSingle({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.18 21.25H5.82a3.07 3.07 0 0 1-3.07-3.07V5.82c0-1.7 1.38-3.07 3.07-3.07h12.36c1.7 0 3.07 1.38 3.07 3.07v12.36c0 1.69-1.38 3.07-3.07 3.07"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m2.75 17.45 3.53-3.53c.6-.6 1.58-.6 2.18 0l.9.9c.6.6 1.58.6 2.18 0l3.99-3.99c.6-.6 1.58-.6 2.18 0l3.53 3.53"
      />
      <path
        fill="currentColor"
        d="M8.15 5.91a1.52 1.52 0 1 0-.001 3.039A1.52 1.52 0 0 0 8.15 5.91"
      />
    </svg>
  );
}
export default SvgImageSingle;
