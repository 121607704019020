function SvgShoppingList({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.08 21.25H4.8c-1.14 0-2.06-.92-2.06-2.06V4.81c0-1.14.92-2.06 2.06-2.06h12.33v16.44c.01 1.14-.91 2.06-2.05 2.06M5.83 6.91h1.03M9.94 6.91h4.12M5.83 12.05h1.03M9.94 12.05h4.12M5.83 17.19h1.03M9.94 17.19h4.12M17.14 4.81V12h2.06c1.14 0 2.06-.92 2.06-2.06V4.8c0-1.14-.92-2.06-2.06-2.06M12 2.75h7.19"
      />
    </svg>
  );
}
export default SvgShoppingList;
