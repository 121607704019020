function SvgScanner({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.22 14.97H3.78c-.57 0-1.03.46-1.03 1.03v3.08c0 .57.46 1.03 1.03 1.03h13.36A4.11 4.11 0 0 0 21.25 16c0-.57-.46-1.03-1.03-1.03"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.11 1.61 3.55 11.27c-.51.39-.8.99-.8 1.63v4.13M6.86 11.89h12.33"
      />
      <path
        fill="currentColor"
        d="M5.85 18.57c-.57 0-1.03-.46-1.03-1.03s.45-1.03 1.02-1.03h.01c.57 0 1.03.46 1.03 1.03s-.46 1.03-1.03 1.03"
      />
    </svg>
  );
}
export default SvgScanner;
