function SvgQuill({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3.78 19.2H2.75M12.15 18.26l-3.04-1.44-1.44-3.04 8.17-8.17a3.175 3.175 0 1 1 4.49 4.49zM6.86 19.06 17.69 8.23"
      />
    </svg>
  );
}
export default SvgQuill;
