function SvgSugarCane({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m9.94 9.84.91-.86H6.29l.91.86M9.94 15.89v4.32H7.2v-4.32M7.2 15.89l-.91-.87h4.56l-.91.87M7.2 14.16V9.84h2.74v4.32M9.94 14.16l.91.86H6.29l.91-.86M7.2 8.11V3.79h2.74v4.32M9.94 8.11l.91.87H6.29l.91-.87M5.772 7.824c.469-.47.19-1.507-.622-2.32s-1.851-1.09-2.32-.622-.19 1.507.623 2.32 1.85 1.09 2.319.622"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5.772 7.824c.469-.47.19-1.507-.622-2.32s-1.851-1.09-2.32-.622-.19 1.507.623 2.32 1.85 1.09 2.319.622M14.06 9.84l-.91-.86h4.56l-.91.86M14.06 15.89v4.32h2.74v-4.32M16.8 15.89l.91-.87h-4.56l.91.87M16.8 14.16V9.84h-2.74v4.32M14.06 14.16l-.91.86h4.56l-.91-.86M16.8 8.11V3.79h-2.74v4.32M14.06 8.11l-.91.87h4.56l-.91-.87M20.543 13.359c.812-.812 1.09-1.85.622-2.32-.469-.468-1.507-.19-2.32.623-.811.812-1.09 1.85-.621 2.319.468.469 1.506.19 2.319-.622"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.543 13.359c.812-.812 1.09-1.85.622-2.32-.469-.468-1.507-.19-2.32.623-.811.812-1.09 1.85-.621 2.319.468.469 1.506.19 2.319-.622"
      />
    </svg>
  );
}
export default SvgSugarCane;
