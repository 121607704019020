function SvgUnlink({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m14.12 14.12 4.24-4.24a3 3 0 0 0 0-4.24 3 3 0 0 0-4.24 0l-4.09 4.09M8 11.76l-2.36 2.36a3 3 0 0 0 0 4.24 3 3 0 0 0 4.24 0l2.37-2.37M7 7l10 10"
      />
    </svg>
  );
}
export default SvgUnlink;
