function SvgRoundSmartWatch({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18 12c0 1.71-.67 3.25-1.76 4.36-.28.3-.6.55-.93.78A5.77 5.77 0 0 1 12 18.17a5.77 5.77 0 0 1-3.31-1.03A6.2 6.2 0 0 1 6 12c0-1.71.67-3.25 1.76-4.36.28-.3.6-.56.93-.78A5.77 5.77 0 0 1 12 5.83c1.22 0 2.36.37 3.31 1.03.33.23.65.48.93.78A6.2 6.2 0 0 1 18 12"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16 3.93v2.93h-.69A5.77 5.77 0 0 0 12 5.83c-1.22 0-2.36.37-3.31 1.03H8V3.93c0-.65.52-1.18 1.15-1.18h5.7c.63 0 1.15.53 1.15 1.18M16 17.14v2.93c0 .65-.52 1.18-1.15 1.18h-5.7c-.63 0-1.15-.53-1.15-1.18v-2.93h.69A5.77 5.77 0 0 0 12 18.17c1.22 0 2.36-.37 3.31-1.03zM19 10.97v2.06M12 12.7l-1-1.03M14 10.65l-2 2.05"
      />
    </svg>
  );
}
export default SvgRoundSmartWatch;
