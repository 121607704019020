function SvgAppleWatch({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.97 18.17H8.03c-1.12 0-2.03-.91-2.03-2.03V7.86c0-1.12.91-2.03 2.03-2.03h7.94c1.12 0 2.03.91 2.03 2.03v8.28c0 1.12-.91 2.03-2.03 2.03M14.99 2.75H9.02c-.56 0-1.01.45-1.01 1.01v2.07h8V3.76c-.01-.56-.46-1.01-1.02-1.01"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.99 21.25H9.02c-.56 0-1.01-.45-1.01-1.01v-2.07h8v2.07c-.01.56-.46 1.01-1.02 1.01M19 8.92v2.05M12 12.7l-1-1.03M14 10.65l-2 2.05"
      />
    </svg>
  );
}
export default SvgAppleWatch;
