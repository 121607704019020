function SvgBarcode({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M2.75 7.9V5.83c0-1.71 1.38-3.08 3.08-3.08h12.33c1.71 0 3.08 1.38 3.08 3.08V7.9M21.25 16.08v2.09c0 1.71-1.38 3.08-3.08 3.08H5.84c-1.71 0-3.08-1.38-3.08-3.08V16.1M12 6.86v10.28M8.92 6.89v8.22M5.83 6.86v10.28M15.04 6.89v8.22M18.12 6.86v10.28"
      />
    </svg>
  );
}
export default SvgBarcode;
