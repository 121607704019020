function SvgTestTube({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m17.02 11.68-4.69-4.69-8.6 8.6c-1.3 1.3-1.3 3.4 0 4.69 1.3 1.3 3.4 1.3 4.69 0zM20.93 9.33l-6.26-6.26c-.43-.43-1.13-.43-1.56 0l-1.56 1.56c-.43.43-.43 1.13 0 1.56l6.26 6.26c.43.43 1.13.43 1.56 0l1.56-1.56c.43-.43.43-1.13 0-1.56"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m11.542 12.452-2.348-2.347L4.5 14.8l2.348 2.347z"
      />
    </svg>
  );
}
export default SvgTestTube;
