function SvgBluetooth({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m7 7.9 9.66 8.55c.47.42.45 1.12-.04 1.52l-3.72 3.02c-.76.62-1.9.07-1.9-.91V3.92c0-.98 1.13-1.52 1.89-.91l3.72 2.96c.5.4.52 1.11.04 1.53L7 16.04"
      />
    </svg>
  );
}
export default SvgBluetooth;
