function SvgAlarmBellOff({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.17 10.97v4.11h.46c.71 0 1.38.46 1.55 1.15.25 1.02-.51 1.94-1.49 1.94H8.93M3.87 17.14a1.4 1.4 0 0 1-.09-.51c0-.85.69-1.54 1.54-1.54h.51V9.95c0-3.4 2.76-6.17 6.17-6.17 1.7 0 3.24.69 4.36 1.81.38.38.71.81.98 1.27M12 3.78V2.75M15.08 18.17c0 1.71-1.38 3.08-3.08 3.08s-3.08-1.38-3.08-3.08zM2.75 19.19l18.5-14.38"
      />
    </svg>
  );
}
export default SvgAlarmBellOff;
