function SvgDirectionSign({
  size = 16,
  className = "",
  ...props
}: {
  size?: number,
  className?: string,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.94 3.78v2.06H6.86V3.78c0-.57.46-1.03 1.03-1.03h1.03c.56 0 1.02.46 1.02 1.03M6.86 12h3.08v8.22c0 .57-.46 1.03-1.03 1.03H7.88c-.57 0-1.03-.46-1.03-1.03V12z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.22 8.92c-.91 1.03-1.83 2.06-2.74 3.08H3.78V5.83h13.7c.91 1.03 1.83 2.06 2.74 3.09"
      />
    </svg>
  );
}
export default SvgDirectionSign;
